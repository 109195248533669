workflow-card-convergence {
  .workflow-card {
    &.-card {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: spacer(1);
    }
    .-card-number-collapsed {
      border-radius: 50%;
      width: spacer(5);
      height: spacer(5);
      border: 2px solid var(--workflow-step-bg-color-default);
      color: var(--workflow-step-bg-color-default);
      text-align: center;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: spacer(3);
      font-weight: 700;
    }
    .-card-number-expanded {
      border-radius: 50%;
      width: spacer(5);
      height: spacer(5);
      border: 2px solid var(--workflow-step-bg-color-expanded);
      background-color: var(--workflow-step-bg-color-expanded);
      color: var(--workflow-step-text-color-expanded);
      text-align: center;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: spacer(3);
      font-weight: 700;
    }
    .-card-no {
      margin: unset;
    }

    &.-status {
      flex-direction: column;
    }

    &.-content-title {
      margin-bottom: spacer(5);
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: var(--workflow-card-content-title-color);
    }
    &.-content-subtitle {
      margin-bottom: spacer(4);
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }

    &.-content-description-width {
      margin-bottom: spacer(4);
      width: 180px;
    }
    &.-content-description {
      margin-bottom: spacer(4);
    }
    &.-toggle {
      font-weight: 700;
      font-size: 14px;
      line-height: spacerThree(7);
      margin-bottom: spacer(5);
      icon {    
        height: 9px;
        width: 9px;     
      }
    }
    &.-spacer {
      border-left: $hairline solid var(--workflow-step-separator-color);
      height: 100%;
      margin: spacer(2) 0 spacer(2) spacer(3);
    }
    &.-spacer-collapsed {
      border-left: $hairline solid var(--workflow-step-separator-color);
      height: 60%;
      margin: spacer(2) 0 spacer(2) spacer(3);
    }

    &.-content {
      margin: 0 0 0 spacer(4);
      width: 100%;

      &.-bottom-padding {
        padding-bottom: spacer(30);
      }
      
    }

    &.-content-title:empty,
    &.-content-description:empty {
      display: none;
    }

    &.-childrenContainer {
      overflow: hidden;
    }
  }

  &.-lastChild {
    .wpdh-workflowCard {
      &.-spacer {
        border-left: 0;
        border-top: 0;
      }
    }
  }
}

@media (min-width: $screen-md) {
  workflow-card-convergence:not(.-vertical) {
    .workflowCard {
      &.-card {
        flex-direction: column;
        margin-bottom: spacer(4);
        padding: 0 spacer(4) 0 0;
        width: 16rem;
      }

      &.-status {
        flex-direction: row;
      }
      &.-spacer {
        border-left: 0;
        border-top: $hairline solid $colorSupportingWhite;
        width: 100%;
        margin: spacer(3) 0 0 spacer(3);
      }

      &.-content {
        margin: spacer(4) 0 0 0;

        p {
          max-width: 10rem;
        }
      }
      &.-toggle {
        icon {    
          height: 9px;
          width: 9px;     
        }
      }
    }
  }
}
