left-nav {
  nav-vertical-updated {
    display: block;
    position: sticky;
    top: 70px;
    bottom: 0;
    height: calc(100vh - 68px);
    overflow-y: auto;
    width: 300px;
    background-color: var(--left-nav-bg-color);
  }

  nav-vertical-updated.-account {
    top: 120px;
  }

  nav-vertical-rebranded {
    display: flex;
    position: sticky;
    top: 70px;
    bottom: 0;
    height: calc(100vh - 68px);
    overflow-y: auto;
    width: 272px;
    background-color: var(--left-nav-bg-color);
    flex-direction: column;
    gap: 16px;

  }

  nav-vertical-rebranded.-account {
    top: 120px;
  }

  .-doc-page-nav {
    top: 140px;
    height: calc(100vh - 140px);
  }

  .-hide-sec-nav {
    top: 0 !important;
    .-doc-page-nav {
      height: 100vh;
    }
  }

  .sidebar-opener {
    display: none;
  }
}

@media (max-width: $screen-laptop-sm) {
  left-nav {
    .-doc-page-nav {
      top: 70px !important;
      height: calc(100vh - 70px) !important;
      padding-top: 0 !important;
    }
    .sidebar-slider {
      position: fixed;
      top: 70px;
      left: 0;
      bottom: 0;
      background-color: var(--left-nav-bg-color);
      transform: translateX(-100%);
      transition: ease-in-out 300ms transform;
      width: 300px;
    }

    .sidebar-opener {
      display: inline-block;
      background-image: url('/assets/side-menu/side-right.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      width: 25px;
      height: 42px;
      position: absolute;
      left: 300px;
      top: 123px;
      cursor: pointer;
    }

    .-sec-nav-offset {
      .sidebar-opener {
        top: 73px;
      }
    }

    .-sec-nav-with-release-notes-offset {
      .sidebar-opener {
        top: 123px;
      }
    }

    .-no-sec-nav-offset {
      .sidebar-opener {
        top: 53px;
      }
    }
    .sidebar-slide-in nav-vertical-updated.-account {
      position: fixed;
      top: 50px;
    }

    .sidebar-slide-in .sidebar-opener {
      background-image: url('/assets/side-menu/side-left.svg');
      background-size: 100%;
      cursor: pointer;
      width: 25px;
      height: 42px;
    }
    .sidebar-slider.sidebar-slide-in {
      transform: translateX(0%);
      transition: ease-in-out 600ms transform;
    }

    .-overlay-container {
      z-index: unset;
      display: block;
      background-color: var(--left-nav-small-screen-overlay);
      opacity: 0.3;
      cursor: pointer;
      transform: translateX(-100%);
      transition: ease-in-out 300ms transform;
    }
    .-overlay-container.-overlay-slide-in {
      transform: translateX(0%);
      transition: ease-in-out 600ms transform;
    }
  }
}
