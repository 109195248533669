@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/clearfix';

@mixin button-reset {
  padding: 0;
  border: 0;
  background-color: transparent;

  &:focus {
    outline: none;
  }
}

@mixin list-reset {
  padding: 0;
  margin: 0;
  list-style: none;
}

@mixin pseudo-icon {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin hide-text {
  text-indent: 10000px;
  color: transparent;
  overflow: hidden;
}
