dashboard-go-live {
  h2 , h3 {
    color: $colorWpPrimaryHeadTextColor;
  }
  app-breadcrumbs {
    display: flex;
  }

  .dashboard-go-live {
    margin-top: 33px;
    
    h1 {
      border: none;
    }
  }

  .go-live {
    .-sandbox-header {
      font-weight: 700;
      margin-top: spacer(4);
      margin-bottom: spacer(6);
    }

    &.-certification {
      &.-header {
        font-weight: 700;
        margin: spacer(4) 0 spacer(6) 0;
      }

      &.-test-case-table {
        margin-top: spacer(42);
        padding-left: spacer(42);
      }

      &.-modal-text {
        color: $colorAccentGrey;
        margin-bottom: 0;
      }

      &.-modal-link {
        color: $colorPrimaryGreen;
      }

      &.-buttons {
        margin: spacer(5) 0 spacer(30);

        wpbutton {
          margin-right: spacer(4);
        }

        .-contact-btn {
          padding-right: spacer(3);
        }
      }
    }

    &.-production {
      &.-header {
        font-weight: 700;
        margin: spacer(30) 0;
        margin-top: unset;
      }
    }

    &.-selection {
      &.-subheading {
        margin: 0;
        padding-bottom: spacer(30);
      }

      &.-buttons {
        wpbutton {
          margin-right: spacer(4);
        }
      }

      &.-feature-selection {
        border-top: 1px solid var(--go-live-feature-selection-border);

        .-selection-content {
          padding-bottom: spacer(6);

          .-tooltip {
            display: flex;
            icon {
              margin: auto 0 auto spacer(2);
              svg > * {
                fill: var(--go-live-workflow-card-info-icon-color);
              }
            }
          }
        }
      }
    }

    &.-certified-title {
      margin-bottom: spacer(30);
    }
  }
}
