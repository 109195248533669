dashboard-monitoring {
  .dashboard-monitoring {
    &.-api-select {
      margin-top: 33px;

      p {
        font-weight: bold;
      }
    }

    &.-graphs {
      margin-top: spacer(42);
    }

    &.-footer-buttons {
      display: flex;

      wpbutton {
        margin: 0;
      }

      link-icon {
        margin: auto 0 auto spacer(5);
      }
    }
  }

  modal {
    .fismodal .-secondary-modal-margin {
      text-align: left;
      margin-left: spacer(4);
    }

    .monitoring-modal {
      &.-body {
        @extend .-fontSmall;
        text-align: left;
        margin: 0 spacer(4) spacer(2) spacer(4);

        p {
          color: var(--link-color);
        }
      }
    }
  }
}
