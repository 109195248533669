app-api-overview {
  h2 {
    color: $colorWpPrimaryHeadTextColor;
  }

  display: flex;
  content-viewer {
    flex: 1;
    display: flex;
    flex-direction: column;
    link-icon {
      &:hover {
        color: $colorSupportingWhite;
      }
      a {
        &:hover {
          color: var(--link-icon-text-hover-color);
        }
      }
    }

    workflow-card,
    workflow-card-convergence {
      link-icon {
        a {
          color: var(--workflow-step-text-color-default);
          &:hover {
            color: var(--workflow-step-text-color-default);
          }
        }
      }
    }

    .sec-masthead-section {
      display: flex;
      padding-bottom: spacer(42);
      .masthead-block {
        width: 71%;
        margin-right: 2%;
      }
      .sales-div {
        width: 27%;
        padding-top: spacer(3);
        .-light-font {
          color: var(--sales-section-light-font-color);
        }
        .-sales-text {
          padding-top: spacer(3);
          margin-bottom: 0;
        }
      }

      wpbutton {
        margin-top: spacerThree(10);
      }
    }

    .-api-text {
      max-width: 630px;
      font-size: $font-size;
      line-height: spacer(5);
      padding-bottom: spacerThree(11);
      margin: unset;
      color: var(--api-overview-api-text-color);
    }
    .-key-section {
      margin-top: spacerThree(11);
      padding-bottom: spacerThree(11);
      p {
        margin: unset;
      }
      .-key-section-heading {
        font-size: spacer(5);
        line-height: spacer(30);
        font-weight: 700;
        margin: unset;
        padding-bottom: spacerThree(7);
      }

      ul,
      ol {
        padding-inline-start: 20px;
        margin-top: spacer(4);

        li::marker {
          color: var(--api-overview-bullet-icon-color);
        }
      }
    }
    .-fontFraudVideo {
      font-size: spacer(5);
      line-height: spacer(30);
      font-weight: 700;
    }
    grid {
      card-product {
        p {
          font-size: 16px;
          line-height: spacer(5);
          font-weight: 400;
        }
        div.card-product.-title {
          h3 {
            line-height: 24px;
            font-size: 18px;
          }
        }
      }
    }

    .-section-border {
      &:nth-child(2) {
        grid {
          gridcolumn {
            width: 32%;
          }
        }
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    content-viewer {
      .sec-masthead-section {
        flex-direction: column;
        .masthead-block {
          width: 100%;
        }
        .sales-div {
          width: 100%;
        }
      }

      .-key-section {
        padding-bottom: spacerThree(7);
      }
      grid {
        .pill-count-style,
        .pill-count-style icon div {
          width: 36px;
          display: block;
        }
        div {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          gridcolumn {
            width: 100%;
            flex-grow: 0;
            margin-bottom: spacerThree(11);
          }
        }
      }

      .-section-border {
        &:nth-child(2) {
          grid {
            gridcolumn {
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media (min-width: $screen-sm) and (max-width: $breakpoint-sm-desktop-max) {
    content-viewer {
      .-key-section {
        padding-bottom: spacerThree(7);
      }
      grid {
        .pill-count-style,
        .pill-count-style icon div {
          width: 36px;
          display: block;
        }
        div {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          gridcolumn {
            width: 49%;
            flex-grow: 0;
            margin-bottom: spacerThree(7);
            padding: 0 spacer(3);
          }
        }
      }
    }
  }

  @media (min-width: $breakpoint-desktop) {
    content-viewer {
      .-key-section {
        padding-bottom: spacerThree(7);
      }
      grid {
        .pill-count-style,
        .pill-count-style icon div {
          width: 36px;
          display: block;
        }
        div {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          gridcolumn {
            width: 32%;
            flex-grow: 0;
            margin-bottom: spacerThree(7);
          }
        }
      }
    }
  }
}
