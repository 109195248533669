cardChain {
  display: flex;
  
  .card-chain {
    &.-container {
      display: inline-flex;
      margin-top: 60px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
    }
  
    &.-parent {
      margin-right: spacer(4);
      margin-left: spacer(4);
      margin-bottom: spacer(6);
      width: 192px;
      // Removed for no links on home page
      // height: 271px;
    }
  
    &.-white-line {
      position: absolute;
      border: 2px solid white;
      // Removed for no links on home page
      // top: 20%;
      top: 19%;
      left: 7%;
    }
  
    &.-text-parent {
      margin-top: spacer(6);
      text-align: center;
  
      h4 {
        margin-bottom: spacer(3);
      }
    }
  
    &.-text-summary {
      font-size: 14px;
      letter-spacing: 0;
      line-height: spacer(4);
    }
  
    &.-link-icon {
      margin-top: spacer(5);
      width: 192px;
      padding-left: 18%;
    }
  }
  
  .card-chain-side {
    &.-container {
      display: inline-flex;
    }
  
    &.-parent {
      // margin-right: spacer(6);
      // margin-left: spacer(6);
      margin-bottom: spacer(6);
      width: 337px;
      display: flex;
    }
  
    &.-white-line {
      position: absolute;
      border: 1px solid white;
      bottom: 115px;
      left: 120px;
    }
  
    &.-text-parent {
      position: relative;
      margin-left: spacer(5);
  
      h4 {
        margin-top: 0px;
        margin-bottom: spacer(3);
      }
    }
  
    &.-text-summary {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 18px;
    }
  
    &.-link-icon {
      margin-top: spacer(3);
      width: 192px;
      position: absolute;
      bottom: 0;
    }
  }
}


@media (max-width: $screen-md-max) {
  cardChain {
    .card-chain {
      flex-direction: column;
  
      &.-parent {
        margin-bottom: 42px;
      }
  
      &.-container {
        display: block;
      }
  
      &.-white-line {
        display: none;
      }
  
      &.-link-icon {
        margin-top: spacer(4);
      }
    }
  
    .card-chain-side {
      &.-parent {
        display: flex;
        width: auto;
        height: auto;
        position: relative;
        margin-right: 0;
        margin-bottom: 42px;
      }
  
      &.-text-parent {
        h4 {
          font-size: 0.875rem;
          margin-bottom: spacer(2);
        }
      }
      
      &.-link-icon {
        width: auto;
        font-size: 0.875rem;
      }
  
      &.-white-line {
        display: none;
      }
    }
  }
}

// @media(max-width: 1980px){ 
//   cardChain {
//     .card-chain {
//       &.-white-line {
//         top: 19%;
//       }
//     }
//   }
// }