.postman-button {
  background-color: var(--button-secondary-background-color);
  border: 0.125rem solid var(--button-secondary-border-color);
  color: var(--button-secondary-text-color);
  margin: spacerThree(7) spacerThree(2) spacerThree(4) 0;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.4375rem;
  border-radius: spacerThree(8);
  user-select: none;
  display: inline-flex;
  transition: 0.3s;
  padding: calc(spacerThree(4) - 0.125rem) calc(spacerThree(12) - 0.125rem);
  position: relative;
  align-items: center;
  cursor: pointer;
  svg {
    height: 15px;
    width: 15px;
    display: flex;
    margin: auto 0 auto spacerThree(2);
  }
  svg > * {
    fill: var(--button-secondary-text-color);
  }

  &:hover {
    color: var(--button-secondary-text-hover-color);
    // background-color: var(--button-secondary-text-hover-color);
    svg > * {
      fill: var(--button-secondary-text-hover-color);
    }

    text-decoration: none;
  }
}
