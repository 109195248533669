card-product {
  height: 100%;
  color: var(--card-product-text-color);
  border: $borderWidth solid var(--card-product-border-color);
  background-color: var(--card-product-bg-color);
  border-radius: spacer(3);
  padding: spacer(42) spacer(6);

  .card-product {
    &.-title {
      display: flex;
    }
    h3 {
      font-weight: 700;
      margin: 0 0 spacer(3) spacer(3);
      flex: 1;
      color: var(--card-product-title-color) !important;
    }
  }
  p {
    margin-bottom: spacer(2);
  }
  .grid {
    margin: 0;
    padding: 0;
    justify-content: space-between;

    icon {
      margin-top: spacer(1);
      margin-right: spacer(2);
    }

    gridColumn {
      margin-top: spacerThree(5);
      flex-direction: initial;
      width: 50%;
      flex-grow: unset;
      padding: 0;

      &.-align {
        width: auto;
      }

      app-link {
        a {
          color: var(--card-product-link-color);
          &:hover {
            color: var(--card-product-link-hover-color);
            text-decoration: var(--card-product-link-hover-decoration);
          }
        }
      }
    }

    link-icon {
      flex-direction: column;
      a {
        color: var(--card-product-link-color);
        line-height: 15px;

        icon,
        .link-icon.-parent.-text {
          margin-top: 0;
        }

        &:hover {
          color: var(--card-product-link-hover-color);
        }
      }
    }
  }
  wpbutton {
    margin-top: spacer(42);
  }
  &:hover {
    background: var(--card-product-hover-color);
    transition: 0.5s;
  }

  @media (max-width: 575px) {
    .card-product {
      h3 {
        font-size: 1.25rem;
      }
    }
  }
}
