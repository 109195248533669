app-product {
  display: flex;

  .product {
    &.-vertical-nav {
      min-height: 100vh;
      display: unset;
      z-index: 2;

      nav-vertical {
        display: block;
        position: sticky;
        top: 90px;
        padding: 18px;
        width: 222px;
        position: sticky;
        bottom: 0;
        height: calc(100vh - 90px);
        overflow-y: auto;
      }
    }

    &.-content {
      width: calc(100vw - 222px);
      padding: 0 10% 5% 3vw;
      .-baas-intro-img {
        border-radius: 10px;
        display: block;
        margin: auto;
      }
    }

    &.-vertical-nav-expand {
      display: none;
    }
    .-baas-section-spacing {
      margin-top: spacer(54);
    }
  }

  .product .masthead .-buttons-scroll-down {
    left: calc(-222px + 63%);
  }
}

@media (max-width: $screen-sm-max) {
  app-product {
    .product .masthead .-buttons-scroll-down {
      left: calc(50% - 36px);
    }
    .product {
      &.-content {
        width: 100%;
        margin-left: 0;
        transition: 200ms;
        padding: spacer(7) 6% 6% 6%;
      }

      &.-vertical-nav-expand {
        display: block;
        position: fixed;
        bottom: 15px;
        left: 15px;
        z-index: 10;
      }

      &.-slide {
        // margin-left: 2px !important;
        overflow: hidden;
      }

      &.-slide-nav {
        width: auto !important;
        overflow: unset !important;
      }

      &.-vertical-nav {
        transition: width 200ms;
        width: 0;
        overflow: hidden;
      }
      .-baas-section-spacing {
        margin-top: spacer(54);
      }
    }
  }
}

// $verticalNavSize: 222px;

// app-product {
//   display: flex;

//   .product {
//     &.-vertical-nav {
//       display: unset;
//       z-index: 2;

//       // Remove this and uncomment next line if nav has own column
//       position: fixed;
//       // min-height: 100vh;

//       nav-vertical {
//         display: block;
//         top: 90px;
//         padding: 18px;
//         width: $verticalNavSize;
//         position: sticky;
//         bottom: 0;
//         height: calc(100vh - 90px);
//         overflow-y: auto;
//       }
//     }

//     &.-content {
//       // Uncomment next line if nav has own column
//       // width: calc(100vw - $verticalNavSize);
//       padding: 0 10% 5% calc(3% + $verticalNavSize);
//     }

//     &.-vertical-nav-expand {
//       display: none;
//     }
//   }
// }

// @media (max-width: $screen-sm-max) {
//   app-product {
//     .product {
//       &.-content {
//         width: 100%;
//         margin-left: 0;
//         transition: 200ms;
//         padding: spacer(7) 10% 6% 6%;
//       }

//       &.-vertical-nav-expand {
//         display: block;
//         position: fixed;
//         bottom: 15px;
//         left: 15px;
//         z-index: 10;
//       }

//       &.-slide {
//         // margin-left: 2px !important;
//         margin-left: $verticalNavSize;
//         overflow: hidden;
//       }

//       &.-slide-nav {
//         width: auto !important;
//         overflow: unset !important;
//       }

//       &.-vertical-nav {
//         transition: width 200ms;
//         width: 0;
//         overflow: hidden;
//       }
//     }
//   }
// }
