dashboard-overview {
  .dashboard-overview {
    &.-cards {
      margin-top: 33px;
      card-product {
        p {
          font-size: 16px;
          line-height: spacer(5);
          font-weight: 400;
        }
        div.card-product.-title {
          h3 {
            line-height: 24px;
            font-size: 18px;
          }
        }
      }
    }
  }

  .guided-modal {
    // width: 352px;
    z-index: 4;
    text-align: center;

    &:after {
      position: absolute;
      height: 1.61rem;
      width: 1.61rem;
      content: '';
      background-color: var(--modal-triangle-color);
      transform: rotate(45deg);
      display: none;
      border: $hairline solid var(--fismodal-border-color);
    }

    &.-credentials-modal {
      position: fixed;
      top: 132px;
      left: 276px;

      &:after {
        display: unset;
        top: 30px;
        left: -12px;
        border-right: 0;
        border-top: 0;
      }
    }

    &.-monitoring-modal {
      position: fixed;
      top: 176px;
      left: 276px;

      &:after {
        display: unset;
        top: 30px;
        left: -12px;
        border-right: 0;
        border-top: 0;
      }
    }

    &.-documentation-modal {
      position: fixed;
      top: 382px;
      left: 468px;

      &:after {
        display: unset;
        top: -13px;
        left: 40px;
        border-right: 0;
        border-bottom: 0;
      }
    }

    &.-account-modal {
      position: fixed;
      right: 0;
      top: 74px;
      width: 352px;

      &:after {
        display: unset;
        top: -12px;
        right: 32px;
        border-bottom: 0;
        border-right: 0;
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    .guided-modal {
      display: none;
    }
  }

  @media (min-width: $screen-sm) and (max-width: $breakpoint-sm-desktop-max) {
    grid {
      div {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        gridcolumn {
          width: 49%;
          flex-grow: 0;
          margin-bottom: spacerThree(7);
          padding: 0 spacer(3);
        }
      }
    }
  }
}
