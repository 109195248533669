app-api-embed {
  height: calc(100vh - 140px);
  .-api-spec-container {
    position: relative;
    top: 70px;
    .-apimatic-container {
      height: calc(100vh - 213px);
    }
    .-apimatic-container > div {
      border-top: unset;
    }
  }
}

@media (max-width: $breakpoint-mid-two-mobile-max) {
  app-api-embed {
    .-api-spec-container {
      top: 140px;
    }
  }
}

@media (max-width: ($screen-md - 2)) {
  app-api-embed {
    .-api-spec-container {
      .-apimatic-container {
        height: calc(100vh - 141px);
      }

      app-footer {
        display: none !important;
      }
    }
  }
}
