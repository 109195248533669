.chart-parent {
  tab-container {
    color: $colorWpPrimaryBodyTextDarkColor;
    ul {
      li {
        color: var(--analytics-chart-tabs-active-hover-color);  
        button {
          &.is-active {
            color: var(--analytics-chart-tabs-active-hover-color) !important;
          }
          &:hover {
            color: var(--analytics-chart-tabs-active-hover-color) !important;
          }
        }
      }
    }
    button {
      color: $colorWpPrimaryBodyTextDarkColor !important;
    }
  }
  &.-container {
    padding: spacer(6);
    border-radius: spacer(2);
    border: var(--analytics-chart-border-color) 1px solid;
    background-color: var(--analytics-chart-bg-color);
  }

  &.-loader {
    width: inherit;
    height: inherit;
    // position: absolute;
    // top: 50%;
    // left: 50%;

    loader {
      .loader {
        &.-parent {
          position: absolute;
          z-index: 3;
        }
        &.-wrapper {
          z-index: 2;
          position: absolute;
          border-radius: spacer(2);
          background-color: var(--analytics-chart-bg-color);
        }
      }
    }
  }
}
