breadcrumb {
  .-breadcrumb-container {
    display: flex;
    color: #4d4d4d;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 16px;
    gap: 8px;
    align-items: center;
  }

  icon {
    width: 9px;
    height: 9px;
    svg > * {
      fill: #4d4d4d;
    }
  }
}
