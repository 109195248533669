site-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: spacer(3);
  max-height: 36px;
  svg {
    width: 102px;
    max-width: 102px;
    .st0 {
      fill: var(--site-logo-color);
      -webkit-transition: fill 0.6s;
      -o-transition: fill 0.6s;
      transition: fill 0.6s;
    }
  }
  span:nth-child(2) {
    border-left: 2px solid var(--logo-divider-color);
    height: 20px;
  }
  span:nth-child(3) {
    display: flex;
    svg {
      width: 89px;
      max-width: 89px;
    }

    svg > * {
      fill: var(--site-logo-text-color) !important;
    }
  }
}

@media (max-width: $breakpoint-sm-mobile-max) {
  site-logo {
    svg {
      width: 114px;
      max-width: 114px;
    }
    gap: spacerThree(3);
    span:nth-child(3) {
      font-size: 14px;
      letter-spacing: normal;
    }
  }
}

@media (max-width: $breakpoint-medium-mobile-max) {
  site-logo {
    svg {
      width: 90px;
      max-width: 90px;
    }
    gap: spacerThree(3);
    span:nth-child(3) {
      font-size: 12px;
      letter-spacing: normal;
    }
  }
}
