developers-contract {
  .developers-contract {
    &.-contract-header {
      margin-top: 0;
      margin-bottom: spacer(30);
    }

    &.-contract-text {
      color: $colorFontLightGray;
      height: 70vh;
      overflow-y: scroll;
      margin-bottom: spacer(3);
      padding-right: spacer(7);

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #0b394a;
      }
    }

    &.-contract-button {
      margin-right: spacer(3);
    }
  }
}
