tab-item {
  .tab-contents {
    max-height: 0;
    overflow-y: hidden;
    overflow-x: hidden;
    // overflow: auto;
    &.is-active {
      max-height: none;
    }
  }
}
