accordion {
  .accordion {
    &.-main {
      border-bottom: $hairline solid rgba($color: $colorSupportingWhite, $alpha: 0.2);
      display: block;
      padding: spacer(7) 0;
    }

    &.-title {
      margin-left: spacer(6);
    }

    &.-title,
    &.-date {
      margin-bottom: 0;
      margin-top: $hairline;
    }

    &.-date,
    &.-hiddenContent {
      margin-left: spacer(6);
    }

    &.-hiddenRow {
      overflow: hidden;
    }

    &.-hiddenContent {
      margin-top: spacer(3);
    }

    &.-icon {
      background-color: var(--accordion-convergence-icon-background-color);
      cursor: pointer;
      svg > * {
        fill: var(--accordion-convergence-icon-svg-color);
      }
    }
  }

  @media (min-width: $screen-md) {
    .accordion {
      &.-date {
        margin-left: 0;
        margin-right: spacer(3);
        text-align: right;
      }
    }
  }
}
