//stick on width and height and set possition for chat icon
#wo_online_image {
  bottom: 15px;
  z-index: 8;
}
#wo_online_image img#wo_onImage {
  height: spacer(6) !important;
  width: spacer(6) !important;
}

.wo-inline .bottom-right {
  right: spacer(4) !important;
}
