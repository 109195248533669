search-form {
  div {
    display: flex;
    position: relative;
  }
  input {
    height: 36px;
    padding: 8px 32px 8px 12px;
    border-radius: 4px;
    border: 1px solid #cccccc;
    &:focus-visible {
      outline: none;
    }
  }
  icon {
    position: absolute;
    right: 7px;
    top: 6px;
    cursor: pointer;

    svg > * {
      fill: #4d4d4d;
    }
  }
}
