card-api-convergence {
  transition: 0.6s;
  border-radius: 8px;
  background: var(--card-api-convergence-bg-color);
  border: $hairline solid var(--card-api-convergence-border-color);
  div {
    &.-card-container {
      padding: spacer(5);
    }
  }
  app-link {
    a {
      &:hover {
        text-decoration: none;
        color: unset;
      }
      &:focus {
        color: unset;
      }
    }
  }
  h4 {
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 900;
    line-height: 18px;
    margin-bottom: unset;
    color: var(--card-api-convergence-title-color) !important;
  }

  h3 {
    margin-top: spacerThree(3);
    font-size: 1.5rem;
    font-weight: 900;
    line-height: 26px;
    -webkit-line-clamp: 1;
    color: var(--card-api-convergence-title-color);
  }

  p {
    font-size: 1rem;
    opacity: 0.7;
    margin-top: spacerThree(3);
    line-height: 24px;
    margin-bottom: 0 !important;
    color: var(--card-api-convergence-text-color);
  }

  &:hover {
    transform: translate(0, -0.5rem);
  }
}
