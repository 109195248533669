/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Utility classes
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/utilities';
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities';

// Reset and dependencies
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize';
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/print';
// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

// Core CSS
// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type';
// @import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/code';
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid';
