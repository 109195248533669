pill-holder-collapsable {
  .collapsable-small {
    width: 24rem;
  }

  .pill-holder {
    &.-container {
      display: block;
      overflow: hidden;
      margin-top: spacer(5);
      padding-right: spacer(6);
      position: relative;
    }

    &.-linkContainer {
      position: absolute;
      right: 0;
      top: calc(-#{spacer(1)});

      &.-open {
        bottom: spacer(2);
        top: auto;
      }
    }
    &.-linkCollapse {
      cursor: pointer;
    }
  }
}
