.form {
  &.-help {
    opacity: 0.5;
    display: block;
  }

  &.-group-wrapper {
    display: flex;
    margin: 12px 0;
    margin-bottom: spacer(5);
    margin-top: spacer(3);
  }

  &.-group-wrapper-no-flex {
    display: block !important;
  }

  &.-group-element-wrapper {
    min-width: 100px;
  }

  &.-group-element {
    cursor: pointer;
    margin-right: spacer(5);
    position: relative;
    user-select: none;

    input {
      left: 0;
      width: 0;
      height: 0;
      opacity: 0;
      cursor: pointer;
      position: absolute;

      &:checked ~ .-group-element-checkbox {
        border: 1px solid var(--checkbox-checked-border-color);
        background-color: var(--checkbox-checked-border-color) !important;
        &.-checkbox-blue {
          border-color: var(--checkbox-checked-border-color);
          &.-defaulted {
            border-color: var(--checkbox-checked-border-color);
          }
          &.-subCheckbox {
            border-color: var(--checkbox-checked-border-color);
          }
        }
      }

      &:checked ~ .-group-element-checkbox:after {
        display: block;
      }

      &:checked ~ .-group-element-radio {
        border: 1px solid var(--input-radio-checked-color);
      }

      &:checked ~ .-group-element-radio:after {
        display: block;
      }

      &:focus ~ .-group-element-radio {
        border: 1px solid var(--input-radio-focus-border-color);
        box-shadow: 0px 0px 0px 2px var(--input-radio-focus-shadow-color),
          0px 0px 0px 1px var(--input-radio-focus-shadow-color-one);
      }

      &:focus ~ .-group-element-checkbox {
        background-color: var(--checkbox-focus-bg-color) !important;
        border: 1px solid var(--checkbox-focus-border-color);
        box-shadow: 0px 0px 0px 2px var(--checkbox-focus-shadow-color-one),
          0px 0px 0px 1px var(--checkbox-focus-shadow-color-two);
      }

      &:focus:checked ~ .-group-element-checkbox {
        background-color: var(--checkbox-checked-focus-bg-color-one) !important;
        border: 1px solid var(--checkbox-checked-border-color);
        box-shadow: 0px 0px 0px 4px var(--checkbox-checked-focus-shadow-color),
          0px 0px 0px 2px var(--checkbox-checked-focus-shadow-color-one);
      }

      &:focus:checked ~ .-group-element-radio {
        border: 1px solid var(--input-radio-checked-focus-border-color);
        box-shadow: 0px 0px 0px 2px var(--input-radio-checked-focus-shadow-color),
          0px 0px 0px 1px var(--input-radio-checked-focus-shadow-color-one);
      }
    }

    &:hover input ~ .-group-element-checkbox {
      background-color: var(--checkbox-hover-bg-color) !important;
      border: 1px solid var(--checkbox-hover-border-color);
      &.-checkbox-blue {
        border-color: var(--checkbox-hover-border-color);
        &.-defaulted {
          border-color: var(--checkbox-hover-border-color);
        }
        &.-subCheckbox {
          border-color: var(--checkbox-hover-border-color);
        }
      }
    }

    &:hover :checked ~ .-group-element-checkbox {
      border: 1px solid var(--checkbox-checked-border-color);
      background-color: var(--checkbox-checked-hover-bg-color) !important;
      &.-checkbox-blue {
        border-color: var(--checkbox-checked-border-color);
        &.-defaulted {
          border-color: var(--checkbox-checked-border-color);
        }
        &.-subCheckbox {
          border-color: var(--checkbox-checked-border-color);
        }
      }
    }

    &:hover :checked ~ .-group-element-radio {
      border: 1px solid var(--input-radio-checked-border-color);
      box-shadow: 0px 0px 0px 3px var(--input-radio-checked-shadow-color);
    }

    &:focus :checked ~ .-group-element-checkbox {
      border: 1.5px solid var(--checkbox-checked-border-color);
      background-color: var(--checkbox-checked-border-color) !important;
      box-shadow: 0px 0px 0px 2px var(--checkbox-focus-shadow-color-one),
        0px 0px 0px 1px var(--checkbox-focus-shadow-color-two);
      &.-checkbox-blue {
        border-color: var(--checkbox-blue-border-color);
        &.-defaulted {
          border-color: var(--checkbox-blue-defaulted-border-color);
        }
        &.-subCheckbox {
          border-color: var(--checkbox-blue-defaulted-border-color);
        }
      }
    }

    &:hover input ~ .-group-element-radio {
      border: 1px solid var(--input-radio-hover-border-color);
      box-shadow: 0px 0px 0px 3px var(--input-radio-hover-shadow-color),
        0px 0px 0px 1px var(--input-radio-hover-shadow-color-one);
    }
  }
  &.-group-element-label {
    margin-left: 33px;
  }

  &.-group-element-checkbox {
    @extend .group-element-input;
    border-radius: 2px;
    margin-top: spacer(2);
    margin-left: spacer(2);

    &:after {
      left: 4px;
      width: 4px;
      height: 8px;
      align-items: center;
      border: 1.5px solid $colorSupportingWhite;
      border-width: 0 1.5px 1.5px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &.-checkbox-blue:after {
      border-color: var(--checked-tick-color);

      &.-defaulted:after {
        border-color: var(--checked-tick-color);
      }

      &.-subCheckbox:after {
        border-color: var(--checked-tick-color);
      }
    }

    &.-checkbox-blue {
      &.-defaulted:after {
        border-color: var(--checked-tick-color);
      }

      &.-subCheckbox {
        border-color: var(--checked-tick-color);
      }
    }
  }

  &.-group-element-radio {
    @extend .group-element-input;
    border-radius: 50%;

    &:after {
      top: 2.4px;
      left: 2.4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: var(--input-radio-checked-color);
    }
  }
}

.noSelector {
  &:focus {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }
}

.group-element-input {
  height: 14px;
  width: 14px;
  background-color: var(--input-radio-bg-color) !important;
  border: 1px solid var(--input-radio-border-color);
  position: absolute;
  left: 0;

  &.-checkbox-blue {
    &.-subCheckbox {
      border-color: var(--checkbox-blue-subCheckbox-border-color);
    }
  }

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
}

@media (max-width: $screen-sm-max) {
  .form {
    &.-group {
      margin-bottom: spacer(5);
    }
    &.-group-wrapper {
      display: block;
      margin-bottom: spacer(2);
    }

    &.-group-element-wrapper {
      margin: spacer(5) 0;

      &:first-child {
        margin-top: spacer(3);
      }

      &:last-child {
        margin-bottom: spacer(2);
      }
    }
  }
}
