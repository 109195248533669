app-sdks {
  .page {
    min-height: 60%;
  }

  //   .-spec-page-wrapper {
  //     display: flex;
  //     flex-direction: column;
  //   }

  .-sdk-parent {
    min-height: 100vh !important;
  }

  .-spec-page-wrapper-apimatic {
    position: relative;
    top: 70px;
    min-height: 100vh;
  }

  .redoc-parent {
    display: inline-grid;
    width: 100%;

    // breadcrumbs {
    //   margin-left: spacer(4);
    // }
  }

  //   @media (max-width: $screen-lg) {
  //     .-doc-page-wrapper {
  //       .-content {
  //         .-docpage-content-container {
  //           padding: spacer(5) spacer(30) 0 spacer(30);
  //         }
  //       }
  //     }
  //   }

  //   @media (max-width: $screen-xs-max) {
  //     .-doc-page-wrapper {
  //       .-content {
  //         .-docpage-content-container {
  //           padding: spacer(5) spacer(4) 0 spacer(4);
  //         }
  //       }
  //     }
  //   }

  //   @media (max-width: $breakpoint-sm-mobile-max) {
  //     .-doc-page-wrapper {
  //       .-content {
  //         .-docpage-content-container {
  //           padding: spacer(5) spacer(3) 0 spacer(3);
  //         }
  //       }
  //     }
  //   }
}
