app-prod-doc {
  background-color: var(--doc-page-bg-color);
  display: flex;
  flex-direction: column;
  .-docpage-content {
    display: flex;
    gap: 40px;
    margin-bottom: 40px;
    .-left-pane {
      max-width: 680px;
    }
    .-right-pane {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 256px;
      index {
        top: 172px;
      }
    }
    .-full-width {
      width: 100%;
    }
    content-viewer {
      display: flex;
      flex-direction: column;
      gap: 24px;
      article {
        display: flex;
        flex-direction: column;
        gap: 24px;
        section {
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding-bottom: 24px;
          border-bottom: 1px solid var(--doc-page-section-border-color);

          &:last-of-type {
            border-bottom: unset;
            padding-bottom: 0;
          }

          p {
            margin: 0;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--prod-doc-page-section-description-color) !important;
          }

          strong,
          b {
            font-weight: 600;
          }

          .-auth-bearer {
            font-family: Courier;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            padding-left: 16px;
            color: var(--doc-page-section-auth-bearer-color) !important;
          }
          .-note {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            padding-left: 8px;
            border-left: 2px solid var(--doc-page-section-note-border-color);
          }

          app-link {
            a {
              color: var(--doc-page-section-link-color);
              font-weight: 600;
              img {
                display: inline-block;
              }

              &:hover {
                cursor: pointer;
                text-decoration: underline;
                text-decoration-color: var(--doc-page-section-link-color);
              }
            }
          }
          ul,
          ol {
            padding-inline-start: 14px;
            margin: 0;
            li {
              margin: 0;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: var(--prod-doc-page-section-description-color) !important;
              padding-left: 8px;
            }
            li::marker {
              color: var(--prod-doc-page-section-list-marker-color);
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
            }
          }

          accordion {
            ul,
            ol {
              li::marker {
                color: $colorPrimaryGreen;
              }
            }
          }

          workflow-setup-card {
            &:last-of-type {
              .workflow-card {
                border-bottom: unset;
                padding-bottom: unset;
              }
            }
          }

          .-padding-element:first-of-type {
            padding: spacer(42) 0 0 0;
          }

          .-padding-element {
            color: var(--body-text-color);
            padding: spacer(42) 0 0 0;
            p:first-of-type {
              margin-top: spacer(4);
            }
            p.-base-urls:first-of-type {
              margin-top: spacerThree(7);
            }

            a {
              icon.-colorPrimaryGreen svg > * {
                fill: var(--workflow-step-expan-icon-color);
              }
            }
            workflow-card,
            workflow-card-convergence {
              a {
                color: var(--workflow-step-text-color-default);
              }
              .workflow-card {
                p {
                  margin-top: 0;
                }
              }
            }

            workflow-task-convergence {
              p.workflowTask {
                a {
                  color: $colorAccentLightBlue;
                }
              }
            }

            tab-container {
              ul {
                padding-left: 0;
                margin-top: spacerThree(3);
                li {
                  margin-top: 0;
                  padding-left: 0;
                }
              }
              .tab-content {
                padding-left: 0;
                padding-top: 0;
              }
            }
            .engineTable {
              overflow: auto;
            }
            table {
              border-collapse: unset;
              border: $hairline solid var(--table-border-color);
              th {
                background-color: var(--table-row-bg-color);
                color: var(--table-head-text-color);
                padding: spacer(5);
                border: $hairline solid var(--table-border-color);
                border-left: 0;
                border-top: 0;
                border-right: 0;
                p {
                  color: var(--table-row-text-color);
                }
              }

              td {
                color: var(--table-row-text-color);
                padding: spacer(5);
                border-bottom: $hairline solid var(--table-border-color);
                vertical-align: baseline;
              }

              tr:last-child {
                td {
                  border-bottom: 0;
                }
              }
            }

            table.-addlocation-boardingfields,
            .-addlocations-settlements,
            .-send-request-table,
            .-process-refund-request-table,
            .-process-authorization-request-table,
            .-process-sale-request {
              th:first-of-type {
                width: spacer(156);
              }
              td:first-of-type {
                width: spacer(156);
              }
            }

            table.-beneficial-ownership-table,
            .-addlocations-endpoints {
              th:first-of-type {
                width: spacer(156);
              }

              th:last-of-type {
                width: spacer(156);
              }

              th {
                width: 440px;
                text-align: left;
              }

              td:first-of-type {
                width: spacer(156);
              }

              td:last-of-type {
                width: spacer(156);
              }

              td {
                width: 440px;
              }
            }

            table.-pass-updater-status {
              th:first-of-type {
                width: spacer(174);
              }

              th:last-of-type {
                width: 594px;
              }
            }

            table.-ownership-table {
              width: 1100px;
              th:last-of-type {
                width: spacer(300);
              }
              td:last-of-type {
                width: spacer(300);
              }
            }

            table.-webform-saqs-table {
              th:first-of-type {
                width: spacer(170);
              }

              th:last-of-type {
                width: 580px;
              }
            }

            table.-single-row {
              td {
                border-bottom: none;
              }
            }

            cardapi {
              link-icon {
                a {
                  color: unset;
                }
              }
            }

            section-collapsable-parent {
              link-icon {
                a {
                  color: unset;
                }
              }
            }

            .subtitle {
              margin-top: spacerThree(3) !important;
              @extend .-fontMedium;
              color: var(--doc-page-content-subtitle);
            }
          }

          workflow-card,
          workflow-card-convergence {
            link-icon {
              a {
                color: var(--workflow-step-text-color-default);
              }
            }
          }

          h2 {
            font-size: 20px;
            line-height: 30px;
            font-weight: 600;
            color: var(--prod-doc-page-section-heading-color) !important;
            margin: 0;
          }

          h3 {
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            margin: 0;
          }

          h4 {
            font-size: 18px;
            line-height: 24px;
            font-weight: 600;
            margin-top: spacerThree(3);
            color: var(--body-text-color);
          }
          h5 {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            margin: 0;
          }

          tab-container {
            display: flex;
            flex-direction: column;
            gap: 8px;
            .tab-nav {
              display: flex;
              gap: 16px;
              border-bottom: 1px solid var(--tab-container-head-border-bottom-color-updated);
              padding: 0;
              li {
                padding: 0;
                border-bottom: unset;
                button {
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 28px;
                  color: var(--tab-container-head-text-color-updated) !important;
                  padding: 0;
                }

                &.is-active {
                  font-weight: 600;
                  font-size: 18px;
                  line-height: 28px;
                  border-bottom: 2px solid var(--tab-container-head-active-color-updated) !important;
                  button {
                    color: var(--tab-container-head-active-text-color-updated) !important;
                  }
                }
              }
            }

            .tab-content {
              padding: 0;
              ul {
                padding-inline-start: 24px;
                list-style-type: disc;
                li {
                  padding-left: 0px;
                }
              }

              h5 {
                margin-top: 8px;
                &:first-of-type {
                  margin-top: 0;
                }
              }
              ul {
                margin-top: 4px;
              }
            }
          }

          .-construct-request {
            .-request-pram {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
            }
            .-request-value {
              font-family: Courier;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
            }
            tbody {
              color: var(--brand-table-text-color);
            }
            tr {
              border: 1px solid var(--brand-table-border-color);
              td {
                padding: 8px 16px;
                border: 1px solid var(--brand-table-border-color);
              }
            }
          }

          codeblock {
            .-parent {
              margin: 0;
              .-results {
                background-color: #2d2d36;
              }
            }

            .code-block {
              pre {
                background-color: #2d2d36;
              }

              &.-copy-button {
                background-image: url('#{$path-icons}/copy_new.svg');
                color: #2d2d36;
                top: 16px;
                // display: none;
              }
            }
          }

          card-product {
            h3 {
              line-height: 24px;
              font-size: 18px;
              margin-top: unset;
            }
          }

          .-letter-spacing {
            letter-spacing: 0.23px;
          }
          workflow-task,
          workflow-task-convergence {
            .workflowTask {
              &.-container {
                min-height: spacerThree(180);
              }
              &.-links {
                width: 230px;
              }
            }
          }

          accordionbranded {
            margin-bottom: 8px;
            &:first-of-type {
              margin-top: 8px;
            }
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.-content-link {
  a {
    color: var(--link-color);
    display: flex;
    align-items: flex-end;

    icon {
      margin-left: spacer(2);
      bottom: 3px;
    }
    icon.-colorPrimaryGreen svg > * {
      fill: var(--link-color);
    }
    &:hover {
      text-decoration: underline;
      color: var(--link-hover-color);
    }
  }
}

// @media (max-width: $screen-laptop-sm) {
//   app-doc {
//     .-docpage-content {
//       .-left-pane {
//         width: 100%;
//       }
//       .-right-pane {
//         display: none;
//       }
//     }
//   }
// }

// @media (max-width: $screen-mobile-small-max) {
//   app-doc {
//     .-docpage-content {
//       content-viewer {
//         .-fontDisplayXSmallHeading {
//           font-size: 26px;
//           line-height: 30px;
//         }
//       }
//     }
//   }
// }

// @media (max-width: ($screen-laptop-sm - 1)) {
//   app-doc {
//     .-docpage-content {
//       content-viewer {
//         .-padding-element {
//           padding-top: spacerThree(7);
//         }
//       }
//     }
//   }
// }

@media (max-width: ($screen-sm - 1px)) {
  app-prod-doc {
    .-docpage-content {
      flex-direction: column;
      .-left-pane {
        max-width: unset;
        .-product-masthead {
          margin-top: 0;
        }
      }
      .-right-pane {
        width: 100%;
        index {
          display: none;
        }

        contact-block {
          position: unset;
          top: unset;
          width: 100%;
          .-links {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-sm) and (max-width: ($screen-lg - 1)) {
  app-prod-doc {
    .-docpage-content {
      flex-direction: column;
      .-left-pane {
        max-width: unset;
        .-product-masthead {
          margin-top: 0;
        }
      }
      .-right-pane {
        width: 100%;
        index {
          display: none;
        }

        contact-block {
          position: unset;
          top: unset;
          width: 100%;
          .-links {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
}
