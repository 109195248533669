index {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  right: 0;
  max-width: 256px;
  background-color: var(--index-background-color);
  padding: 16px;
  border-radius: 4px;
  gap: 16px;
  z-index: 1;
  h3 {
    color: var(--index-heading-color) !important;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 1px;
  }
  app-link {
    margin-bottom: 0;
    padding-left: spacer(4);
    a {
      font-size: 14px;
      color: var(--index-text-color);
      display: flex;
      font-weight: 700;
      line-height: 20px;

      &:hover {
        color: var(--index-text-hover-color);
        text-decoration: underline;
        text-decoration-color: var(--index-text-hover-color);
      }
    }
    &:hover {
      cursor: pointer;
      a {
        color: var(--index-text-color);
        text-decoration: underline;
      }
    }
    &.-active {
      a {
        color: var(--index-text-active-color);
        text-decoration: none;
      }
    }
  }
}
