card-usecase-image {
  h3 {
    margin: 0 0 spacer(4) 0;
    font-weight: 700;
  }
  img {
    margin-bottom: spacer(4);
    width: 100%;
  }
  p {
    margin-bottom: spacer(5);
    font-weight: 700;
    font-size: 14px;
  }
  .grid {
    padding: 0;
    link-icon {
      font-weight: 700;
    }
  }
}
