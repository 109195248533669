contact-block {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 4px;
  background-color: var(--contact-widget-bg-color);
  border: 1px solid var(--contact-widget-border-color);
  position: sticky;
  top: 458px;
  .-contact-head {
    display: flex;
    gap: 8px;
    align-items: center;
    icon {
      svg > * {
        fill: #036ad8;
      }
    }
  }
  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: var(--contact-widget-heading-color) !important;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: var(--contact-widget-desc-color) !important;
  }

  app-link {
    a {
      color: var(--contact-widget-link-color);
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
      &:hover {
        color: var(--contact-widget-link-color);
        text-decoration: underline;
        text-decoration-color: var(--contact-widget-link-color);
        cursor: pointer;
      }
    }
  }
}
