#onetrust-consent-sdk #onetrust-pc-sdk h3,
#onetrust-consent-sdk #onetrust-pc-sdk h4,
#onetrust-consent-sdk #onetrust-pc-sdk h5,
#onetrust-consent-sdk #onetrust-pc-sdk h6,
#onetrust-consent-sdk #onetrust-pc-sdk p,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-ven-lst .ot-ven-opts p,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-desc,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-title,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-li-title,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-sel-all-hdr span,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-host-lst .ot-host-info,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-fltr-modal #modal-header,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-checkbox label span,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst #ot-sel-blk p,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst #ot-lst-title h3,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst .back-btn-handler p,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst .ot-ven-name,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst #ot-ven-lst .consent-category,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-leg-btn-container .ot-inactive-leg-btn,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-label-status,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-chkbox label span,
#onetrust-consent-sdk #onetrust-pc-sdk #clear-filters-handler,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-optout-signal {
  color: initial !important;
}

.ot-floating-button__front {
  background-image: url(../../assets/images/site-logo/one_trust_icon.png) !important;
  width: 36px !important;
  height: 36px !important;
}

#ot-sdk-btn-floating.ot-floating-button {
  height: 40px !important;
}
