sliding-button {
  .sliding-button {
    &.-container {
      display: flex;
      ul {
        padding: 0 1px;
        border-radius: spacer(6);
        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;
        background-color: #000e13;
        box-shadow: 0 0 0 1px #000e13;
        height: spacer(7);

        li {
          padding: 0;
          z-index: 2;
          display: flex;
          a {
            padding: 0 spacerThree(11);
            font-size: $font-size-sm;
            line-height: spacer(4);
            text-align: center;
            cursor: pointer;
            &::before {
              display: block;
              content: attr(title);
              font-weight: 700;
              height: 0;
              overflow: hidden;
              visibility: hidden;
            }
          }
          &::after {
            content: '.';
            visibility: visible;
            transition: 0.2s;
            height: spacer(4);
            display: flex;
            align-items: flex-end;
          }
          &:first-of-type {
            a {
              padding-left: spacer(6);
            }
          }
          &:last-of-type {
            a {
              padding-right: spacer(6);
            }
            &::after {
              visibility: hidden;
            }
          }
          &.-active {
            a {
              color: #c9f5ff;
              font-weight: 700;
            }
            &::after {
              visibility: hidden;
            }
          }
          &.-pre-active {
            &::after {
              visibility: hidden;
            }
          }
        }
        .-slider {
          position: absolute;
          background: $colorPrimaryBlack;
          border: 2px solid $colorShadePrimaryBlack;
          left: 0;
          transition: 0.2s;
          height: 97%;
          border-radius: spacer(5);
        }
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  sliding-button {
    .sliding-button {
      display: none !important;
    }
  }
}
