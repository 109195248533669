@import 'includes/includes';
@import 'base/base';
@import 'components/components';
@import 'pages/pages';
// @import 'base/overrides';
// @import 'lib/index';
@import 'includes/helpers';
@import 'fonts/font';
@import 'animations/animations.scss';
@import 'pages/one-trust-custom';
:root {
  --icon-main-color: #{$colorPrimaryBrandAccentLight};
  --icon-secondary-color: #{$colorTertiaryBodyColorLight};
  --icon-bg-color: #{colorSupportingWhite};

  // loader color
  --loader-color: #{$colorWpAccentDarkBlue};

  // body text color
  --body-text-color: #{$colorWpPrimaryBodyTextColor};
  --heading-color: #{$colorWpPrimaryHeadTextColor};
  --heading-color-branded: #{$colorPrimaryBodyColorLight};

  // base url color
  --base-url-color: #{$colorWpPrimaryLightBlue};

  // convergence dropdown colors
  --convergence-dropdown-bg-color: #{$colorSupportingWhite};
  --convergence-dropdown-border-color: #{$colorSupportingGray};
  --convergence-dropdown-icon-color: #{$colorWpPrimaryBodyTextColor};
  --convergence-dropdown-bg-hover-color: #{$colorSupportingGray};

  // go live test case table
  --test-case-status-check-color: #{$colorWpAccentDarkBlue};
  --test-case-action-needed-color: #{$colorWpPrimaryLightBlue};
  --test-case-status-icon-color: #{colorWpPrimaryLightBlue};

  // credentials color
  --copy-icon-color: #{$colorWpPrimaryLightBlue};

  // chart colors
  --analytics-chart-border-color: #{$colorSupportingGray};
  --analytics-chart-bg-color: #{$colorSupportingBlueCB};
  --analytics-chart-legend-bg-color-odd: #{$colorSupportingWhite};
  --analytics-chart-legend-bg-color-even: #{$colorSupportingWhite};
  --analytics-chart-tabs-active-hover-color: #{$colorSupportingWhite};

  // section collapsible color
  --section-collapsible-bg-color: #{$colorSupportingWhite};
  --section-collapsible-border-color: #{$colorSupportingGray};

  // code block colors
  --code-block-punctuation-color: #{$colorPrimaryBodyColorDark};
  --code-block-property-color: #{$colorAccentLightBlue};
  --code-block-selector-color: #{$colorPrimaryBrandAccentDark};
  --code-block-bg-color: #{$colorDeepBlue06Dp};
  --code-block-head-bg-color: #{$colorSupportingGray};
  --code-block-head-border-color: #{$colorSupportingWhite};
  --code-block-bg-container-border-color: #{$colorDeepBlue06Dp};
  --code-block-text-color: #{$colorSupportingWhite};

  --short-code-bg-color: #{$colorSupportingWhite};
  --short-code-bg-border-color: #{$colorSupportingGray};
  --short-code-text-color: #{$colorWpPrimaryHeadTextColor};

  // diagram colors
  --diagram-radio-color: #{$colorWpPrimaryLightBlue};
  --diagram-default-text-color: #{$colorWpPrimaryBodyTextColor};
  --diagram-default-title-color: #{$colorWpPrimaryHeadTextColor};
  --diagram-default-title-bg-color: #{$colorSupportingOrange};
  --diagram-default-bg-border-color: #{$colorSupportingGray};
  --diagram-start-bg-color: #{$colorWpPrimaryLightBlue};
  --diagram-default-bg-color: #{$colorSupportingWhite};

  // workflow convergence colors
  --workflow-step-bg-color-expanded: #{$colorWpAccentDarkBlue};
  --workflow-step-text-color-expanded: #{$colorSupportingWhite};
  --workflow-step-bg-color-default: #{$colorWpAccentDarkBlue};
  --workflow-step-text-color-default: #{$colorWpPrimaryBodyTextColor};
  --workflow-step-separator-color: #{$colorSupportingGray};
  --workflow-step-expan-icon-color: #{$colorWpAccentDarkBlue};
  --workflow-task-bg-color: #{$colorSupportingWhite};
  --workflow-task-border-color: #{$colorSupportingLightGray};
  --workflow-task-separator-color: #{$colorSupportingGray};
  --workflow-task-title-color: #{$colorWpPrimaryHeadTextColor};
  --workflow-task-left-bg-color: #{$colorSupportingWhite};
  --workflow-task-left-border-color: #{$colorSupportingGray};
  --workflow-card-content-title-color: #{colorWpPrimaryBodyTextColor};

  --workflow-setup-card-border-bottom-color: #{$colorPrimaryBodyColorDark};

  // doc index colors
  --doc-index-text-color: #{$colorWpPrimaryBodyTextColor};
  --doc-index-text-hover-color: #{$colorWpPrimaryLightBlue};

  --index-text-color: #{$colorPrimaryBrandAccentLight};
  --index-text-hover-color: #{$colorDeepBlue80};
  --index-text-active-color: #{$colorDeepBlue60One};
  --index-heading-color: #{$colorPrimaryBodyColorLight};
  --index-background-color: #{$primaryBackgroundLightColor};

  --contact-widget-link-color: #{$colorPrimaryBrandAccentLight};
  --contact-widget-bg-color: #{$colorInfo50};
  --contact-widget-border-color: transparent;
  --contact-widget-heading-color: #{$colorPrimaryBodyColorLight};
  --contact-widget-desc-color: #{$colorSecondaryBodyColorLight};

  // body background color default
  --body-background-color: #{$colorSupportingWhite};
  --body-background-color-branded: #{$colorSupportingWhite};

  // header color
  --header-bottom-border-color: #{$colorSupportingGray};
  --header-icon-colors: #{$colorWpPrimaryHeadTextColor};
  --header-background-color: #{$colorSupportingWhite};

  // link-color
  --link-color: #{$colorWpPrimaryLightBlue};
  --link-hover-color: #{$colorDeepBlue80};
  --link-focus-color: #{$colorSupportingLightBlue};
  --link-focus-text-color: #{$colorBorderLightGray60};

  // link-color
  --login-link-color: #{$colorWpPrimaryLightBlue};

  // link icon colors
  --link-icon-text-color: #{$colorWpPrimaryBodyTextColor};
  --link-icon-text-hover-color: #{$colorSupportingDeepBlue};
  --link-icon-color: #{$colorWpPrimaryLightBlue};
  --link-icon-hover-color: #{$colorSupportingDeepBlue};
  --link-icon-focus-color: #{$colorSupportingLightBlue};

  // colors for button primary
  --button-primary-background-color: #{$colorWpPrimaryLightBlue};
  --button-primary-background-hover-color: #{$colorSupportingDeepBlue};
  --button-primary-border-color: #{$colorWpPrimaryLightBlue};
  --button-primary-text-color: #{$colorSupportingWhite};
  --button-primary-text-hover-color: #{$colorSupportingWhite};
  --button-primary-focus-border-color: #{$colorBorderLightGray20};
  --button-primary-focus-border-color-two: transparent;

  // colors for button secondary
  --button-secondary-background-color: #{$colorSupportingWhite};
  --button-secondary-background-hover-color: #{$colorSupportingLightBlue};
  --button-secondary-border-color: #{$colorSupportingDeepBlue};
  --button-secondary-text-color: #{$colorWpPrimaryLightBlue};
  --button-secondary-text-hover-color: #{$colorSupportingBlack};
  --button-secondary-focus-shadow-color-two: transparent;

  // colors for tertiary primary
  --button-tertiary-background-color: #{$colorSupportingWhite};
  --button-tertiary-background-hover-color: #{$colorNeutralN30};
  --button-tertiary-border-color: #{$colorSupportingGreyBorder};
  --button-tertiary-text-color: #{$colorSupportingNewCaptionColor};
  --button-tertiary-text-hover-color: #{$colorSupportingNewCaptionColor};
  --button-tertiary-focus-border-color: #{$colorSupportingGreyBorder};
  --button-tertiary-disabled-border-color: #{$colorPrimaryBodyColorDark};
  --button-tertiary-focus-shadow-color-one: #{$colorBrandedBgDarkColor};
  --button-tertiary-focus-shadow-color-two: #{$colorSupportingWhite};
  --button-tertiary-focus-bg-color: #{$colorSupportingWhite};

  // logo color
  --site-logo-color: #{$colorAccentRed};
  --logo-divider-color: #{$colorSupportingGray};
  --site-logo-text-color: #{$colorWpAccentDarkBlue};

  // colors for button disabled
  --button-disabled-background-color: #{$colorSupportingLightGray10};
  --button-disabled-border-color: #{$colorSupportingLightGray10};
  --button-disabled-text-color: #{colorSupportingWhite};

  //redoc
  --redoc-header-para-string-color: #{$colorAccentPurple};
  --redoc-text-color: #{$colorWpPrimaryHeadTextColor};
  --redoc-text-color: #{$colorWpPrimaryBodyTextColor};
  --redoc-tab-selected-color: #{$colorAccentLightCoolPurple};
  --redoc-tab-error-color: #{$colorAccentLightCoolRed};
  --redoc-expand-copy-hover-color: #{$colorSupportingGray};

  // apimatic
  --icon-color-apimatic: #{$colorSupportingGray};
  --download-spec-border-color: #{$colorSupportingGray};
  --search-icon-color: #191919;
  --ctrlkColor: #b3b3b3;
  --response-section-bg-color: #{$colorWpPrimaryHeadTextColor};
  --response-status-code-active-color: #{$colorSupportingBlue};

  // main nav color
  --main-nav-menu-item-color: #{$colorWpPrimaryHeadTextColor};
  --main-nav-menu-item-color-hover: #{$colorWpPrimaryHeadTextColor};
  --main-nav-menu-item-color-active: #{$colorWpPrimaryLightBlue};
  --main-nav-menu-item-color-visited: #{$colorWpPrimaryHeadTextColor};
  --main-nav-menu-item-menu-pulldown-color: #{$colorWpPrimaryLightBlue};

  // functional nav color
  --functional-nav-menu-item-color: #{$colorWpPrimaryLightBlue};
  --functional-nav-menu-item-hover-color: #{$colorWpAccentDarkBlue};
  --functional-nav-login-item-color-hover: #{$colorWpAccentDarkBlue};

  // header mega dropdown colors
  --header-mega-dropdown-border-color: #{$colorSupportingLightGray};
  --header-mega-dropdown-background-color: #{$colorSupportingWhite};
  --header-mega-dropdown-right-pane-left-border-color: #{$colorSupportingGray};
  --header-mega-dropdown-left-pane-heading-color: #{$colorWpPrimaryHeadTextColor};

  // account pull down colors
  --account-pull-down-background-color: #{$colorSupportingWhite};

  // tab container colors
  --tab-container-text-color: #{$colorDropDownIcon};
  --tab-container-text-weight: 400;
  --tab-container-active-text-color: #{$colorWpPrimaryHeadTextColor};
  --tab-container-active-text-weight: 700;
  --tab-container-active-underline-color: #{$colorWpPrimaryLightBlue};

  --tab-container-head-text-color-updated: #{$colorQuadBodyColorLight};
  --tab-container-head-active-text-color-updated: #{$colorPrimaryBodyColorLight};
  --tab-container-head-border-bottom-color-updated: #{$colorPrimaryBodyColorDark};
  --tab-container-head-active-bottom-color-updated: #{$colorPrimaryBodyColorDark};
  --tab-container-head-active-color-updated: #{$colorBrandDeepBlue800};

  // tab items colors
  --tab-item-heading-color: #{$colorWpPrimaryHeadTextColor};
  --tab-item-link-color: var(--link-color);
  --tab-item-link-hover-color: var(--link-hover-color);

  // tab block colors
  --tab-block-active-color: #{$colorWpPrimaryBodyTextColor};
  --tab-block-active-border-color: #{$colorSupportingDeepBlue};
  --tab-block-default-border-color: #{$colorPrimaryBodyColorDark};

  // footer color
  --small-footer-border-top: #{$colorSupportingGray};
  --footer-separator-color: #{$colorSupportingGray};
  --footer-big-separator-color: #{$colorSupportingLightGray30};
  --footer-social-icon-color: var(--link-color);
  --footer-social-icon-hover-color: var(--link-hover-color);
  --footer-secondary-bg-color: #{$colorSupportingWhite};
  --footer-looking-for-color: #{$colorWpPrimaryHeadTextColor};
  --footer-bg-image: url('/assets/images/footer_bg.png');

  // masthead colors
  --masthead-title-color: #{$colorWpPrimaryHeadTextColor};
  --masthead-sub-title-color: #{$colorWpPrimaryLightBlue};
  --masthead-tag-bg-color: #{$colorSupportingWhite};
  --masthead-old-bg-color: #{$colorSupportingWhite};
  --masthead-tag-border-color: #{$colorWpPrimaryLightBlue};
  --masthead-tag-text-color: #{$colorWpPrimaryHeadTextColor};

  //masthead updated color
  --masthead-updated-text-color: #{$colorWpAccentDarkBlue};

  // carousel tile color
  --carousel-tile-navigator-bg-color: #{$colorSupportingWhite};
  --carousel-tile-bg-color: #{$colorSupportingWhite};
  --carousel-tile-border-color: #{$colorSupportingLightGray};
  --carousel-tile-title-color: #{$colorWpPrimaryHeadTextColor};
  --carousel-tile-text-color: #{$colorWpPrimaryBodyTextColor};
  --corousel-tile-icon-bg-color: #{$colorSupportingWhite};
  --corousel-tile-icon-border-color: #{$colorSupportingLightGray};
  --corousel-tile-icon-color: #{$colorWpPrimaryLightBlue};

  // testimonial color
  --testimonial-text-color: #{$colorSupportingWhite};
  --testimonial-bg-color: #{$colorSecondaryBgColorDark};
  --testimonial-identifier-color: #{$colorPrimaryBrandAccentDark};

  // article card colors
  --article-image-card-title-color: #{$colorWpPrimaryHeadTextColor};
  --article-image-card-caption-color: #{$colorWpPrimaryBodyTextColor};
  --article-image-card-text-color: #{$colorWpPrimaryBodyTextColor};
  --article-image-content-card-border-color: #{$colorSupportingLightGray};
  --article-image-card-container-color: #{$colorSupportingWhite};

  // pill color
  --pill-bg-color: #{$colorAccentLightBlue};
  --pill-text-color: #{$colorSupportingWhite};

  // pill country color
  --pill-country-bg-color: #{$colorSupportingWhite};
  --pill-country-text-color: #{$colorWpPrimaryBodyTextColor};
  --pill-line-color: #cccccc;

  // button icon background color
  --button-icon-bg-color: #{$colorWpPrimaryLightBlue};
  --button-icon-bg-hover-color: #{$colorWpAccentDarkBlue};
  --button-icon-color: #{$colorSupportingWhite};

  // icon color
  --icon-clor: #{$colorWpPrimaryLightBlue};
  --modal-icon-clor: #{$colorIconDefault};

  // card product colors
  --card-product-bg-color: #{$colorSupportingWhite};
  --card-product-border-color: #{$colorSupportingLightGray};
  --card-product-hover-color: #{$colorSupportingBlue};
  --card-product-icon-color: #{$colorWpPrimaryLightBlue};
  --card-product-text-color: #{$colorWpPrimaryBodyTextColor};
  --card-product-title-color: #{$colorWpPrimaryHeadTextColor};
  --card-product-link-color: var(--link-color);
  --card-product-link-hover-color: var(--link-hover-color);
  --card-product-link-hover-decoration: 'unset';

  //card product updated
  --card-product-updated-border-color: #{$colorPrimaryBorderColorLight};
  --card-product-updated-shadow-color: #{$colorSupportingGreyUpdatedDark};
  --card-product-updated-shadow-color-one: #{$colorSupportingNewBoxShadowColor2};
  --card-product-updated-shadow-color-two: #{$colorSupportingNewBoxShadowColor2};
  --card-product-updated-shadow-color-three: #{$colorSupportingNewBoxShadowColor2};
  --card-product-updated-shadow-hover-color-one: #{$colorSupportingNewBoxShadowColor3};
  --card-product-updated-shadow-hover-color-two: #{$colorSupportingNewBoxShadowColor4};
  --card-product-updated-shadow-hover-color-three: #{$colorSupportingNewBoxShadowColor4};
  --card-product-updated-title-color: #{$colorPrimaryBodyColorLight};
  --card-product-updated-text-color: #{$colorSecondaryBodyColorLight};
  --card-product-updated-link-color: #{$colorPrimaryBrandAccentLight};
  --card-product-updated-bg-color: #{$colorSecondaryBgColorLight};

  // category card
  --category-card-box-shadow-color-one: #{$colorSupportingNewBoxShadowColor};
  --category-card-box-shadow-color-two: #{$colorSupportingNewBoxShadowColor2};
  --category-card-box-bg-color: #{$colorSupportingWhite};
  --card-product-category-caption-color: #{$colorSecondaryBodyColorLight};
  --card-product-category-title-color: #{$colorPrimaryBodyColorLight};
  --card-product-category-description-color: #{$colorSecondaryBodyColorLight};
  --card-product-category-link-color: #{$colorPrimaryBrandAccentLight};

  // category product card
  --card-category-product-title-color: #{$colorPrimaryBrandAccentLight};
  --card-category-product-description-color: #{$colorSecondaryBodyColorLight};
  --card-category-product-bg-color: 'transparent';
  --card-category-product-shadow-color-one: #{$colorSupportingNewBoxShadowColor6};
  --card-category-product-color-two: #{$colorSupportingNewBoxShadowColor4};
  --card-category-product-color-three: #{$colorSupportingGreyUpdatedDarkShade};

  //card product small
  --card-product-small-border-color: #{$colorAccentLightWhite};
  --card-product-small-title-color: #{$colorPrimaryBodyColorLight};
  --card-product-small-text-color: #{$colorSecondaryBodyColorLight};
  --card-product-small-link-color: #{$colorPrimaryBrandAccentLight};
  --card-product-small-bg-color: #{$colorSecondaryBgColorLight};
  --card-product-small-shadow-color-one: #{$colorSupportingNewBoxShadowColor2};
  --card-product-small-shadow-color-two: #{$colorSupportingNewBoxShadowColor2};
  --card-product-small-shadow-color-three: #{$colorSupportingNewBoxShadowColor2};
  --card-product-small-shadow-hover-color-one: #{$colorSupportingNewBoxShadowColor3};
  --card-product-small-shadow-hover-color-two: #{$colorSupportingNewBoxShadowColor3};
  --card-product-small-shadow-hover-color-three: #{$colorSupportingNewBoxShadowColor4};
  --card-product-small-border-top-purplecolor: #{$colorCardProductTopBorderPurple};
  --card-product-small-border-top-purple-color: #{$colorCardProductTopBorderLightPurple};
  --card-product-small-border-top-orange-color: #{$colorAccentLightOrange};
  --card-product-small-border-top-indigo-color: #{$colorCardProductTopBorderIndigo};
  --card-product-small-border-top-blue-color: #{$colorAccentLightBlue};

  //card support
  --card-support-bg-color: #{$colorCardSupportBackgroundPaleCyan};
  --card-support-shadow-color-one: #{$colorSupportingGreyUpdatedDarkShade};
  --card-support-shadow-color-two: #{$colorSupportingNewBoxShadowColor5};
  --card-support-shadow-color-three: #{$colorSupportingNewBoxShadowColor4};
  --card-support-border-color: #{$colorSupportingNewLightBlue};
  --card-support-title-color: #{$colorPrimaryBodyColorLight};
  --card-support-text-color: #{$colorPrimaryBodyColorLight};
  --card-support-link-color: #{$colorWpWhiteTitleShade};

  // updated masthead gradient colors
  --updated-masthead-gradient-text-color: #{$colorPrimaryBodyColorLight};
  --updated-masthead-gradient-caption-color: #{$colorPrimaryBrandAccentLight};

  //mastedhead basic updated
  --masthead-basic-updated-bg-color: #{$colorSupportingLavender};
  --masthead-basic-updated-title-color: #{$colorPrimaryBodyColorLight};
  --masthead-basic-updated-text-color: #{$colorSecondaryBodyColorLight};
  --masthead-basic-updated-link-color: #{$colorWpFontDark};
  --masthead-basic-bg-footer-image: url('/assets/images/platforms/rectangle-purple.png');
  --masthead-basic-bg-footer-image-two: url('/assets/images/footer_bg.png');
  --masthead-basic-updated-after-color: #{$colorSupportingLavender};
  --masthead-basic-bg-footer-image-color: #{$colorBrandImageBgLightColor};

  // branded footer bg color
  --branded-footer-bg-color: #{$colorSupportingLavender};

  // card basic color
  --card-basic-border-color: #{$colorSupportingLightGray};
  --card-basic-title-color: #{$colorWpPrimaryHeadTextColor};

  // card api convergence color
  --card-api-convergence-bg-color: #{$colorSupportingWhite};
  --card-api-convergence-title-color: #{$colorWpPrimaryLightBlue};
  --card-api-convergence-border-color: #{$colorSupportingLightGray};
  --card-api-convergence-text-color: #{$colorWpPrimaryBodyTextColor};

  // Best in class APIs section title color
  --best-apis-section-color: #{$colorWpPrimaryHeadTextColor};

  // payment figures colors
  --payment-figures-title-color: #{$colorWpPrimaryHeadTextColor};

  // related card color
  --card-related-font-color: #{$colorAccentFont};
  --card-related-title-color: #{$colorWpPrimaryBodyTextColor};
  --card-related-description-color: #{$colorSecondaryBodyColorLight};
  --card-related-link-color: #{$colorPrimaryBrandAccentLight};
  --card-related-link-hover-color: #{$colorRelatedShadowColor5};
  --card-related-box-shadow-color-one: #{$colorSupportingNewBoxShadowColor2};
  --card-related-box-shadow-color-two: #{$colorSupportingNewBoxShadowColor2};
  --card-related-box-shadow-color-three: #{$colorSupportingNewBoxShadowColor2};
  --card-related-box-shadow-hover-color-one: #{$colorSupportingNewBoxShadowColor4};
  --card-related-box-shadow-hover-color-two: #{$colorSupportingNewBoxShadowColor4};
  --card-related-box-shadow-hover-color-three: #{$colorSupportingNewBoxShadowColor4};
  --card-related-box-border-color: #{$colorSupportingGreyUpdated};
  --card-related-box-bg-color: #{$colorSupportingWhite};

  // category section color
  --category-title-color: #{$colorPrimaryBodyColorLight};
  --category-bg-color: #{$colorSupportingWhite};

  // category container section
  --category-container-bg-color: #{$colorSupportingWhite};

  // category product color
  --category-product-title-color: #{$colorPrimaryBrandAccentLight};
  --category-product-title-hover-color: #{$colorRelatedShadowColor5};

  // page component bg color
  --page-bg-color: #{$colorSupportingWhite};
  --pg-heading-color: #{$colorWpPrimaryHeadTextColor};
  --pg-section-border-color: #{$colorSupportingGray};
  --pg-section-left-border-color: #{$colorSupportingGray};

  // secondary masthead color
  --secondary-masthead-title-color: #{$colorPrimaryBodyColorLight};
  --secondary-masthead-detail-color: #{$colorSecondaryBodyColorLight};
  --secondary-masthead-border-bottom-color: #{$colorPrimaryBodyColorDark};
  --masthead-secondary-title-color: #{$colorPrimaryBodyColorLight};
  --masthead-secondary-detail-color: #{$colorSupportingNewCaptionColor};
  --masthead-applicable-color: #{$colorPrimaryBodyColorLight};

  // sales section color
  --sales-section-light-font-color: #{$colorWpPrimaryLightBlue};

  // list marker color
  --list-color: #{$colorWpPrimaryLightBlue};

  // form color
  --input-background-color: #{$colorSupportingWhite};
  --input-border-color: #{$colorSupportingGreyBorder};
  --input-border-hover-color: #{$colorBorderLightGray40};
  --input-border-focus-color: #{$colorBorderLightGray60};
  --input-border-focus-shadow-color: #{$colorBorderLightGray20};
  --input-mandatory-color: #{$colorSupportingDarkThemeRedLight};
  --input-disabled-color: #{$colorPrimaryBodyColorDark};
  --input-disabled-border-color: #{$colorSupportingGreyBorder};
  --input-text-color: #{$colorWpPrimaryBodyTextColor};
  --input-placeholder-text-color: #{$colorSecondaryBodyColorDark};
  --input-label-color: #{$colorSecondaryBodyColorLight};

  // multi dropdown color
  --dropdown-text-color: #{$colorWpPrimaryBodyTextColor};
  --dropdown-pull-down-bg-color: #{$colorSupportingWhite};
  --dropdown-pull-down-border-color: #{$colorPrimaryBorderColorLight};
  --dropdown-pull-down-shadow-color: #{$colorSupportingNewBoxShadowColor2};
  --dropdown-checkbox-hover-bg-color: #{$colorSupportingGreyUpdatedDark};
  --dropdown-checkbox-checked-border-color: #{$colorWpAccentDarkBlue};
  --dropdown-checkbox-checked-tick-color: #{$colorWpAccentDarkBlue};
  --dropdown-checkbox-default-color: #{$colorWpAccentDarkBlue};
  --dropdown-bg-hover-color: #{$colorSupportingGray};
  --dropdown-text-hover-color: #{$colorWpAccentDarkBlue};
  --dropdown-icon-color: #{$colorDropDownIcon};

  --dropdown-hover-color: #{$colorBorderLightGray40};
  --dropdown-focus-color: #{$colorBorderLightGray60};
  --dropdown-focus-shadow-color: #{$colorBorderLightGray20};

  // multi filter button
  --filter-button-border-color: #{$colorSupportingLightGray};

  // api left menu color
  --api-left-menu-hover-bg-color: #{$colorSupportingGray};
  --api-left-menu-hover-text-color: #{$colorWpAccentDarkBlue};
  --api-left-menu-active-text-color: #{$colorWpAccentDarkBlue};
  --api-left-menu-text-color: #{$colorWpPrimaryLightBlue};

  // pagination color
  --paginator-border-color: #{$colorWpPrimaryLightBlue};
  --paginator-text-color: #{$colorWpAccentDarkBlue};
  --paginator-active-text-color: #{$colorSupportingWhite};
  --paginator-active-hover-bg-color: #{$colorWpPrimaryLightBlue};

  // 404 color
  --four-o-four-heading-color: #{$colorWpPrimaryHeadTextColor};

  // login / forgot pass color
  --login-bg-color: #{$colorSupportingWhite};
  --login-image-bg-color: #{$colorWpAccentDarkBlue};
  --login-heading-color: #{$colorWpPrimaryHeadTextColor};
  --login-input-bg-color: #{$colorSupportingWhite};
  --login-input-border-color: #{$colorBorderLightGray40};
  --login-input-text-color: #{$colorWpPrimaryBodyTextColor};
  --login-separator-color: #{$colorSupportingGray};
  --forgot-pass-info-color: #{$colorWpPrimaryLightBlue};
  --login-input-focus-border-color: #{$colorBorderLightGray60};
  --login-input-focus-border-box-color: #{$colorBorderLightGray20};

  // search page colors
  --search-page-background-color: #{$colorSupportingWhite};
  --search-page-title-color: #{$colorWpPrimaryHeadTextColor};
  --search-page-highlight-color: #{$colorAccentLightBlue};
  --search-input-carret-color: #{$colorWpAccentDarkBlue};
  --search-text-color: #{$colorWpPrimaryBodyTextColor};
  --search-close-icon-hover-color: #{$colorWpAccentDarkBlue};
  --search-result-border-bottom-color: #{$colorSupportingLightGray};
  --search-pager-active-color: #{$colorWpPrimaryLightBlue};
  --search-pager-navigation-icon-color: #{$colorWpPrimaryLightBlue};
  --search-pager-navigation-hover-icon-color: #{$colorWpAccentDarkBlue};
  --search-pager-page-dots-color: #{$colorWpAccentDarkBlue};
  --search-close-icon-border-bottom: #{$colorSupportingLightGray};
  --search-pager-hover-text-color: #{$colorPrimaryGreen};

  // sf modal color
  --sf-modal-bg-color: #{$colorSupportingWhite};
  --sf-modal-restart-icon-color: #{$colorWpPrimaryLightBlue};
  --sf-modal-close-icon-color: #{$colorAccentDarkMediumGrey};
  --sf-intro-text-color: #{$colorWpAccentDarkBlue};

  // fismodal color
  --fismodal-bg-color: #{$colorSupportingWhite};
  --fismodal-border-color: #{$colorSupportingGreyUpdated};
  --modal-text-color: #{$colorSecondaryBodyColorLight};
  --modal-asterix-color: #{$colorSupportingDarkThemeRedLight};
  --modal-shadow-color: #{$colorSupportingNewBoxShadowColor2};
  --modal-triangle-color: #{$colorSupportingWhite};

  // sf progress color
  --sf-progress-bg-color: #{$colorWpAccentDarkBlue};
  --sf-progress-text-color: #{$colorSupportingWhite};
  --sf-progress-border-color: #{$colorWpAccentDarkBlue};
  --sf-progress-default-border-color: #{$colorWpPrimaryLightBlue};
  --sf-progress-default-text-color: #{$colorWpPrimaryLightBlue};
  --sf-progress-separator-color: #{$colorSupportingGray};

  --sf-answer-tile-bg-color: #{$colorSupportingWhite};
  --sf-answer-tile-border-color: #{$colorSupportingLightGray};
  --sf-answer-section-border-color: #{$colorSupportingGray};
  --sf-answer-section-help-text-color: #{$colorWpPrimaryLightBlue};

  --sf-text-color: #{$colorWpPrimaryBodyTextColor};
  --sf-title-color: #{$colorWpPrimaryHeadTextColor};

  --input-radio-border-color: #{$colorCheckBoxUncheckedDefault};
  --input-radio-bg-color: #{$colorSupportingWhite};
  --input-radio-border-hover-color: #{$colorWpPrimaryLightBlue};
  --input-radio-selection-color: #{$colorWpPrimaryLightBlue};
  --input-radio-hover-border-color: #{$colorSecondaryBodyColorLight};
  --input-radio-hover-shadow-color: #{$colorPrimaryBodyColorDark};
  --input-radio-hover-shadow-color-one: #{$colorBrandedBgDarkColor};
  --input-radio-hover-shadow-color-two: #{$colorSupportingWhite};
  --input-radio-checked-color: #{$colorPrimaryBrandAccentLight};
  --input-radio-checked-hover-shadow-color: #{$colorBorderLightGray20};
  --input-radio-focus-border-color: #{$colorDropDownIcon};
  --input-radio-focus-shadow-color: #{$colorBrandedBgDarkColor};
  --input-radio-focus-shadow-color-one: #{$colorSupportingWhite};
  --input-radio-checked-border-color: #{$colorAccentDeepBlue};
  --input-radio-checked-shadow-color: #{$colorBorderLightGray20};
  --input-radio-checked-focus-border-color: #{$colorAccentDeepBlue};
  --input-radio-checked-focus-shadow-color: #{$colorBrandedBgDarkColor};
  --input-radio-checked-focus-shadow-color-one: #{$colorSupportingWhite};

  // basecard color
  --base-card-text-color: #{$colorWpPrimaryBodyTextColor};
  --base-card-left-border: #{$colorPrimaryBrandAccentDark};
  --base-card-list-marker-color: var(--list-color);

  // api overview colors
  --api-overview-api-text-color: #{$colorWpPrimaryBodyTextColor};
  --api-overview-bullet-icon-color: var(--list-color);

  // doc page colors
  --doc-page-bg-color: #{$colorSupportingWhite};
  --doc-page-content-subtitle: #{colorWpPrimaryBodyTextColor};
  --doc-image-bg-color: #{$colorSupportingBlueCB};
  --doc-page-section-border-color: #{$colorPrimaryBodyColorDark};
  --doc-page-section-heading-color: #{$colorPrimaryBodyColorLight};
  --doc-page-section-description-color: #{$colorPrimaryBodyColorLight};
  --doc-page-section-list-marker-color: #{$colorSecondaryBodyColorLight};
  --doc-page-section-auth-bearer-color: #{$colorSecondaryBodyColorLight};
  --doc-page-section-note-border-color: #{$colorQuadBodyColorLight};
  --doc-page-section-related-link-color: #{$colorPrimaryBrandAccentLight};
  --doc-page-section-link-color: #{$colorAccentFont};
  --prod-doc-page-section-heading-color: #{$colorPrimaryBodyColorLight};
  --prod-doc-page-section-description-color: #{$colorPrimaryBodyColorLight};
  --prod-doc-page-section-list-marker-color: #{$colorSupportingNewCaptionColor};
  
  
  --related-title-color: #{$colorPrimaryBodyColorLight};
  --related-link-color: #{$colorPrimaryBrandAccentLight};

  //lead gen
  --drop-down-font-color: #{$colorWpPrimaryBodyTextColor};

  // spec page color
  --spec-page-left-bg-color: #{$colorSupportingWhite};
  --spec-page-left-separator: #{$colorSupportingGray};
  --spec-page-intro-header-color: #{$colorWpPrimaryHeadTextColor};
  --spec-page-api-info-color: #{$colorWpPrimaryBodyTextColor};
  --spec-page-api-email-color: #{$colorWpPrimaryBodyTextColor};
  --spec-page-auth-description-color: #{$colorWpPrimaryBodyTextColor};
  --spec-page-link-color: var(--link-color);
  --spec-page-link-hover-color: var(--link-hover-color);
  --spec-page-download-button-border-color: #{$colorWpPrimaryLightBlue};
  --spec-page-download-button-color: #{$colorWpPrimaryLightBlue};
  --spec-page-download-button-border-hover-color: #{$colorWpAccentDarkBlue};
  --spec-page-download-button-hover-color: #{$colorWpAccentDarkBlue};
  --spec-auth-input-border-color: var(--input-border-color);
  --spec-auth-input-border-focus-color: var(--input-focus-color);
  --spec-auth-input-background-color: var(--input-background-color);
  --spec-auth-input-placeholder-color: #{$colorWpPrimaryBodyTextColor};
  --spec-auth-head-color: #{$colorWpPrimaryHeadTextColor};
  --spec-page-h5-color: #{$colorWpPrimaryHeadTextColor};
  --spec-page-h5-icon-color: #{$colorWpPrimaryBodyTextColor};
  --spec-page-h5-text-color: #{$colorWpPrimaryBodyTextColor};
  --spec-page-table-text-color: #{$colorWpPrimaryBodyTextColor};
  --spec-page-example-text-color: #{$colorWpSecondaryBodyTextColor};
  --spec-page-table-text-td-border-bottom-color: #{$colorSupportingGray};
  --spec-page-accent-color: #{$colorWpAccentDarkBlue};
  --spec-page-accordian-bg-color: #{$colorSupportingLightGray};
  --spec-page-thirdpane-bg-color: #{$colorSupportingGray};
  --spec-page-endpoint-trial-bg-color: #{$colorSupportingGray};
  --spec-page-endpoint-bg-color: var(--input-background-color);
  --spec-page-endpoint-text-color: #{$colorWpPrimaryBodyTextColor};
  --spec-page-endpoint-trial-title-color: #{$colorWpPrimaryHeadTextColor};
  --spec-page-request-sample-bg-color: #{$colorWpPrimaryHeadTextColor};
  --spec-page-request-sample-code-bg-color: #{$colorWpPrimaryHeadTextColor};
  --spec-page-request-sample-type-color: #{$colorWpSecondaryBodyTextColor};
  --spec-auth-input-color: #{$colorWpPrimaryBodyTextColor};
  --spec-page-success-notification-border-color: #{$colorSupportingGreen};
  --spec-page-success-notification-background-color: #{$colorSupportingGreenLight};
  --spec-page-notification-text-color: #{$colorPrimaryBlack};
  --spec-page-error-notification-border-color: #{$colorSupportingOrange};
  --spec-page-error-notification-background-color: #{$colorSupportingRed};
  --spec-page-editor-variable-color: #{$colorAccentLightBlue};
  --spec-page-editor-bg-color: #{$colorWpPrimaryHeadTextColor};
  --spec-page-response-bg-color: #{$colorSupportingWhite};
  --spec-page-modal-bg-color: #{$colorSupportingWhite};
  --spec-page-modal-border-color: #{$colorSupportingGray};
  --spec-page-modal-text-color: #{$colorWpPrimaryBodyTextColor};

  // spec page left nav color
  --spec-left-nav-search-bottom-color: #{$colorSupportingGray};
  --spec-left-nav-search-icon-color: #{$colorWpPrimaryBodyTextColor};
  --spec-left-nav-search-text-color: #{$colorWpPrimaryBodyTextColor};
  --spec-left-nav-text-color: #{$colorWpPrimaryBodyTextColor};
  --spec-left-nav-text-hover-color: #{$colorWpPrimaryLightBlue};
  --spec-left-nav-right-icon-color: #{$colorWpPrimaryBodyTextColor};
  --spec-left-nav-right-icon-hover-color: #{$colorWpPrimaryLightBlue};
  --spec-left-nav-active-color: #{$colorWpPrimaryLightBlue};
  --spec-left-nav-bg-color: #{$colorSupportingWhite};
  --spec-left-nav-search-bg-color: #{$colorSupportingGray};

  // left nav colors
  --left-nav-bg-color: #{$colorSupportingWhite};
  --left-nav-heading-color: #{$colorWpPrimaryHeadTextColor};
  --left-nav-text-color: #{$colorWpPrimaryBodyTextColor};
  --left-nav-active-text-color: #{$colorWpPrimaryLightBlue};
  --left-nav-active-text-color-level-1: #{$colorWpPrimaryLightBlue};
  --left-nav-bg-hover-color: #{$colorSupportingGray};
  --old-left-nav-bg-hover-color: #{$colorSupportingGray};
  --left-nav-bg-hover-color-level-1: #{$colorSupportingLightGray};
  --left-nav-border-color: #{$colorWpPrimaryLightBlue};
  --left-nav-small-screen-overlay: #{$colorSupportingGray};
  --left-nav-vertical-text-color: #{colorWpPrimaryBodyTextColor};
  --left-nav-vertical-text-hover-color: #{$colorWpPrimaryLightBlue};

  // notification color
  --notification-success-border-color: #{$colorSupportingGreen};
  --notification-success-background-color: #{$colorSupportingGreenLight};
  --notification-error-border-color: #{$colorSupportingOrange};
  --notification-error-background-color: #{$colorSupportingRed};
  --notification-notes-yellow-border-color: #{$colorAccentYellow};
  --notification-notes-yellow-background-color: #{$colorNotificationYellow};
  --notification-notes-border-color: #{$colorAccentLightBlue};
  --notification-notes-background-color: #{$colorSupportingNotesLightBlue};
  --notification-background-color: #{$colorSupportingWhite};
  --notification-text-color: #{$colorPrimaryBlack};
  --notification-link-color: #{$colorWpPrimaryLightBlue};

  // doc header
  --doc-header-link-color: #{$colorWpPrimaryBodyTextColor};
  --doc-header-link-hover-bg-color: #{$colorSupportingGray};
  --doc-header-link-active-color: #{$colorWpPrimaryLightBlue};
  --doc-header-bottom-border-color: #{$colorSupportingGray};
  --doc-header-bg-color: #{$colorSupportingWhite};

  // most popular
  --most-popular-title-color: #{$colorPrimaryBodyColorLight};
  --most-popular-link-color: #{$colorPrimaryBrandAccentLight};

  // reference cards colors
  --reference-cards-title-color: #{$colorPrimaryBodyColorLight};
  --reference-cards-description-color: #{$colorSecondaryBodyColorLight};
  --reference-cards-link-color: #{$colorPrimaryBrandAccentLight};
  --reference-cards-bg-color: #{$colorSupportingWhite};

  // version control dropdown colors
  --version-control-bg-color: #{$colorSupportingWhite};
  --version-control-bg-hover-color: #{$colorIconCircleGray};
  --version-control-border-color: #{$colorSupportingGreyBorder};
  --version-control-border-hover-color: #{$colorBorderLightGray40};
  --version-control-border-focus-color: #{$colorBorderLightGray60};
  --version-control-focus-shadow-color: #{$colorBorderLightGray20};
  --version-control-flip-color: #{$colorDropDownIcon};
  --version-control-text-color: #{$colorWpPrimaryBodyTextColor};
  --version-control-text-hover-color: #{$colorSupportingWhite};

  // request method colors
  --request-method-bg-color: #{$colorSupportingWhite};
  --request-method-post-text-color: #{$colorWpPrimaryLightBlue};
  --request-method-post-border-color: #{$colorWpPrimaryLightBlue};
  --request-method-get-text-color: #{$colorSupportingPurple};
  --request-method-get-border-color: #{$colorSupportingPurple};
  --request-method-delete-text-color: #{$colorSupportingOrange};
  --request-method-delete-border-color: #{$colorSupportingOrange};
  --request-method-patch-text-color: #{$colorWpPrimaryLightBlue};
  --request-method-patch-border-color: #{$colorWpPrimaryLightBlue};

  // table colors
  --table-border-color: #{$colorSupportingGray};
  --table-row-bg-color: unset;
  --table-row-text-color: #{$colorWpPrimaryBodyTextColor};
  --table-head-text-color: #{$colorWpPrimaryHeadTextColor};
  --table-head-bg-color: #{$colorSupportingGray87};
  --brand-table-border-color: #{$colorPrimaryBorderColorLight};
  --brand-table-text-color: #{$colorSecondaryBodyColorLight};

  // accordian convergence
  --accordion-convergence-border-botton: #{$colorSupportingGray};
  --accordion-convergence-icon-background-color: #{$colorWpPrimaryLightBlue};
  --accordion-convergence-icon-svg-color: #{$colorSupportingWhite};
  --accordion-convergence-marker-color: #{$colorWpPrimaryLightBlue};

  --accordion-branded-version-color: #{$colorPrimaryBrandAccentLight};
  --accordion-branded-date-color: #{$colorQuadBodyColorLight};
  --accordion-branded-release-heading-color: #{$colorPrimaryBodyColorLight};
  --accordion-branded-release-points-text-color: #{$colorSecondaryBodyColorLight};
  --accordion-branded-release-expand-icon-color: #{$colorBrandNeutralColor};

  // glossary page color
  --glossary-page-item-hover-color: #{$colorWpPrimaryLightBlue};
  --glossary-title-start-letter-color: #{$colorSupportingWhite};
  --glossary-title-start-letter-bg-color: #{$colorWpPrimaryLightBlue};

  //dashboard
  --dashboard-border-color: #{$colorSupportingGray};
  --dashboard-background-color: #{$colorSupportingWhite};

  // go live
  --go-live-icon-circle: #{$colorSupportingLightGray};
  --go-live-workflow-border-left: #{$colorSupportingGray};
  --go-live-feature-selection-border: #{$colorSupportingGray};
  --go-live-workflow-card-info-icon-color: #{$colorWpPrimaryLightBlue};
  --go-live-head-text-color: #{$colorWpPrimaryHeadTextColor};

  // $workflow-card
  --workflow-selection-checkbox-subtext-color: #{$colorSupportingDarkGrey};
  --workflow-task-border-color: #{$colorSupportingLightGray};

  --background-Shade-Primary-border: #{$colorSupportingGray};

  --testcase-status-table-text: #{$colorWpPrimaryBodyTextColor};
  --testcase-status-table-text-url-cell: #{$colorWpPrimaryLightBlue};

  --checkbox-blue-border-color: #{$colorWpAccentDarkBlue};
  --checkbox-blue-defaulted-border-color: #{$colorCheckBoxUncheckedDefault};
  --checkbox-blue-subCheckbox-border-color: #{$colorCheckBoxUncheckedDefault};
  --restful-checkbox-collection-head-text-color: #{$colorWpPrimaryHeadTextColor};
  --checkbox-blue-background-color: #{$colorWpAccentDarkBlue};
  --checkbox-grey-border-color: #{$colorCheckBoxUncheckedDefault};
  --checkbox-hover-bg-color: #{$colorDeepBlue};
  --checkbox-hover-border-color: #{$colorSupportingNewCaptionColor};
  --checkbox-focus-bg-color: #{$colorSupportingWhite};
  --checkbox-focus-border-color: #{$colorCheckBoxUncheckedDefault};
  --checkbox-focus-shadow-color-one: #{$colorBrandedBgDarkColor};
  --checkbox-focus-shadow-color-two: #{$colorSupportingWhite};
  --checkbox-checked-border-color: #{$colorWpPrimaryLightBlue};
  --checkbox-checked-hover-bg-color: #{$colorDeepBlue900};
  --checked-tick-color: #{$colorSupportingWhite};
  --checkbox-checked-focus-shadow-color: #{$colorBrandedBgDarkColor};
  --checkbox-checked-focus-shadow-color-one: #{$colorSupportingWhite};
  --checkbox-checked-focus-bg-color-one: #{$colorPrimaryBrandAccentLight};

  --section-collapsable-smallTitle-color: #{$colorWpPrimaryHeadTextColor};

  //modal
  --modal-opacityBlock-background-color: #{$colorSupportingGray};
  --modal-container-background-color: #{$colorSupportingLightGray};

  //account
  --button-spinner-color: #{$colorSupportingWhite};
  --button-spinner-hover-color: #{$colorSupportingBlack};

  // tool tip color
  --tool-tip-bg-color: #{$colorSupportingWhite};
  --tool-tip-border-color: #{$colorWpPrimaryLightBlue};

  // public
  --public-border-line-color: #{$colorSupportingGray};

  &[data-theme='dark'] {
    icon.-colorPrimaryGreen svg > * {
      fill: #{$colorPrimaryBrandAccentDark};
    }

    --icon-main-color: #{$colorPrimaryBodyColorDark};
    --icon-secondary-color: #{$colorTertiaryBodyColorDark};
    --icon-bg-color: transparent;

    // loader color
    --loader-color: #{$colorPrimaryBrandAccentDark};

    // body text color
    --body-text-color: #{$colorWpPrimaryBodyTextDarkColor};
    --heading-color: #{$colorWpPrimaryDarkHeadTextColor};
    --heading-color-branded: #{$colorPrimaryBodyColorDark};

    // icon color
    --icon-clor: #{$colorPrimaryBrandAccentDark};
    --modal-icon-clor: #{$colorSupportingLightGray30};

    // link-color
    --link-color: #{$colorPrimaryBrandAccentDark};
    --link-hover-color: #{$colorPrimaryBrandAccentDarkShade};
    --link-focus-text-color: #{$colorLinkFocus};
    --link-focus-color: #{$colorSupportingWhite};

    // link-color
    --login-link-color: #{$colorSupportingWhite};

    // link icon colors
    --link-icon-text-color: #{$colorPrimaryBodyColorDark};
    --link-icon-text-hover-color: #{$colorPrimaryBrandAccentDark};
    --link-icon-color: #{$colorPrimaryBrandAccentDark};
    --link-icon-focus-color: #{$colorSupportingWhite};

    // colors for button primary
    --button-primary-background-color: #{$colorPrimaryBrandAccentDark};
    --button-primary-background-hover-color: #{$colorPrimaryBrandAccentDarkShade};
    --button-primary-border-color: #{$colorAccentLightBlueDeSaturated};
    --button-primary-text-color: #{$colorSecondaryBgColorDark2};
    --button-primary-text-hover-color: #{$colorSupportingWhite};
    --button-primary-focus-border-color: #{$colorBrandedBgDarkColor};
    --button-primary-focus-border-color-two: #{$colorSupportingWhite};
    --button-disabled-background-color: #{$colorDisabled800};
    --button-disabled-text-color: #{$colorDisabledColor};

    // colors for button secondary
    --button-secondary-background-color: #{$colorSecondaryBgColorDark2};
    --button-secondary-background-hover-color: #{$colorBackgroundSecondaryButton};
    --button-secondary-border-color: #{$colorPrimaryBrandAccentDark};
    --button-secondary-text-color: #{$colorPrimaryBrandAccentDark};
    --button-secondary-text-hover-color: #{$colorSupportingWhite};
    --button-secondary-focus-border-color: #{$colorSupportingWhite};
    --button-secondary-focus-background-color: #{$colorBackgroundSecondaryFocusButton};
    --button-secondary-focus-shadow-color-one: #{$colorBrandedBgDarkColor};
    --button-secondary-focus-shadow-color-two: #{$colorSupportingWhite};

    // colors for tertiary primary
  --button-tertiary-background-color: #{$colorSecondaryBgColorDark2};
  --button-tertiary-background-hover-color: #{$colorSecondaryBgColorDark};
  --button-tertiary-border-color: #{$containerBorderTertiary};
  --button-tertiary-text-color: #{$colorSecondaryBodyColorDark};
  --button-tertiary-text-hover-color: #{$colorSecondaryBodyColorDark};
  --button-tertiary-focus-border-color: #{$colorSupportingGreyBorder};
  --button-tertiary-disabled-border-color: #{$colorDisabled800};
  --button-tertiary-focus-shadow-color-one: #{$colorBrandedBgDarkColor};
  --button-tertiary-focus-shadow-color-two: #{$colorSupportingWhite};
  --button-tertiary-focus-bg-color: #{$colorDeepBlueDp02};
  --button-tertiary-disabled-bg-color: transparent;
  --button-tertiary-disabled-text-color: #{$colorDisabledColor};
  --button-tertiary-hover-border-color: #{$containerBorderTertiary};

    // body background color dark
    --body-background-color: #{$colorSecondaryBgColorDark2};
    --body-background-color-brnaded: #{$colorSecondaryBgColorDark2};

    // header color
    --header-bottom-border-color: #{$colorSupportinDarkgGray};
    --header-icon-colors: #{$colorSupportingWhite};
    --header-background-color: #{$colorWpAccentDarkDeepBlue};

    // functional nav color dark
    --functional-nav-menu-item-color: #{$colorPrimaryBrandAccentDark};
    --functional-nav-menu-item-hover-color: #{$colorSupportingWhite};
    --functional-nav-login-item-color-hover: #{$colorLightBlueS30};

    // main nav color
    --main-nav-menu-item-color: #{$colorSupportingWhite};
    --main-nav-menu-item-color-hover: #{$colorSupportingWhite};
    --main-nav-menu-item-color-active: #{$colorSupportingWhite};
    --main-nav-menu-item-color-visited: #{$colorSupportingWhite};
    --main-nav-menu-item-menu-pulldown-color: #{$colorSupportingWhite};

    // header mega dropdown colors
    --header-mega-dropdown-border-color: 'none';
    --header-mega-dropdown-background-color: #{$colorWpAccentDarkDeepBlueS50};
    --header-mega-dropdown-right-pane-left-border-color: #{$colorSupportinDarkgGray};
    --header-mega-dropdown-left-pane-heading-color: #{$colorWpPrimaryDarkHeadTextColor};

    // account pull down colors
    --account-pull-down-background-color: #{$colorWpAccentDarkDeepBlueS50};

    // tab container colors
    --tab-container-text-color: #{$colorDropDownIcon};
    --tab-container-active-text-color: #{$colorPrimaryBodyColorDark};
    --tab-container-active-text-weight: 700;
    --tab-container-active-underline-color: #{$colorAccentLightBlue};
    --tab-container-head-active-text-color-updated: #{$colorPrimaryBodyColorDark};
    --tab-container-head-border-bottom-color-updated: #{$colorDeepBlue06Dp};
    --tab-container-head-active-color-updated: #{$colorBrandDeepBlue800};

    // tab items colors
    --tab-item-heading-color: var(--heading-color);
    --tab-item-link-color: var(--link-color);
    --tab-item-link-hover-color: var(--link-hover-color);

    // tab block colors
    --tab-block-active-color: var(--body-text-color);
    --tab-block-active-border-color: #{$colorPrimaryBrandAccentDark};
    --tab-block-default-border-color: #{$colorContainerBorderTertiary};

    // logo color
    --site-logo-color: #{$colorSupportingWhite};
    --logo-divider-color: #{$colorSupportingWhite};
    --site-logo-text-color: #{$colorSupportingWhite};

    // footer color
    --small-footer-border-top: #{$colorSupportinDarkgGray};
    --footer-separator-color: #{$colorSupportinDarkgGray};
    --footer-big-separator-color: #{$colorSupportinDarkgGray};
    --footer-social-icon-color: var(--link-color);
    --footer-social-icon-hover-color: var(--link-hover-color);
    --footer-secondary-bg-color: #{$colorWpAccentDarkDeepBlue};
    --footer-looking-for-color: var(--heading-color);
    --footer-bg-image: url('/assets/images/footer_bg_small.png');

    // masthead colors
    --masthead-title-color: #{$colorWpPrimaryDarkHeadTextColor};
    --masthead-sub-title-color: #{$colorAccentLightBlue};
    --masthead-tag-bg-color: #{$colorWpAccentDarkDeepBlue};
    --masthead-tag-border-color: #{$colorAccentLightBlue};
    --masthead-tag-text-color: #{$colorSupportingWhite};
    --masthead-old-bg-color: #{$colorWpAccentDarkDeepBlue};

    //masthead updated color
    --masthead-updated-text-color: #{$colorWpPrimaryBodyTextDarkColor};

    // carousel tile color
    --carousel-tile-navigator-bg-color: #{$colorWpAccentDarkDeepBlue};
    --carousel-tile-bg-color: #{$colorSecondaryBgColorDark};
    --carousel-tile-border-color: #{$colorSecondaryBgColorDark};
    --carousel-tile-title-color: var(--heading-color);
    --carousel-tile-text-color: var(--heading-color);
    --corousel-tile-icon-bg-color: #{$colorSecondaryBgColorDark2};
    --corousel-tile-icon-border-color: #{$colorSecondaryBgColorDark2};
    --corousel-tile-icon-color: #{$colorPrimaryBrandAccentDark};

    // article card colors
    --article-image-card-title-color: var(--heading-color);
    --article-image-card-caption-color: #{$colorSupportingWhite};
    --article-image-card-text-color: #{$colorSupportingWhite};
    --article-image-content-card-border-color: 'none';
    --article-image-card-container-color: #{$colorSupportingBlueCB};

    // pill country color
    --pill-country-bg-color: #{$colorSecondaryBgColorDark};
    --pill-country-text-color: #{$colorPrimaryBodyColorDark};
    --pill-country-border-color: #{colorSupportingGreyBorder};
    --pill-line-color: #2f2f38;

    // card product colors
    --card-product-bg-color: #{$colorSecondaryBgColorDark};
    --card-product-border-color: #{$colorSecondaryBgColorDark};
    --card-product-hover-color: #{$colorSecondaryBgColorDark};
    --card-product-icon-color: #{$colorAccentLightBlue};
    --card-product-text-color: #{$colorWpWhiteShade};
    --card-product-title-color: #{$colorWpWhiteTitleShade};
    --card-product-link-color: #{$colorWpWhiteTitleShade};
    --card-product-link-hover-color: #{$colorWpWhiteTitleShade};
    --card-product-link-hover-decoration: 'unset';

    //card product updated
    --card-product-updated-border-color: #{$colorPrimaryBorderColorDark};
    --card-product-updated-title-color: #{$colorPrimaryBodyColorDark};
    --card-product-updated-text-color: #{$colorSecondaryBodyColorDark};
    --card-product-updated-bg-color: #{$colorSecondaryBgColorDark};
    --card-product-updated-link-color: #{$colorPrimaryBrandAccentDark};
    --card-product-updated-shadow-color-one: #{$colorSupportingNewBoxShadowColor2};
    --card-product-updated-shadow-color-two: #{$colorSupportingNewBoxShadowColor2};
    --card-product-updated-shadow-color-three: #{$colorSupportingNewBoxShadowColor2};
    --card-product-updated-shadow-hover-color-one: #{$colorSupportingNewBoxShadowColor3};
    --card-product-updated-shadow-hover-color-two: #{$colorSupportingNewBoxShadowColor4};
    --card-product-updated-shadow-hover-color-three: #{$colorSupportingNewBoxShadowColor4};

    // category card
    --category-card-box-shadow-color-one: #{$colorSupportingNewBoxShadowColor};
    --category-card-box-shadow-color-two: #{$colorSupportingNewBoxShadowColor2};
    --category-card-box-bg-color: #{$colorSecondaryBgColorDark};
    --card-product-category-caption-color: #{$colorSecondaryBodyColorDark};
    --card-product-category-title-color: #{$colorPrimaryBodyColorDark};
    --card-product-category-description-color: #{$colorSecondaryBodyColorDark};
    --card-product-category-link-color: #{$colorPrimaryBrandAccentDark};

    // category product card
    --card-category-product-title-color: #{$colorPrimaryBrandAccentDark};
    --card-category-product-description-color: #{$colorSecondaryBodyColorDark};
    --card-category-product-bg-color: #{$colorPrimaryBrandAccentDarkShade10};
    --card-category-product-shadow-color-one: #{$colorSupportingNewBoxShadowColor6};
    --card-category-product-color-two: #{$colorSupportingNewBoxShadowColor4};
    --card-category-product-color-three: #{$colorSupportingGreyUpdatedDarkShade};

    //card product small
    --card-product-small-border-color: #{$colorSupportingBlueCB};
    --card-product-small-title-color: #{$colorPrimaryBodyColorDark};
    --card-product-small-text-color: #{$colorSecondaryBodyColorDark};
    --card-product-small-link-color: #{$colorPrimaryBrandAccentDark};
    --card-product-small-bg-color: #{$colorSecondaryBgColorDark};
    --card-product-small-border-top-purplecolor: #{$colorAccentLightBlue};
    --card-product-small-border-top-purple-color: #{$colorAccentLightBlue};
    --card-product-small-border-top-orange-color: #{$colorAccentLightBlue};
    --card-product-small-border-top-indigo-color: #{$colorAccentLightBlue};
    --card-product-small-border-top-blue-color: #{$colorAccentLightBlue};
    --card-product-small-shadow-color-one: #{$colorSupportingNewBoxShadowColor2};
    --card-product-small-shadow-color-two: #{$colorSupportingNewBoxShadowColor2};
    --card-product-small-shadow-color-three: #{$colorSupportingNewBoxShadowColor2};
    --card-product-small-shadow-hover-color-one: #{$colorSupportingNewBoxShadowColor3};
    --card-product-small-shadow-hover-color-two: #{$colorSupportingNewBoxShadowColor3};
    --card-product-small-shadow-hover-color-three: #{$colorSupportingNewBoxShadowColor4};

    //card support
    --card-support-bg-color: #{$colorSecondaryBgColorDark};
    --card-support-shadow-color-one: #{$colorSupportingGreyUpdatedDarkShade};
    --card-support-shadow-color-two: #{$colorSupportingNewBoxShadowColor5};
    --card-support-shadow-color-three: #{$colorSupportingNewBoxShadowColor4};
    --card-support-border-color: #{$colorSupportingNewLightBlue};
    --card-support-shadow-color: #{$colorSupportingGreyUpdated};
    --card-support-title-color: #{$colorPrimaryBodyColorDark};
    --card-support-text-color: #{$colorPrimaryBodyColorDark};
    --card-support-link-color: #{$colorWpWhiteTitleShade};

    // secondary masthead color
    --secondary-masthead-title-color: #{$colorWpPrimaryDarkHeadTextColor};
    --masthead-secondary-title-color: #{$colorPrimaryBodyColorDark};
    --masthead-secondary-detail-color: #{$colorSecondaryBodyColorDark};
    --masthead-applicable-color: #{$colorPrimaryBodyColorDark};
    --secondary-masthead-border-bottom-color: #{$colorDeepBlue06Dp};

    // page component bg color
    --page-bg-color: #{$colorWpAccentDarkDeepBlue};
    --pg-section-border-color: #{$colorSupportinDarkgGray};
    --pg-section-left-border-color: #{$colorSupportinDarkgGray};

    //lead gen
    --drop-down-font-color: #{$colorWpPrimaryBodyTextDarkColor};

    // doc index colors
    --doc-index-text-color: #{$colorWpPrimaryBodyTextDarkColor};
    --doc-index-text-hover-color: #{$colorPrimaryBrandAccentDarkShade};

    --index-background-color: #{$primaryBackgroundDarkColor};
    --index-heading-color: #{$colorPrimaryBodyColorDark};
    --index-text-color: #{$colorPrimaryBrandAccentDark};

    --contact-widget-link-color: #{$colorPrimaryBrandAccentDark};
    --contact-widget-bg-color: transparent;
    --contact-widget-border-color: #{$colorInfo50Dark};
    --contact-widget-heading-color: #{$colorPrimaryBodyColorDark};
    --contact-widget-desc-color: #{$colorSecondaryBodyColorDark};

    // sales section color
    --sales-section-light-font-color: #{$colorPrimaryBrandAccentDark};

    // card api convergence color
    --card-api-convergence-bg-color: #{$colorSupportingBlueCB};
    --card-api-convergence-title-color: #{$colorWpWhiteTitleShade};
    --card-api-convergence-border-color: #{$colorSupportingBlueCB};
    --card-api-convergence-text-color: #{$colorWpPrimaryBodyTextDarkColor};

    // related card color
    --card-related-font-color: #{$colorPrimaryBrandAccentDark};
    --card-related-title-color: #{$colorPrimaryBodyColorDark};
    --card-related-description-color: #{$colorSecondaryBodyColorDark};
    --card-related-link-color: #{$colorPrimaryBodyColorDark};
    --card-related-link-hover-color: #{$colorPrimaryBodyColorDark};
    --card-related-box-shadow-color-one: #{$colorSupportingGreyUpdatedDarkShade};
    --card-related-box-shadow-color-two: #{$colorSupportingNewBoxShadowColor5};
    --card-related-box-shadow-color-three: #{$colorSupportingGreyUpdatedDark};
    --card-related-box-shadow-hover-color-one: #{$colorSupportingGreyUpdatedDarkShade};
    --card-related-box-shadow-hover-color-two: #{$colorSupportingNewBoxShadowColor5};
    --card-related-box-shadow-hover-color-three: #{$colorSupportingGreyUpdatedDark};
    --card-related-box-border-color: #{$colorPrimaryBorderColorDark};
    --card-related-box-bg-color: #{$colorSecondaryBgColorDark2};

    // category section color
    --category-title-color: #{$colorPrimaryBodyColorDark};
    --category-bg-color: transparent;

    // category container section
    --category-container-bg-color: #{$colorSecondaryBgColorDark};

    // category product color
    --category-product-title-color: #{$colorPrimaryBrandAccentDark};
    --category-product-title-hover-color: #{$colorPrimaryBrandAccentDark};

    // api left menu color
    --api-left-menu-hover-bg-color: #{$colorsAccentLightShade05};
    --api-left-menu-hover-text-color: #{$colorPrimaryBrandAccentDarkShade};
    --api-left-menu-active-text-color: #{$colorPrimaryBrandAccentDark};
    --api-left-menu-text-color: #{colorWpSecondaryTextColor};

    // form color
    --input-background-color: #{$colorSecondaryBgColorDark2};
    --input-border-color: #{$colorContainerBorderTertiary};
    --input-disabled-border-color: #{$colorDisabledTertiary800};
    --input-disabled-color: #{$colorDisabled800};
    --input-border-hover-color: #{$colorsAccentLightShade40};
    --input-placeholder-text-color: #{$colorDropDownIcon};
    --input-border-focus-color: #{$colorsAccentLightShade60};
    --input-border-focus-shadow-color: #{$colorBorderLightGray20};
    --input-focus-color: #{$colorSupportingWhite};
    --input-text-color: #{$colorPrimaryBodyColorDark};
    --input-mandatory-color: #{$colorWarningShade600};
    --input-label-color: #{$colorSecondaryBodyColorDark};
    --login-input-focus-border-color: #{$colorBorderLightGray60};

    // card basic color
    --card-basic-border-color: #{$colorSupportinDarkgGray};

    // updated masthead gradient colors
    --updated-masthead-gradient-text-color: #{$colorPrimaryBodyColorDark};
    --updated-masthead-gradient-caption-color: #{$colorPrimaryBrandAccentDark};

    //mastedhead basic updated
    --masthead-basic-updated-bg-color: #1b1b6f4d;
    --masthead-basic-updated-title-color: #{$colorPrimaryBodyColorDark};
    --masthead-basic-updated-text-color: #{$colorSecondaryBodyColorDark};
    --masthead-basic-updated-link-color: #{$colorWpFontDark};
    --masthead-basic-bg-footer-image: url('/assets/images/platforms/rectangle-purple_dark.png');
    --masthead-basic-bg-footer-image-two: url('/assets/images/footer_bg_small.png');
    --masthead-basic-updated-after-color: #{$colorBrandedBgDarkColor};
    --masthead-basic-bg-footer-image-color: #{$colorBrandImageBgDarkColor};

    // branded footer bg color
    --branded-footer-bg-color: #1b1b6f4d;

    // login / forgot pass color
    --login-input-text-color: #{$colorWpTextColor};
    --login-input-bg-color: #{$colorWpAccentDarkDeepBlue};
    --forgot-pass-info-color: #{$colorAccentLightBlueDeSaturated};

    // list marker color
    --list-color: #{$colorPrimaryBrandAccentDark};

    // pagination color
    --paginator-border-color: #{$colorPrimaryBrandAccentDark};
    --paginator-text-color: #{$colorPrimaryBrandAccentDark};
    --paginator-active-hover-bg-color: #{$colorPrimaryBrandAccentDark};
    --paginator-active-text-color: #{$colorSecondaryBgColorDark2};

    // multi dropdown color
    --dropdown-text-color: #{$colorPrimaryBodyColorDark};
    --dropdown-pull-down-bg-color: #{$colorSecondaryBgColorDark2};
    --dropdown-pull-down-border-color: #{$colorContainerBorderTertiaryOne};
    --dropdown-pull-down-shadow-color: #{$colorSupportingNewBoxShadowColor2};
    --dropdown-checkbox-hover-bg-color: #{$colorsAccentLightShade05};
    --dropdown-checkbox-checked-border-color: #{$colorAccentLightBlue};
    --dropdown-checkbox-checked-tick-color: #{$colorAccentLightBlue};
    --dropdown-checkbox-default-color: #{$colorAccentLightBlue};
    --dropdown-bg-hover-color: #{$colorWpPrimaryLightBlue};
    --dropdown-text-hover-color: #{$colorLightBlueS30};
    --dropdown-icon-color: #{$colorPrimaryBodyColorDark};
    --dropdown-hover-color: #{$colorsAccentLightShade40};
    --dropdown-focus-color: #{$colorsAccentLightShade60};
    --dropdown-focus-shadow-color: #{$colorBorderLightGray20};

    // public
    --public-border-line-color: #{$colorSupportinDarkgGray};

    // sf modal color
    --sf-modal-bg-color: #{$colorWpAccentDarkDeepBlue};
    --sf-modal-restart-icon-color: #{$colorPrimaryBrandAccentDark};
    --sf-modal-close-icon-color: #{$colorPrimaryBrandAccentDark};
    --sf-intro-text-color: #{$colorPrimaryBrandAccentDark};

    // sf progress color
    --sf-progress-bg-color: #{$colorPrimaryBrandAccentDark};
    --sf-progress-text-color: #{$colorSecondaryBgColorDark2};
    --sf-progress-border-color: #{$colorPrimaryBrandAccentDark};
    --sf-progress-default-border-color: #{$colorPrimaryBrandAccentDark};
    --sf-progress-default-text-color: #{$colorPrimaryBrandAccentDark};
    --sf-progress-separator-color: #{$colorSupportingGray};

    --sf-answer-tile-bg-color: #{$colorSupportingBlueCB};
    --sf-answer-tile-border-color: #{$colorSupportingBlueCB};
    --sf-answer-section-border-color: #{$colorSupportinDarkgGray};
    --sf-answer-section-help-text-color: #{$colorPrimaryBrandAccentDark};

    --sf-text-color: #{$colorWpWhiteShade};
    --sf-title-color: #{$colorWpWhiteTitleShade};

    --input-radio-border-color: #{$colorDropDownIcon};
    --input-radio-bg-color: #{$colorSecondaryBgColorDark2};
    --input-radio-border-hover-color: #{$colorTertiaryBodyColorDark};
    --input-radio-selection-color: #{$colorAccentLightBlue};
    --input-radio-hover-shadow-color: #{$colorBrandedBgDarkColor};
    --input-radio-hover-shadow-color-one: #{colorSupportingWhite}
    --input-radio-focus-border-color: #{$colorDropDownIcon};
    --input-radio-focus-shadow-color: #{$colorBrandedBgDarkColor};
    --input-radio-focus-shadow-color-one: #{$colorSupportingWhite};
    --input-radio-checked-color: #{$colorPrimaryBrandAccentDark};
    --input-radio-checked-border-color: #{$colorPrimaryBrandAccentDark};
    --input-radio-checked-shadow-color: #{$colorBorderLightGray20};
    --input-radio-hover-border-color: #{$colorDropDownIcon};

    // search page colors
    --search-page-background-color: #{$colorWpAccentDarkDeepBlue};
    --search-input-carret-color: #{$colorSupportingWhite};
    --search-text-color: #{$colorSupportingWhite};
    --search-close-icon-border-bottom: #{$colorSupportinDarkgGray};
    --search-result-border-bottom-color: #{$colorSupportinDarkgGray};
    --search-close-icon-hover-color: #{$colorWpPrimaryBodyTextDarkColor};
    --search-page-highlight-color: #{$colorSupportinDarkgGray};
    --search-pager-active-color: #{$colorAccentLightBlue};
    --search-pager-navigation-icon-color: #{$colorAccentLightBlue};
    --search-pager-navigation-hover-icon-color: #{$colorLightBlueS30};
    --search-pager-hover-text-color: #{$colorLightBlueS30};
    --search-pager-page-dots-color: #{$colorAccentLightBlue};

    // table colors
    --table-border-color: #{$colorSupportinDarkgGray};
    --brand-table-border-color: #{$colorPrimaryBorderColorDark};
    --brand-table-text-color: #{$colorSecondaryBodyColorDark};

    // basecard color
    --base-card-text-color: #{$colorWpPrimaryBodyTextDarkColor};

    // left nav colors
    --left-nav-bg-color: #{$colorWpAccentDarkDeepBlue};
    --left-nav-text-color: #{$colorWpPrimaryBodyTextDarkColor};
    --left-nav-active-text-color: #{$colorPrimaryBrandAccentDark};
    --left-nav-active-text-color-level-1: #{$colorAccentLightBlue};
    --left-nav-bg-hover-color: #{$colorWpPrimaryLightBlue};
    --old-left-nav-bg-hover-color: #{$colorWpAccentDarkDeepBlueS50};
    --left-nav-border-color: #{$colorPrimaryBrandAccentDarkShade};
    --left-nav-vertical-text-color: #{colorWpPrimaryBodyTextDarkColor};
    --left-nav-vertical-text-hover-color: #{$colorWpPrimaryBodyTextDarkColor};
    --left-nav-small-screen-overlay: #{$colorWpAccentDarkDeepBlue};

    // doc header
    --doc-header-link-color: #{$colorWpPrimaryBodyTextDarkColor};
    --doc-header-link-hover-bg-color: #{$colorWpPrimaryLightBlue};
    --doc-header-link-active-color: #{$colorPrimaryBrandAccentDark};
    --doc-header-bottom-border-color: #{$colorSupportinDarkgGray};
    --doc-header-bg-color: #{$colorWpAccentDarkDeepBlue};

    // most popular
    --most-popular-title-color: #{$colorPrimaryBodyColorDark};
    --most-popular-link-color: #{$colorPrimaryBrandAccentDark};

    // reference cards colors
    --reference-cards-title-color: #{$colorPrimaryBodyColorDark};
    --reference-cards-description-color: #{$colorSecondaryBodyColorDark};
    --reference-cards-link-color: #{$colorPrimaryBrandAccentDark};
    --reference-cards-bg-color: #{$colorSecondaryBgColorDark};

    // table colors
    --table-row-bg-color: unset;
    --table-row-text-color: #{$colorWpPrimaryBodyTextDarkColor};
    --table-head-text-color: #{$colorWpPrimaryBodyTextDarkColor};
    --table-head-bg-color: unset;

    // doc page colors
    --doc-page-bg-color: #{$colorWpAccentDarkDeepBlue};
    --doc-page-content-subtitle: #{colorWpPrimaryBodyTextDarkColor};
    --doc-image-bg-color: unset;
    --prod-doc-page-section-heading-color: #{$colorPrimaryBodyColorDark};
    --prod-doc-page-section-description-color: #{$colorPrimaryBodyColorDark};
    --prod-doc-page-section-list-marker-color: #{$colorSecondaryBodyColorDark};
    --doc-page-section-link-color: #{$colorPrimaryBrandAccentDark};
    --doc-page-section-border-color: #{$colorDeepBlue06Dp};
    --doc-page-section-auth-bearer-color: #{$colorSecondaryBodyColorDark};
    --doc-page-section-note-border-color: #{$colorQuadBodyColorLight};
    

    --related-title-color: #{$colorPrimaryBodyColorDark};
    --related-link-color: #{$colorPrimaryBrandAccentDark};

    // accordian convergence
    --accordion-convergence-border-botton: #{$colorSupportinDarkgGray};
    --accordion-convergence-marker-color: #{$colorPrimaryBrandAccentDark};
    --accordion-convergence-icon-background-color: #{$colorPrimaryBrandAccentDark};
    --accordion-convergence-icon-svg-color: #{$colorSecondaryBgColorDark2};

    --accordion-branded-version-color: #{$colorPrimaryBrandAccentDark};
    --accordion-branded-date-color: #{$colorTertiaryBodyColorDark};
    --accordion-branded-release-heading-color: #{$colorPrimaryBodyColorDark};

    // base url color
    --base-url-color: #{$colorPrimaryBrandAccentDark};

    // workflow convergence colors
    --workflow-step-bg-color-expanded: #{$colorAccentLightBlue};
    --workflow-step-bg-color-default: #{$colorPrimaryBrandAccentDark};
    --workflow-step-text-color-default: #{$colorWpPrimaryBodyTextDarkColor};
    --workflow-step-separator-color: #{$colorSupportinDarkgGray};
    --workflow-step-expan-icon-color: #{$colorPrimaryBrandAccentDark};
    --workflow-task-bg-color: #{$colorWpAccentDarkDeepBlue};
    --workflow-card-content-title-color: #{colorWpPrimaryBodyTextDarkColor};
    --workflow-step-text-color-expanded: #{$colorWpFontDark};
    --workflow-task-separator-color: #{$colorSupportinDarkgGray};

    --workflow-setup-card-border-bottom-color: #{$colorDeepBlue06Dp};

    // spec page left nav color
    --spec-left-nav-search-bottom-color: #{$colorSupportingGray};
    --spec-left-nav-search-icon-color: #{$colorWpPrimaryBodyTextDarkColor};
    --spec-left-nav-search-text-color: #{$colorWpPrimaryBodyTextDarkColor};
    --spec-left-nav-text-color: #{$colorWpPrimaryBodyTextDarkColor};
    --spec-left-nav-text-hover-color: #{$colorAccentLightBlue};
    --spec-left-nav-right-icon-color: #{$colorWpPrimaryBodyTextDarkColor};
    --spec-left-nav-right-icon-hover-color: #{$colorAccentLightBlue};
    --spec-left-nav-active-color: #{$colorAccentLightBlue};
    --spec-left-nav-bg-color: #{$colorWpAccentDarkDeepBlue};
    --spec-left-nav-search-bg-color: #{$colorWpAccentDarkDeepBlueS50};

    // spec page color
    --spec-page-left-bg-color: #{$colorWpAccentDarkDeepBlue};
    --spec-page-left-separator: #{$colorSupportinDarkgGray};
    --spec-page-intro-header-color: #{$colorWpPrimaryBodyTextDarkColor};
    --spec-page-api-info-color: #{$colorWpPrimaryBodyTextDarkColor};
    --spec-page-api-email-color: #{$colorWpPrimaryBodyTextDarkColor};
    --spec-page-auth-description-color: #{$colorWpPrimaryBodyTextDarkColor};
    --spec-page-download-button-border-color: #{$colorAccentLightBlue};
    --spec-page-download-button-color: #{$colorAccentLightBlue};
    --spec-page-download-button-border-hover-color: #{$colorLightBlueS30};
    --spec-page-download-button-hover-color: #{$colorLightBlueS30};
    --spec-auth-input-placeholder-color: #{$colorWpPrimaryBodyTextDarkColor};
    --spec-auth-head-color: #{$colorWpPrimaryDarkHeadTextColor};
    --spec-page-h5-color: #{$colorWpPrimaryDarkHeadTextColor};
    --spec-page-h5-icon-color: #{$colorWpPrimaryBodyTextDarkColor};
    --spec-page-h5-text-color: #{$colorWpPrimaryBodyTextDarkColor};
    --spec-page-table-text-color: #{$colorWpPrimaryBodyTextDarkColor};
    --spec-page-table-text-td-border-bottom-color: #{$colorWpAccentDarkDeepBlueS50};
    --spec-page-accent-color: #{$colorAccentLightBlue};
    --spec-page-accordian-bg-color: #{$colorWpAccentDarkDeepBlueS50};
    --spec-page-thirdpane-bg-color: #{$colorWpAccentDarkDeepBlueS50};
    --spec-page-endpoint-trial-bg-color: #{$colorWpAccentDarkDeepBlueS50};
    --spec-page-endpoint-text-color: #{$colorWpPrimaryDarkHeadTextColor};
    --spec-page-endpoint-trial-title-color: #{$colorWpPrimaryDarkHeadTextColor};
    --spec-page-request-sample-bg-color: #{$colorWpAccentDarkDeepBlue};
    --spec-page-request-sample-code-bg-color: #{$colorWpAccentDarkDeepBlue};
    --spec-page-request-sample-type-color: #{$colorWpSecondaryBodyTextColor};
    --spec-auth-input-color: #{$colorWpPrimaryBodyTextDarkColor};
    --spec-page-success-notification-border-color: #{$colorSupportingDarkThemeGreen};
    --spec-page-success-notification-background-color: #{$colorSupportingDarkThemeDarkGreen};
    --spec-page-notification-text-color: #{$colorSupportingWhite};
    --spec-page-error-notification-border-color: #{$colorSupportingDarkThemeRedLight};
    --spec-page-error-notification-background-color: #{$colorSupportingDarkThemeRed};
    --spec-page-editor-bg-color: #{$colorWpAccentDarkDeepBlue};
    --spec-page-modal-text-color: #{$colorWpPrimaryBodyTextDarkColor};
    --spec-page-response-bg-color: #{$colorWpAccentDarkDeepBlue};
    --spec-page-modal-bg-color: #{$colorWpAccentDarkDeepBlue};
    --spec-page-modal-border-color: #{$colorWpPrimaryLightBlue};
    --spec-page-modal-text-color: #{$colorWpPrimaryBodyTextDarkColor};

    // notification color
    --notification-success-border-color: #{$colorSupportingDarkThemeGreen};
    --notification-success-background-color: #{$colorSupportingDarkThemeDarkGreen};
    --notification-error-border-color: #{$colorSupportingDarkThemeRedLight};
    --notification-error-background-color: #{$colorSupportingDarkThemeRed};
    --notification-notes-yellow-border-color: #{$colorAccentDarkThemeLightYellow};
    --notification-notes-yellow-background-color: #{$colorAccentDarkThemeYellow};
    --notification-notes-border-color: #{$colorAccentDarkThemeLightBlue};
    --notification-notes-background-color: #{$colorAccentDarkThemeBlue};
    --notification-text-color: #{$colorSupportingWhite};
    --notification-link-color: var(--link-color);

    // diagram colors
    --diagram-radio-color: #{$colorPrimaryBrandAccentDark};
    --diagram-default-text-color: #{$colorWpPrimaryBodyTextDarkColor};
    --diagram-default-title-color: #{$colorWpPrimaryDarkHeadTextColor};
    --diagram-default-bg-border-color: #{$colorSupportinDarkgGray};
    --diagram-default-bg-color: #{$colorSecondaryBgColorDark};

    // version control dropdown colors
    --version-control-bg-color: #{$colorSecondaryBgColorDark2};
    --version-control-bg-hover-color: #{$colorWpPrimaryLightBlue};
    --version-control-border-color: #{$colorContainerBorderTertiary};
    --version-control-border-hover-color: #{$colorPrimaryBrandAccentDark};
    --version-control-border-focus-color: #{$colorsAccentLightShade60};
    --version-control-focus-shadow-color: #{$colorBorderLightGray20};
    --version-control-flip-color: #{$colorWpPrimaryBodyTextDarkColor};
    --version-control-text-color: #{$colorWpPrimaryBodyTextDarkColor};

    //redoc
    --redoc-header-para-string-color: #{$colorAccentYellow};
    --redoc-head-text-color: #{$colorWpPrimaryDarkHeadTextColor};
    --redoc-text-color: #{$colorWpPrimaryBodyTextDarkColor};
    --redoc-tab-selected-color: #{$colorAccentLightPurple};
    --redoc-tab-error-color: #{$colorAccentLightOrange};
    --redoc-expand-copy-hover-color: #{$colorWpPrimaryLightBlue};

    // apimatic
    // --icon-color-apimatic: #{$colorSupportingGray};
    // --download-spec-border-color: #{$colorSupportingGray};
    // --search-icon-color: #191919;
    // --ctrlkColor: #B3B3B3;
    --response-section-bg-color: unset;
    --response-status-code-bg-color: unset;
    --response-status-code-active-color: #{$colorWpPrimaryHeadTextColor};

    --main-bg-color: unset;

    // fismodal color
    --fismodal-bg-color: #{$colorSecondaryBgColorDark};
    --fismodal-border-color: #{$colorDisabled800};
    --modal-text-color: #{$colorTertiaryBodyColorDark};
    --modal-asterix-color: #{$colorWarningShade600};
    --modal-shadow-color: #{$colorSupportingNewBoxShadowColor2};
    --modal-triangle-color: #{$colorWpAccentDarkDeepBlue};

    // login / forgot pass color
    --login-bg-color: #{$colorWpAccentDarkDeepBlue};
    --login-heading-color: #{$colorWpPrimaryBodyTextDarkColor};
    --login-input-bg-color: #{$colorWpAccentDarkDeepBlue};
    --login-input-border-color: #{$colorSupportinDarkgGray};
    --login-input-text-color: #{$colorWpPrimaryBodyTextDarkColor};
    --login-separator-color: #{$colorSupportinDarkgGray};

    //dashboard
    --dashboard-border-color: #{$colorSupportinDarkgGray};
    --dashboard-background-color: #{$colorWpAccentDarkDeepBlue};

    // convergence dropdown colors
    --convergence-dropdown-bg-color: #{$colorWpAccentDarkDeepBlue};
    --convergence-dropdown-border-color: #{$colorSupportinDarkgGray};
    --convergence-dropdown-icon-color: #{$colorWpPrimaryBodyTextDarkColor};
    --convergence-dropdown-bg-hover-color: #{$colorWpPrimaryLightBlue};

    // go live
    --go-live-workflow-card-info-icon-color: #{$colorAccentLightBlue};
    --go-live-workflow-border-left: #{$colorSupportinDarkgGray};
    --go-live-feature-selection-border: #{$colorSupportinDarkgGray};
    --go-live-head-text-color: #{$colorWpPrimaryDarkHeadTextColor};

    // go live test case table
    --test-case-status-check-color: #{$colorAccentLightBlue};
    --test-case-status-icon-color: #{$colorAccentLightBlue};

    // tool tip color
    --tool-tip-bg-color: #{$colorWpAccentDarkDeepBlue};
    --tool-tip-border-color: #{$colorSupportinDarkgGray};

    // section collapsible color
    --section-collapsible-bg-color: #{$colorWpAccentDarkDeepBlue};
    --section-collapsible-border-color: #{$colorSupportinDarkgGray};

    // $workflow-card
    --workflow-selection-checkbox-subtext-color: #{$colorWpPrimaryBodyTextDarkColor};
    --workflow-task-border-color: #{$colorSupportinDarkgGray};

    --checkbox-blue-border-color: #{$colorAccentLightBlue};
    --checkbox-blue-defaulted-border-color: #{$colorAccentLightBlue};
    --checkbox-blue-subCheckbox-border-color: #{$colorAccentLightBlue};
    --restful-checkbox-collection-head-text-color: #{colorWpPrimaryDarkHeadTextColor};
    --checkbox-hover-bg-color: #{$colorSecondaryBgColorDark};
    --checkbox-hover-border-color: #{$colorTertiaryBodyColorDark};
    --checkbox-focus-bg-color: #{$colorSecondaryBgColorDark2};
    --checkbox-focus-border-color: #{$colorDropDownIcon};
    --checkbox-focus-shadow-color-one: #{$colorBrandedBgDarkColor};
    --checkbox-focus-shadow-color-two: #{$colorSupportingWhite};
    --checkbox-checked-border-color: #{$colorPrimaryBrandAccentDark};
    --checked-tick-color: #{$colorSecondaryBgColorDark2};
    --checkbox-checked-hover-bg-color: #{$colorPrimaryBrandAccentDarkShade};
    --checkbox-checked-focus-shadow-color: #{$colorBrandedBgDarkColor};
    --checkbox-checked-focus-shadow-color-one: #{$colorSupportingWhite};
    --checkbox-checked-focus-bg-color-one: #{$colorPrimaryBrandAccentDarkShade};

    // chart colors
    --analytics-chart-border-color: #{$colorSupportinDarkgGray};
    --analytics-chart-legend-bg-color-odd: #{$colorWpAccentDarkDeepBlue};
    --analytics-chart-legend-bg-color-even: #{$colorWpAccentDarkDeepBlue};

    // credentials color
    --copy-icon-color: #{$colorAccentLightBlue};

    // api overview colors
    --api-overview-api-text-color: #{$colorWpPrimaryBodyTextDarkColor};

    //account
    --button-spinner-color: #{$colorSupportingBlack};
    --button-spinner-hover-color: #{$colorSupportingWhite};

    // multi filter button
    --filter-button-border-color: #{$colorSupportinDarkgGray};

    // glossary page color
    --glossary-title-start-letter-color: #{$colorWpFontDark};
    --glossary-title-start-letter-bg-color: #{$colorAccentLightBlue};
  }

  // &.light {
  //   // body background color dark
  //   --body-background-color: #{$colorSupportingWhite};
  // }
}
@import 'pages/pendo_custom';
@import 'pages/apimatic_custom.scss';
