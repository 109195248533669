solution-finder {
  display: flex;
  .section-border {
    padding-bottom: spacer(42);
    border-bottom: 1px solid var(--sf-answer-section-border-color);
  }
  .solution-finder {
    flex: 1;
    display: flex;
    flex-direction: column;
    .masthead-section {
      display: flex;
      padding-bottom: spacerThree(5);
      .masthead-block {
        width: 71%;
        margin-right: 2%;
      }
      .sales-div {
        width: 27%;
        padding-top: spacer(3);
        .-light-font {
          color: var(--sf-answer-section-help-text-color);
        }
        .-sales-text {
          padding-top: spacer(3);
          margin-bottom: 0;
        }
      }
      wpbutton {
        margin-top: spacerThree(10);
      }
    }
    .get-started-section {
      margin-top: spacerThree(11);
      padding-bottom: spacerThree(7);
      h2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
        margin: unset;
        padding-bottom: spacerThree(7);
      }
      card-product {
        h3 {
          line-height: 24px;
          font-size: 18px;
        }
      }
    }
    .results-section {
      h2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
        margin: unset;
        padding-bottom: spacerThree(7);
      }
    }
    .-description {
      margin-top: spacer(5);
      max-width: 47%;
      padding-bottom: spacerThree(11);
    }
    .-back-button {
      display: flex;
      width: max-content;
      cursor: pointer;
      margin-bottom: spacer(5);
      link-icon.left-trans {
        &:hover {
          a > icon {
            margin-left: -6px;
          }
        }
        icon {
          transition: margin-left 250ms;
          text-align: initial;
          margin: auto 0;
          transform: rotate(0deg) scaleX(-1);
        }
      }

      span {
        margin-left: spacer(2);
      }
    }
  }
  @media (max-width: $screen-xs-max) {
    .solution-finder {
      .masthead-section {
        flex-direction: column;
        .masthead-block {
          width: 100%;
        }
        .sales-div {
          width: 100%;
        }
      }
      .-description {
        max-width: 100%;
      }
    }
  }
}
