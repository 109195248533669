testimonial {
  .testimonial {
    &.-container {
      height: 740px;
      position: relative;
      .-testimonial-items {
        testimonial-item {
          height: 100%;
          width: 100%;
          position: absolute;
          visibility: hidden;
          opacity: 0;
          transition: opacity 0.6s, visibility 0.6s;
          color: var(--testimonial-text-color);
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: spacerThree(3);
          }
          .-captionTextPosition {
            position: absolute;
            padding: 0 spacer(84) 0 spacer(8);
          }
          h3 {
            @extend .-captionTextPosition;
            bottom: spacer(239);
            font-size: 45px;
            line-height: 53.5px;
            color: var(--testimonial-text-color) !important;
          }
          p {
            @extend .-captionTextPosition;
            bottom: spacer(174);
            font-size: spacer(5);
            font-weight: 700;
          }
        }
        .-no-controls {
          h3 {
            bottom: spacer(144);
          }
          p {
            bottom: spacer(66);
          }
        }
        .-visible {
          visibility: visible;
          opacity: 1;
        }
      }

      sliding-button {
        position: absolute;
        bottom: spacer(96);
        left: spacer(72);
      }
    }
  }
}
@media (max-width: $breakpoint-big-mobile) {
  testimonial {
    .testimonial {
      &.-container {
        height: 330px;
        .-testimonial-items {
          testimonial-item {
            .-captionTextPosition {
              position: absolute;
              padding: 0 spacer(2) 0 spacer(2);
            }
            h3 {
              font-size: 18px;
              line-height: 22.6px;
              font-weight: 400;
            }
            p {
              font-size: 18px;
              font-weight: 700;
              line-height: 30px;
            }
          }
          .-no-controls {
            h3 {
              bottom: 100px;
            }
            p {
              bottom: 40px;
              margin: unset;
            }
          }
        }
      }
    }
  }
}
@media (min-width: $screen-mobile-large) and (max-width: $screen-mobile-large-max) {
  testimonial {
    .testimonial {
      &.-container {
        height: 260px;
        .-testimonial-items {
          testimonial-item {
            .-captionTextPosition {
              position: absolute;
              padding: 0 spacer(54) 0 spacer(54);
            }
            h3 {
              font-size: 18px;
              line-height: 22.6px;
              font-weight: 400;
            }
            p {
              font-size: 18px;
              font-weight: 700;
              line-height: 30px;
            }
          }
          .-no-controls {
            h3 {
              bottom: 79px;
            }
            p {
              bottom: 22px;
            }
          }
        }
      }
    }
  }
}
