cardBasic {
  // border-bottom: $borderWidth solid $colorAccentDarkTeal;
  height: 100%;
  // margin: 0 spacer(5) spacer(5) 0;
  padding-bottom: spacer(5);

  h1 {
    font-weight: 900;
    margin-bottom: 20px;
    color: var(--card-basic-title-color);
  }

  h4 {
    margin-top: spacer(2);
    margin-bottom: spacer(3);
    color: var(--card-basic-title-color);
  }

  .fade-animation {
    animation-iteration-count: 1;
    animation: fade 200ms;
    animation-direction: alternate;
  }

  &.-border {
    border: $borderWidth solid var(--card-basic-border-color);
    border-radius: spacer(3);
    padding: spacer(7);
  }

  &.-border-background {
    border: 1px solid #025670;
    border-radius: spacer(3);
    background-color: $colorPrimaryBlack;
    padding: spacer(5);
    max-width: 25rem;
    height: 11.5rem;
    margin-right: 27px;
  }
}
