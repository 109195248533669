restful-checkbox-collection {
  .restful-checkbox-collection {
    &.-heading-checkbox {
      padding-top: spacer(2);
      color: var(--restful-checkbox-collection-head-text-color);
    }

    &.-sub-checkbox {
      padding-left: spacer(3);
      margin-left: spacer(5);
      margin-top: -(spacer(3));
    }
  }
}
