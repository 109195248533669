app-merchant-boarding-non-pos {
  .hosted-api-docs {
    position: relative;
    min-width: 320px;
    height: calc(100vh - 70px);
  }

  .portal-header {
    width: 100%;
    min-width: 320px;
    height: 70px;
    // background: #fff url('') no-repeat no-repeat right;
    position: relative;
    z-index: 999;
  }

  .branding-container {
    width: 100%;
    height: 70px;
    padding: 0 30px;
    box-sizing: border-box;
  }

  .branding-container .logo {
    width: 60%;
    height: 70px;
    float: left;
    margin: 0 auto;
  }

  .branding-container .logo img {
    height: 40px;
    float: left;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    max-width: 40%;
  }

  .branding-container .logo p {
    height: 40px;
    line-height: 40px;
    float: left;
    color: #2e323c;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 55%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }

  .branding-container .navigation {
    width: 40%;
    height: 70px;
    float: left;
    position: relative;
    margin: 0 !important;
  }

  .branding-container .navigation .center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    height: 30px;
  }

  .branding-container .navigation .center .version-select {
    float: right;
    height: 30px;
    line-height: 30px;
  }

  .branding-container .navigation .center .version-select label {
    font-size: 12px;
    color: #7c8395;
    margin-right: 5px;
  }

  .branding-container .navigation .center .version-select select {
    width: 100px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: 90%;
    border: 1px solid #d9dee5;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 1px 4px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 0px;
    display: inline-block;
    margin-top: 3px;
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    box-sizing: border-box !important;
  }

  .branding-container .navigation .center .version-select select:hover,
  .portal-header .navigation .version-select select:active,
  .portal-header .navigation .version-select select:focus {
    outline: none;
    box-shadow: none;
  }

  .portal {
    outline: none;
    border: none;
    width: 100%;
  }

  .btojUs {
    background: #282d44;
  }
}
