masthead-basic {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;

  .-breadcrumb {
    display: inline-block;
    border-radius: spacer(4);
    padding: spacerThree(1) spacerThree(5);
    background: #123e48;
    border: 1.5px solid #3bcff0;
    font-family: Source Sans Pro;
    font-size: spacerThree(5);
    font-style: normal;
    font-weight: 600;
    line-height: spacerThree(7);
    letter-spacing: 0px;
    text-align: center;
    margin-bottom: spacer(5);
  }

  img {
    width: 100%;
  }

  h1 {
    margin: 0 0 spacerThree(12);
    text-transform: none;
    font-weight: 200;
  }

  p {
    margin-bottom: spacerThree(9);
    font-size: 1.125rem;
    line-height: 1.875rem;
  }

  link-icon {
    font-weight: 600;
  }
  @media (min-width: ($breakpoint-desktop+1)) {
    .text-container {
      width: 50% !important;
    }
    .img-container {
      overflow: hidden;
      width: 35% !important;
    }
  }
  @media (min-width: $screen-md) and (max-width: $breakpoint-desktop) {
    .text-container {
      width: 55% !important;
    }
    .img-container {
      overflow: hidden;
      width: 35% !important;
    }
  }
  @media (max-width: $breakpoint-sm-mobile-max) {
    .text-container h1.-fontDisplayMedium {
      font-size: 32px;
      font-weight: 400;
      line-height: 120%;
    }
  }
  @media (min-width: $screen-sm) {
    margin: initial;
    flex-direction: row-reverse;
    justify-content: flex-end;

    .img-container {
      z-index: 2;
      width: 40%;
    }

    .text-container {
      width: 60%;
      align-self: center;
      h1 {
        font-size: 2rem;
        line-height: 40px;
        font-weight: 700;
        padding-right: spacer(72);
        color: var(--masthead-title-color);
      }
      p {
        padding-right: spacer(102);
      }
    }
  }
  @media (max-width: $screen-sm) {
    .text-container,
    .img-container {
      width: 100% !important;
    }
  }
  @media (max-width: $breakpoint-medium-mobile-max) {
    .img-container {
      overflow: hidden;
      top: 0 !important;
    }
  }
}

@media (max-width: $screen-xs-max) {
  masthead-basic {
    h1 {
      font-size: 24px;
      &.-fontDisplayMedium {
        font-size: 24px;
      }
    }
    .text-container,
    .img-container {
      padding: 0 spacer(5);
    }
  }
}
