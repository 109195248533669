app-back-to-top {
  .back-to-top {
    position: fixed !important;
    z-index: $layer-back-to-top;
    right: $dimension-padding !important;
    bottom: $dimension-padding !important;
    // right: 0;
    min-width: 0;
    font-size: 40px;
    padding: spacer(1);
    z-index: 99;

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 0.4em;
      height: 0.4em;
      transform: translate(-50%, -50%);
      // background-image: url('#{$path-icons}/chevron-up-white.svg');
      // @include pseudo-icon();
    }

    icon {
      &.-round {
        border-radius: spacer(7) !important;

        svg {
          height: calc(#{spacer(4)} * 0.8) !important;
          margin: calc(#{spacer(5)} * 0.4) 0 0 calc(#{spacer(5)} * 0.4) !important;
          position: absolute !important;
          width: calc(#{spacer(5)} * 0.8) !important;
          right: 8px;
        }
      }
    }
  }
}