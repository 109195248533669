card-product-updated {
  height: 100%;
  color: var(--card-product-text-color);
  border: 1px solid var(--card-product-updated-border-color);
  background-color: var(--card-product-updated-bg-color);
  border-radius: spacer(3);
  padding: 32px;
  transition: 0.2s;
  box-shadow: 0px 4px 5px 0px var(--card-product-updated-shadow-color-one),
    0px 1px 3px 0px var(--card-product-updated-shadow-color-two),
    0px 0px 3px 0px var(--card-product-updated-shadow-color-three);

  a {
    &:hover {
      text-decoration: none;
    }
    &:focus {
      text-decoration: none;
    }
  }

  .card-product {
    icon {
      display: inline-block;
      position: relative;
      height: 36px;
      width: 36px;
    }

    &.-title {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
    }
    h3 {
      font-weight: 600;
      margin: 0 0 0 spacer(3);
      flex: 1;
      color: var(--card-product-updated-title-color) !important;
      font-size: 20px;
      line-height: 30px;
    }
  }
  p {
    margin-bottom: 24px;
    line-height: 22px;
    font-weight: 400;
    color: var(--card-product-updated-text-color);
    overflow: hidden;
  }
  .grid {
    margin: 0;
    padding: 0;
    justify-content: space-between;

    icon {
      margin-top: spacer(1);
      margin-right: spacer(2);
    }

    gridColumn {
      margin-top: spacerThree(5);
      flex-direction: initial;
      width: 50%;
      flex-grow: unset;
      padding: 0;

      &.-align {
        width: auto;
      }

      app-link {
        a {
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          color: var(--card-product-updated-link-color);
          &:hover {
            color: var(--link-hover-color);
            text-decoration: var(--link-hover-color);
          }
        }
      }

      link-icon {
        a {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }
      }
    }
  }
  wpbutton {
    margin-top: spacer(42);
  }
  &:hover {
    box-shadow: 0px 8px 20px 0px var(--card-product-updated-shadow-hover-color-one),
      0px 4px 12px 0px var(--card-product-updated-shadow-hover-color-two),
      0px 1px 0px 0px var(--card-product-updated-shadow-hover-color-three);
    transform: scale(1.01);
    cursor: pointer;
  }

  @media (max-width: ($screen-sm - 1px)) {
    padding: 24px;
    .card-product {
      flex-direction: column;
      align-items: flex-start !important;
      gap: 16px;
      margin-bottom: 8px;
      h3 {
        margin: 0;
      }
    }
    p {
      margin-bottom: 8px;
    }
    .grid {
      gridColumn {
        margin: 0 !important;
      }
    }
  }

  @media (min-width: $screen-sm) and (max-width: ($screen-lg-desktop - 1px)) {
    padding: 24px;
    .card-product {
      flex-direction: column;
      align-items: flex-start !important;
      gap: 16px;
      margin-bottom: 8px !important;
      h3 {
        margin: 0;
      }
    }
    p {
      margin-bottom: 8px;
      height: unset;
    }
    .grid {
      gridColumn {
        margin: 0 !important;
      }
    }
  }

  @media (min-width: $screen-lg-desktop) {
    .card-product {
      display: flex;
      flex-direction: column;
      &.-title {
        display: flex;
        align-items: unset !important;
        margin-bottom: unset !important;
      }
    }

    h3 {
      margin-left: unset !important;
      margin-bottom: 24px !important;
    }

    icon {
      margin-bottom: 15px;
    }
  }
}
