cardAPI {
  border-bottom: $borderWidth solid var(--card-api-convergence-border-color);
  height: 100%;
  // margin: 0 spacer(5);
  padding-bottom: spacer(5);
  color: var(--card-api-convergence-text-color);
  width: 100%;

  .d-md-flex {
    link-icon {
      margin-bottom: 10px;
      margin-right: 6px;
    }

    h1 {
      font-weight: 900;
    }
  }
}
