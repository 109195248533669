tooltip {
  @include colorClasses('svg > * ', 'fill');

  .wpdh-tooltip {
    &__container {
      box-sizing: border-box;
      left: 0;
      max-width: 30rem;
      padding: spacer(4) spacer(3);
      position: fixed;
      top: 0;
      z-index: 4;
    }

    &__svg {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
      stroke: var(--tool-tip-border-color);
      stroke-width: $hairline;

      path {
        fill: var(--tool-tip-bg-color);
      }
    }
  }
}
