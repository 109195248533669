workflow-task-convergence {
  .workflowTask {
    h5 {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      color: var(--workflow-task-title-color);
    }

    &.-container {
      background-color: var(--workflow-task-bg-color);
      border: $hairline solid var(--workflow-task-border-color);
      margin-bottom: spacer(4);
      padding: spacer(5) 0;
      border-radius: 10px;
    }

    &.-card {
      padding-left: spacer(30);
    }
    &.-card-api {
      padding-left: spacer(60);
    }
    &.-step {
      wpdh-icon {
        float: left;
        margin-right: spacer(3);
      }
      icon {
        svg {
          width: 24px;
          height: 24px;
          margin: 0px;
        }
      }
    }

    &.-content {
      margin-top: spacer(5);
      padding: 0 spacer(30);
    }
    &.-description {
      padding-top: spacer(2);
      font-size: 14px;
      font-weight: 400;
      line-height: spacer(4);
      a {
        color: $colorPrimaryGreen;

        icon {
          margin-left: spacer(2);
        }

        &:hover {
          border-bottom: 1px solid $colorPrimaryGreen;
        }
      }
    }

    &.-links {
      h5 {
        padding-bottom: spacer(3);
        line-height: 20px;
      }
      font-size: 16px;
      line-height: spacer(5);
      font-weight: 700;
      border-top: $hairline solid $colorPrimaryGreen;
      margin: 0 spacer(6);
      padding: 0;
    }
    &.-links-hide {
      display: none;
    }
  }
}

@media (min-width: $screen-tablet) {
  workflow-task-convergence {
    .workflowTask {
      &.-step {
        min-width: 7rem;

        icon {
          margin-right: spacer(4);
        }
      }

      &.-content {
        margin-top: 0;
        width: spacer(432);
      }

      &.-links {
        border-left: $hairline solid var(--workflow-task-separator-color);
        border-top: 0;
        margin: 0;
        width: 17rem;
        padding: 0 spacer(6);
      }
      &.-links-hide {
        display: none;
      }
    }
  }
}
@media (max-width: $screen-mobile-large-max) {
  workflow-task-convergence {
    .workflowTask {
      &.-container {
        padding: spacer(3);
      }
      &.-card-api {
        padding-left: unset;
      }
      &.-links {
        border-top: unset;
        margin: unset;
        h5 {
          padding-bottom: unset;
        }
      }
      &.-content {
        padding: unset;
      }
    }
  }
}
@media (min-width: $screen-mobile-large) and (max-width: $screen-mobile-large-max) {
  workflow-task-convergence {
    .workflowTask {
      &.-links {
        width: 100% !important;
        display: flex;
        flex-wrap: wrap;
        h5 {
          width: 100%;
        }
        link-icon {
          margin-right: spacer(30);
          &:last-child {
            margin-right: unset;
          }
        }
      }
      &.-content {
        margin-top: 16px;
      }
      &.-description {
        padding-top: unset;
      }
    }
  }
}
@media (max-width: $screen-mobile-small-max) {
  workflow-task-convergence {
    .workflowTask {
      &.-content {
        margin-top: spacer(3);
      }
      &.-description {
        padding-top: unset;
      }
    }
  }
}
