cardcapability {
  border-bottom: $borderWidth solid $colorAccentDarkTeal;
  height: 100%;
  padding-bottom: spacer(5);
  color: $colorSupportingWhite;

  &.-border {
    border: $borderWidth solid rgba($colorSupportingWhite, 0.2);
    border-radius: spacer(1);
    padding: spacer(5);

    &.-colorShadePrimaryBlack {
      border: $hairline solid $colorShadePrimaryBlack;
    }
  }

  link-icon {
    font-weight: 700;
  }
}

@media (max-width: $screen-sm-max) {
  cardcapability {
    link-icon {
      position: unset !important;
    }
  }
}
