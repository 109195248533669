icon-tile {
  @include colorClasses(".-parent", "background-color");
  position: relative;

  .icon-tile {
    &.-parent {
      z-index: 1;
      width: 120px;
      height: 120px;
      position: relative;
      margin-right: auto;
      margin-left: auto;
    }

    &.-wrapper {
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }
}
