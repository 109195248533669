accordionBranded {
  .accordion-convergence {
    &.-main {
      display: block;
      padding: 0;
    }

    &.-title,
    &.-subtitle {
      margin-left: spacer(6);
    }
    &.-title {
      color: var(--accordion-branded-version-color) !important;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
    &.-subtitle {
      padding-top: spacer(3);
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
    &.-title,
    &.-date {
      margin-bottom: 0;
      margin-top: $hairline;
    }

    &.-date {
      margin-left: spacer(6);
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--accordion-branded-date-color) !important;
    }

    &.-hiddenRow {
      overflow: hidden;
    }

    &.-hiddenContent {
      margin-top: spacer(3);
      margin-left: spacer(3);
      font-size: 16px;
      line-height: spacer(5);
      font-weight: 400;
      h4 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin: 0;
        color: var(--accordion-branded-release-heading-color) !important;
      }
      ul {
        padding-inline-start: 27px;
        li {
          padding-left: 0px !important;
          margin: 0px !important;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: var(--accordion-branded-release-points-text-color);
        }
        li::marker {
          color: var(--accordion-branded-release-heading-color);
        }
      }
    }

    &.-icon {
      cursor: pointer;
      width: 14px;
      height: 14px;
      top: 0px;
      svg {
        width: 14px;
        height: 14px;
      }
      svg > * {
        fill: var(--accordion-branded-release-expand-icon-color);
      }
    }
  }

  @media (min-width: $screen-md) {
    .accordion-convergence {
      &.-date {
        margin-left: 0;
        margin-right: spacer(3);
        text-align: right;
        color: $colorWpPrimaryHeadTextColor;
      }
    }
  }
  @media (max-width: ($screen-sm - 1)) {
    .accordion-convergence {
      position: relative;
      &.-title {
        margin-top: 0;
        width: auto;
        text-align: left;
      }
      &.-date {
        position: absolute;
        top: 0px;
        right: 14px;
        width: auto;
        text-align: right;
      }
    }
  }
}
