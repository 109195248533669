pagefeedback {
  h4 {
    color: $colorPrimaryBlack;
    margin-bottom: spacer(4);
  }
  icon {
    cursor: pointer;
  }

  icon:first-child {
    margin-right: spacer(1);
  }
  icon:last-child {
    margin-left: spacer(1);
  }
  .pagefeedback {
    &.-container {
      text-align: center;
    }

    &.-iconContainer {
      text-align: left;
      margin: 0 auto;
      width: spacer(8);
    }
  }
}
