.valign img {
  max-width: 180px;
}

div.form.valign,
div.col.valign {
  padding-right: 0px;
  padding-left: 0px;
  display: inline-block;
  float: none;
  vertical-align: middle;
  // background-color: $colorPrimaryBlack;
}

.valign .form.-question label input,
.valign .form.-question label textarea,
.valign .question-input {
  display: flex;
  width: 100%;
  border: 0.05rem solid #ebebeb;
  outline: 0;
  background-color: var(--login-input-bg-color);
  font-family: 'Source Sans Pro';
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 4px;
  height: 36px;
  align-items: center;
  align-self: stretch;
  border: $hairline solid var(--login-input-border-color);

  &:focus {
    box-shadow: 0px 0px 0px 2px var(--input-border-focus-shadow-color) !important;
    border: 1px solid var(--input-border-focus-color);
  }
}

.valign .form.-question label span {
  width: 100%;
  // line-height: inherit;
  margin-bottom: 8px;
}

.valign h2:first-of-type {
  margin-bottom: spacerThree(5);
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  font-size: 24px;
  color: var(--login-heading-color);
}

.valign .form.-question label {
  margin-bottom: 10px;
  font-size: 0.9rem;
  font-weight: 500;
}

.valign notification .notification.-container {
  width: 66%;
  margin: auto;
  margin-top: 15px;
}

// .valign .-fontMedium {
//   font-size: 0.9rem;
//   font-weight: 500;
// }

.valign .register-steps {
  background-image: url(/assets/images/marketing/Register_steps@2x.png);
  width: 350px;
  height: 600px;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin: unset;
}

.valign .-centered-text a {
  font-weight: 800;
}

.-left-text {
  text-align: left;
}

.grey {
  color: #efefef;
  font-size: 14px;
}

.-small-padding-top {
  padding-top: 1em;
}

.-background-black {
  background-color: $colorPrimaryBlack;
}

.-background {
  background-color: var(--login-bg-color);
}

.login h4 {
  line-height: 20px;
}

.login {
  app-link {
    a {
      color: var(--link-color);
      &:hover {
        color: var(--link-hover-color);
        text-decoration-color: var(--link-hover-color);
      }
      &:focus {
        color: var(--link-focus-text-color);
        border: 1.5px solid var(--link-focus-color);
      }
    }
  }
}
