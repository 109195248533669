section-collapsable-parent {
  .section-collapsable {
    border: $hairline solid var(--section-collapsible-border-color);
    &.-parent {
      background-color: var(--section-collapsible-bg-color);
      padding: 0 30px;
      scroll-margin: 100px;
    }

    &.-collapsableBGPrimaryBlack {
      background-color: $colorPrimaryBlack;
      position: relative;
    }

    &.-collapsableBGPrimaryBlackS40 {
      background-color: var(--dashboard-background-color);
      position: relative;
    }

    &.-smallTitle {
      font-size: 1.125rem;
      font-weight: 700;
      padding-left: 0;
      padding-top: spacer(3);
      background-color: transparent;
      color: var(--section-collapsable-smallTitle-color);
    }
  }
}

section-collapsable {
  // Scroll margin to align page this HTML element with the a header margin
  // scroll-margin: 150px;

  .section-collapsable-child {
    &.-hiddenRow {
      overflow: hidden;
      display: inline-block;
    }
    &.-collapsableIconRight {
      position: absolute;
      padding-top: spacer(4);
      top: 0;
      right: 5%;
    }
    &.-content {
      width: 100%;
    }

    &.-linkCollapse {
      padding-bottom: 30px;
      margin: spacer(3) 0 spacer(6);
    }
  }

  .row {
    margin-left: 0;
  }
}

@media (max-width: $screen-sm-max) {
  section-collapsable-parent {
    .d-flex {
      align-items: unset !important;
      flex-direction: column;

      .p-2 {
        &:last-child {
          margin-bottom: spacer(4);
        }
      }
    }

    .section-collapsable {
      &.-parent {
        padding: 0 spacer(5);
      }
    }
  }
}
