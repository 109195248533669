@import './colors';
@import 'bootstrap-variables';

$font-size: 1rem;

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 1.25rem;
  color: var(--heading-color) !important;
}
p {
  margin-bottom: 1.5rem;
}
// Alpha in the Design Component Libary
h1,
.-fontXXXLarge {
  font-size: 2.25rem;
  font-weight: 300;
  line-height: 2.8125rem;
}

// Beta in the Design Component Libary
h2,
.-fontXXLarge {
  font-size: 1.75rem;
  font-weight: 300;
  line-height: 2.1875rem;
}

// Gamma in the Design Component Libary
h3,
.-fontXLarge {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.25rem;
}

// Delta in the Design Component Libary
h4,
.-fontLarge {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.4375rem;
}

// Epsilon in the Design Component Libary
h5,
.-fontMedium {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
}

// Zeta in the Design Component Libary
label,
.-fontSmall {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
}

// Caption in the Design Component Libary
caption,
.-fontXSmall {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.125rem;
  text-align: left;
}
// Legal in the Design Component Libary
.-fontXXSmall {
  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 1.0625rem;
}

.-fontDisplayXLarge {
  font-size: 8.75rem;
  font-weight: 900;
  line-height: 6rem;
}

.-fontDisplayLarge {
  font-size: 4.75rem;
  font-weight: 900;
  line-height: 4.75rem;
}

.-fontDisplayMedium {
  font-size: 3rem;
  font-weight: 900;
  line-height: 3rem;
}

.-fontDisplaySmall {
  font-size: 2.25rem;
  font-weight: 900;
  line-height: 3.25rem;
}

.-fontDisplayXSmall {
  font-size: 2rem;
  font-weight: 900;
  line-height: 3.25rem;
}
.-fontDisplayXSmallHeading {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
}

.-fontDisplayXXSmall {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 3rem;
}

.-fontDisplay {
  font-family: 'Titillium Web', sans-serif;
  text-transform: uppercase;
}

.-fontDisplayThin {
  font-family: 'Source Sans Pro', sans-serif;
}

.-fontDisplayClan {
  font-family: 'Source Sans Pro', sans-serif;
  text-transform: uppercase;
}

.-fontDisplayClanOffcProUltra {
  font-family: 'Source Sans Pro', sans-serif;
  text-transform: uppercase;
}

.-fontDisplaySourceSan {
  font-family: 'Source Sans Pro', sans-serif;
}

.-fontDisplaySourceSanBold {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
}

.-fontDisplayClanOffcPro {
  font-family: 'Source Sans Pro', sans-serif;
}

.-thickFont {
  font-weight: 900;
}
.-light-font {
  font-weight: 400;
}

.-colorAccentLightBlue {
  color: $colorAccentLightBlue;
}

.-colorAccentPrimaryBlue {
  color: $colorWpPrimaryLightBlue;
}

.-colorAccentRed {
  color: $colorAccentRed;
}

.-base-urls {
  font-family: 'Courier', sans-serif;
  font-size: 0.875rem;
  line-height: 1rem;
  span {
    color: var(--base-url-color);
    word-break: break-all;
  }
}

.-fontFraudVideo {
  font-family: 'Source Sans Pro';
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

@media (max-width: $screen-sm-max) {
  .-fontDisplayXLarge {
    font-size: 4.75rem;
    font-weight: 900;
    line-height: 3.5rem;
    word-wrap: break-word;
  }
}
