masthead {
  // color: $colorSupportingWhite;

  .masthead {
    position: relative;

    &.-container {
      background-color: var(--masthead-old-bg-color);
      background-position: center;
      background-size: cover;
      min-height: 30rem;
      text-align: left;
      // container-fluid
      // padding-right: 15px;
      // padding-left: 15px;
      margin-right: auto;
      margin-left: auto;

      .row {
        width: 100%;
        z-index: 3;
      }

      .-fontDisplay {
        font-family: 'Source Sans Pro';
        color: var(--masthead-title-color);

        &.-colorAccentLightBlue {
          color: var(--masthead-sub-title-color);
        }
      }

      
    }

    &.-buttons-scroll-down {
      left: calc(50% - 36px);
      position: absolute;
      bottom: -2.5rem;
      z-index: 2;

      icon {
        background-color: $colorWpPrimaryLightBlue;
        svg > * {
          fill: $colorSupportingWhite;
        }

        &:hover {
          background-color: $colorWpAccentDarkBlue;
        }
      }
    }

    &.-content-container {
      // reducing this margin from 9 to 5 to accomodate static div for subscription notification
      margin: spacer(5) 0;
      z-index: 1;
    }

    &.-image {
      // height: 528px;
      position: absolute;
      right: 183px;
      // top: spacer(5);
      top: 15%;

      &.-sm {
        height: 400px;
      }
      &.-md {
        height: 528px;
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  masthead {
    .masthead {
      &.-container {
        padding-left: 6%;
        padding-right: 6%;
      }

      &.-buttons-scroll-down {
        left: calc(50% - #{spacer(6)});
      }

      &.-content-container {
        margin: spacer(7) 0 spacer(9) 0;
      }

      &.-image {
        display: none;
      }
    }
  }
}
