app-api-spec {
  iframe {
    width: 100%;
    min-height: 90%;
    border: none;
    margin: 0;
    position: relative;
    top: 70px;
  }
}

@media (max-width: $breakpoint-mid-two-mobile-max) {
  app-api-spec {
    iframe {
      top: 140px;
    }
  }
}
