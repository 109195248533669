app-resend-code {
  .-refresh {
    vertical-align: text-top;
    cursor: pointer;
    margin-top: 2px;

    &.-infinite-spin {
      animation: rotate 2s linear infinite;
      margin: auto;
    }

    &.-sm151 {
      width: 15px;
    }
  }

  a icon {
    text-align: initial;
  }

  &:hover {
    a {
      icon {
        svg > * {
          fill: var(--link-hover-color);
        }
      }
    }
  }
}
