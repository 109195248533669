nav-vertical {
  display: block;
  position: sticky;
  bottom: 0;
  overflow-y: auto;
  min-width: 222px;
  top: 120px;
  align-self: start;
  float: left;

  ul {
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;

    li {
      margin: unset;
      padding-left: unset;
      margin-bottom: spacer(2);
    }
  }

  a {
    display: block;
    cursor: pointer;
    color: var(--left-nav-vertical-text-color) !important;

    &:hover {
      color: var(left-nav-vertical-text-hover-color) !important;
      text-decoration: none;
    }
  }

  button-icon {
    user-select: none;
    // margin: auto 0;
    margin-top: $hairline;
  }

  .nav-vertical {
    &.-menu {
      &.-item {
        &.-container {
          background-color: var(--old-left-nav-bg-hover-color);
          border-radius: $borderWidth;
          padding: spacer(3) spacer(4);

          icon {
            svg > * {
              fill: var(--left-nav-text-color);
            }
          }

          &.-active {
            background-color: var(--old-left-nav-bg-hover-color);
            color: var(--left-nav-vertical-text-hover-color) !important;
          }

          &:hover {
            background-color: var(--old-left-nav-bg-hover-color) !important;
          }
        }
        &.-link {
          font-weight: 400;

          &.-active {
            font-weight: 600;
          }
        }
      }
    }

    &.-menuChild {
      &.-item {
        &.-container {
          padding: spacer(2) spacer(6);

          &.-hasIcon {
            padding-left: spacer(4);
          }

          &:first-child {
            margin-top: spacer(3);
          }

          &:last-child {
            margin-bottom: spacer(3);
          }

          button-icon {
            margin: auto 0;
            margin-right: spacer(2);
          }

          .-link-active {
            color: $colorWpPrimaryLightBlue !important;
          }
        }
        &.-link {
          margin: auto 0;

          &.-active {
            font-weight: 600;
          }
        }
      }
    }

    &.-menuGrandchild {
      &.-item {
        &.-link {
          padding: spacer(2) spacer(7);

          &.-active {
            font-weight: 600;
          }
        }
      }
    }
  }
}

.nav-vertical {
  &.-expand {
    display: none;
  }
}

@media (max-width: $screen-sm-max) {
  .nav-vertical {
    &.-expand {
      display: block;
      position: fixed;
      bottom: 15px;
      left: 15px;
      z-index: 10;
    }

    &.-slide {
      overflow: hidden;
    }

    &.-slide-nav {
      width: auto !important;
      overflow: unset !important;
    }
  }
  nav-vertical {
    // .nav-vertical {
    //   &.-content {
    //     margin-left: 0;
    //     transition: 200ms;
    //   }

    //   &.-slide-button {
    //     display: block;
    //     position: fixed;
    //     bottom: 15px;
    //     left: 15px;
    //     z-index: 10;
    //   }

    //   &.-slide {
    //     // margin-left: 2px !important;
    //     overflow: hidden;
    //   }

    //   &.-verticalNav {
    //     transition: 200ms;
    //     width: 0;
    //     overflow: hidden;
    //   }
    // }
  }
}
