button-icon {
  .button-big-icon_container {
    cursor: pointer;
    // width: spacer(7);
    display: flex;

    p {
      text-align: center;
    }

    icon {
      display: flex;
    }
  }
}


