testcase-status-table {
  icon svg {
    fill: $colorWpAccentDarkBlue !important;
  }
  $fourtyTwo: spacer(42);

  .testcase-status-table {
    &.-header {
      margin-top: spacer(42);
      margin-bottom: spacer(5);
      display: flex;
      justify-content: space-between;

      .-block {
        display: flex;

        icon {
          margin-top: 1px;
          margin-left: calc(0px - #{$fourtyTwo});
        }

        .-title {
          padding-left: spacer(4);

          &-main {
            font-weight: 700;
            margin-bottom: 0;
          }

          &-sub {
            font-weight: 400;
            color: var(--body-text-color);
          }
        }
      }

      link-icon {
        padding-right: spacer(2);
      }
    }

    &.-table {
      .-refresh {
        vertical-align: text-top;
        cursor: pointer;
        margin-top: 2px;

        &.-infinite-spin {
          animation: rotate 2s linear infinite;
        }

        svg > * {
          fill: var(--test-case-status-check-color);
        }
      }

      td {
        border-bottom: $hairline solid var(--table-border-color);
        padding-top: spacer(30);
        padding-bottom: spacer(42);
        vertical-align: top;
      }

      .-use-case {
        display: flex;

        .-verb-box {
          justify-self: left;
        }

        .-cell-text {
          font-weight: 400;
          max-width: 69%;
          padding-right: spacer(3);
        }
      }

      .-url-cell {
        @extend .-fontSmall;

        text-decoration: underline;
        color: var(--testcase-status-table-text)-url-cell;
        width: 135px;
        word-wrap: break-word;
      }

      .-response-tested-cell {
        @extend .-fontSmall;

        white-space: pre-line;
        font-family: 'Courier';
      }

      .-status-cell {
        display: flex;

        p {
          margin: 0 spacer(2);
        }
      }
    }
  }
}
