.wpdh-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}

@media (min-width: $screen-md-min) {
  .wpdh-container {
    padding-top: 0;
  }
}
