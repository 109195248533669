workflow-task {
  .workflowTask {
    &.-container {
      background-color: var(--dashboard-background-color);
      margin-bottom: spacer(3);
      padding: spacer(5) 0;
      border: 0.0625rem solid var(--workflow-task-border-color);
    }

    &.-card {
      padding: 0 spacer(6);
    }
    &.-card-api {
      padding-left: spacer(60);
    }
    &.-step {
      wpdh-icon {
        float: left;
        margin-right: spacer(3);
      }
    }

    &.-content {
      margin-top: spacer(5);
      padding: 0 spacer(30);
    }
    &.-description {
      padding-top: spacer(2);
      font-size: 14px;
      font-weight: 400;
      line-height: spacer(4);
      a {
        color: $colorPrimaryGreen;

        icon {
          margin-left: spacer(2);
        }

        &:hover {
          border-bottom: 1px solid $colorPrimaryGreen;
        }
      }
    }

    &.-links {
      border-top: $hairline solid $colorPrimaryGreen;
      margin: 0 spacer(6);
      padding: 0;
    }
  }
}

@media (min-width: $screen-md) {
  workflow-task {
    .workflowTask {
      &.-step {
        min-width: 7rem;

        icon {
          margin-right: spacer(5);
        }
      }

      &.-content {
        margin-top: 0;
        width: 27rem;
      }

      &.-links {
        border-left: $hairline solid var(--go-live-workflow-border-left);
        border-top: 0;
        margin: 0;
        width: 17rem;
        padding: 0 spacer(6);
      }
    }
  }
}
