pill {
  @include colorClasses('span', 'background-color');

  .-pill {
    border-radius: 1rem;
    color: var(--pill-text-color);
    display: inline-block;
    margin: 0 spacer(2) spacer(3) 0;
    padding: spacer(2) spacer(4);
    font-size: inherit;
    user-select: none;

    icon {
      margin-left: spacer(2);
      display: none;

      svg > * {
        fill: var(--pill-text-color);
      }
    }

    &.-canclick {
      cursor: pointer;

      icon {
        display: inline-block;
      }
    }
  }
  .-filter-pill {
    border-radius: 1rem;
    color: var(--pill-text-color);
    display: inline-block;
    padding: spacer(1) spacer(4);
    font-size: inherit;
    user-select: none;

    icon {
      display: inline-block;
      cursor: pointer;
      svg {
        margin-left: spacer(2);
        color: var(--pill-text-color);
      }
      svg > * {
        fill: var(--pill-text-color);
      }
    }
  }

  &.-colorAccentLightBlue {
    span {
      background-color: var(--pill-bg-color) !important;
    }
  }
}
