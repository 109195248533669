app-breadcrumbs {
  &.-api-spec-margin {
    margin-left: spacer(5);
  }

  .breadcrumbs {
    position: relative;

    &.-list {
      padding: 0;
      float: left;
      list-style: none !important;
      white-space: nowrap;

      li {
        position: relative;
        display: inline;
        padding-right: spacer(4);
        padding-left: 0;
        font-family: 'Source Sans Pro';
        height: 20px;

        icon {
          margin-right: spacer(2);
          position: relative;
          top: 0.4px;
        }

        &::before {
          display: none;
        }

        li:hover {
          color: $colorPrimaryGreen;
        }

        a {
          transition: color 0.2s ease-out;
          color: $colorAccentLightBlue;

          // &:after {
          //   content: "/";
          //   position: absolute;
          //   right: spacer(2);
          //   font-size: spacer(4);
          // }
        }

        a:hover {
          color: $colorPrimaryGreen;

          &:after {
            color: $colorAccentLightBlue;
          }
        }

        &:last-child {
          padding-right: 2em;
        }
      }
    }

    &.-divider {
      position: absolute;
      right: 6px;
      font-size: 18px;
      top: 0;
      color: $colorAccentLightBlue;
    }
  }

  .api-versions-ul {
    margin-top: spacer(5);
  }
}

app-api-versions {
  margin-right: 24px;
  margin-top: 6px;
}

@media (min-width: $screen-md-min) {
  .breadcrumbs {
    &__reel {
      border-bottom: solid $colorTintPrimaryBlackT60 1px;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .breadcrumbs {
    &.-content-container {
      margin-bottom: spacer(3);
    }
    &.-list {
      display: contents;
    }
  }
}

@media (max-width: 640px) {
  .breadcrumbs {
    &.-content-container {
      // display: flex;
      flex-wrap: wrap;
    }
  }
}
