apis {
  .apis {
    &.-grid-column {
      justify-content: flex-start !important;
      margin-right: 18px;
    }

    &.-row-wrapper {
      margin-left: 12px;
      // min-height: 214px;
    }

    &.-api-description {
      margin-bottom: 0;
    }

    &.-clear-filters {
      top: -54px;
      right: 30px;
      position: absolute;
      display: flex;
      cursor: pointer;
      color: $colorTintPrimaryBlackT60;

      button-icon {
        margin-right: spacer(2);
        margin-top: $hairline;
      }
    }
  }
}

@media (max-width: 1410px) and (min-width: $screen-sm-max) {
  apis {
    .apis {
      &.-clear-filters {
        position: unset;
        margin-bottom: spacer(3);
      }
    }
  }
}

@media (max-width: 680px) {
  apis {
    .apis {
      &.-clear-filters {
        position: unset;
        margin-bottom: spacer(3);
      }
    }
  }
}
