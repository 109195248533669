masthead-gradient-updated {
  display: flex;
  flex-direction: column;
  margin: initial;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  .-text-platforms {
    font-weight: 500;
    font-size: 12px;
    color: var(--updated-masthead-gradient-caption-color);
    line-height: 17px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .text-container {
    width: 50%;
    z-index: 2;
    p {
      font-weight: 400;
      font-size: 1rem;
      line-height: 22px;
      width: 642px;
      margin-bottom: 16px;
      color: var(--updated-masthead-gradient-text-color) !important;
    }
    h1 {
      margin-bottom: 10px !important;
      width: 662px;
      text-transform: none;
      color: var(--updated-masthead-gradient-text-color) !important;
      &.-fontDisplayMedium {
        font-weight: bold;
        line-height: 56px;
      }
    }

    wpbutton {
      margin: 0 !important;
    }

    link-icon {
      font-weight: 600;
    }
  }

  .img-container {
    z-index: 2;
    width: 50%;
    img {
      width: 100%;
      position: relative;
      top: 2px;
      left: 77px;
    }
  }

  .masthead-gradient-background {
    position: absolute;
  }
}

@media (max-width: ($screen-sm - 1px)) {
  masthead-gradient-updated {
    margin: unset;
    .text-container {
      width: 100%;
      h1 {
        width: 100%;
        font-size: 40px !important;
        line-height: 54px !important;
        font-weight: 600 !important;
        letter-spacing: -0.04em;
      }
      p {
        width: 100%;
        line-height: 24px;
      }
    }

    .img-container {
      display: none !important;
    }

    .-breadcrumb {
      position: absolute;
      top: -15px;
    }
  }
}

// @media (min-width: $screen-sm) and (max-width: ($screen-lg-desktop - 1px)) {
@media (min-width: $screen-sm) and (max-width: $screen-md-sm-max) {
  masthead-gradient-updated {
    margin: unset;
    position: relative;
    justify-content: flex-end;
    max-width: 864px;
    .text-container {
      width: unset;
      width: 58%;
      h1 {
        // max-width: 490px;
        // min-width: 416px;
        width: unset;
        font-weight: 600;
        line-height: 54px;
        letter-spacing: -0.04em;
        font-size: 40px;
      }
      p {
        // max-width: 490px;
        // min-width: 416px;
        width: unset;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .img-container {
      overflow: hidden;
      width: 400px;
      position: absolute;
      right: -0px;
      img {
        left: 18%;
      }
    }

    .-breadcrumb {
      position: absolute;
      top: -15px;
    }
  }
}

@media (min-width: ($screen-md-sm-max + 1)) and (max-width: ($breakpoint-ipad-landscap - 1)) {
  masthead-gradient-updated {
    margin: unset;
    position: relative;
    justify-content: flex-end;
    max-width: 864px;
    .text-container {
      width: unset;
      width: 56%;
      h1 {
        width: unset;
        font-weight: 600;
        line-height: 54px;
        letter-spacing: -0.04em;
        font-size: 40px;
      }
      p {
        width: unset;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .img-container {
      overflow: unset;
      width: 400px;
      img {
        left: 6%;
      }
    }

    .-breadcrumb {
      position: absolute;
      top: -15px;
    }
  }
}

@media (min-width: $breakpoint-ipad-landscap) and (max-width: ($screen-lg - 1)) {
  masthead-gradient-updated {
    margin: unset;
    position: relative;
    justify-content: flex-end;
    max-width: unset;
    .text-container {
      width: unset;
      width: 56%;
      h1 {
        width: unset;
        font-weight: 600;
        line-height: 54px;
        letter-spacing: -0.04em;
        font-size: 40px;
      }
      p {
        width: unset;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .img-container {
      overflow: hidden;
      width: 400px;
      position: absolute;
      right: 0px;
      img {
        left: 5%;
      }
    }
  }
}
