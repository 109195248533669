@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables';

// Paths
$path-assets: '../../assets';
$path-icons: '#{$path-assets}/icons';
$path-fonts: '#{$path-assets}/fonts';

// Colours
// primary
// $colour-red: #FF0000;
// // secondary
// $colour-dark-red:#B03D41;
// $colour-blue: #2CACE5;
// $colour-black: #000000;
// $colour-dark-blue: #1159a5;
// $colour-purple: #824CEF;
// $colour-dark-purple: #1B1B6F;
// $colour-light-purple: #4c12a1;
// $colour-grey: #E4E3DE;
// $colour-navy: #21145F;
// $colour-light-blue:#2879FF;
// $colour-grey-2d2d2d: #2d2d2d;
// $colour-grey-darkest: #393939;
// $colour-grey-darker: #8f9394;
// $colour-grey-dark: #919191;
// $colour-grey-light: #f7f7f7;
// $colour-white: #ffffff;
// $colour-grey-light-transparent: rgba($colour-grey-darkest, 0.05);
// $colour-grey-lighter: rgba(160, 160, 160, 0.2);
// // teritiary
// $colour-wine: #C80000;
// $colour-aqua: #1AE2E2;
// $colour-orange: #ee6c06;
// $colour-pink: #bd3578;
// $colour-green: #089857;
// $colour-blush: #FF92EA;
// $colour-ocean: #3EF7C7;
// $colour-red-error: #e5322a;
// $colour-near-atomic-tangerine: #FD8D62;
// $colour-golden-tainoi: #ffc845;
// $colour-near-chinese-gold: #cc9e00;
// $colour-fis-green: #4bcd3e;
// $colour-near-cultured: #f9f5f1;
// $colour-near-bone: #e8dbcb;
// $colour-near-scarlet: #ff1f3e;
// $colour-near-metallic-violet: #4c12a1;
// $colour-near-light-pastel-purple: #a18cde;
// $colour-near-cerulean-blue: #285bc5;
// $colour-near-picton-blue: #3bcff0;
// $colour-near-maastricht-blue: #012834;
// $colour-near-blue-sapphire: #015b7e;
// $colour-near-paolo-veronese-green: #009775;
// // HTTP method colors
// $colour-http-get: #884BB2;
// $colour-http-patch: #BE3478;
// $colour-http-put: #3472B2;
// $colour-http-post: #089958;
// $colour-http-delete: #F11E15;
// $colour-http-update: #F66900;
// // alert colors
// $colour-alert-success: #155724;
// $colour-alert-info: #d1ecf1;
// $colour-alert-warning: #f8d7da;
// $colour-alert-plain: #383d41;
// // Font family
// $font-family: 'Adelle Sans',
// Helvetica,
// Arial,
// sans-serif;
// Font sizes
$font-size: 16px;
$font-size-xxxl: 2.5rem; // 68px
$font-size-xxl: 2.75rem; // 40px
$font-size-xl: 1.85rem; // 24px
$font-size-lg: 1.25rem; // 20px
$font-size-md: 1.125rem; // 18px
$font-size-sm: 0.875rem; // 14px
$font-size-xs: 0.75rem; // 12px
$font-size-xxs: 0.6875rem; // 11px
// Line heights
$font-line-height: 1.5;
$font-line-height-lg: 1.875;
$font-line-height-sm: 1.2;
// Letter spacing
$font-letter-spacing: 2px;
// Dimensions
$wpdh-sidebar-width: 250px;
$wpdh-mobile-nav-height: 60px;
$dimension-padding: $grid-gutter-width * 0.5;
$dimension-padding-lg: $grid-gutter-width;
$dimension-padding-xl: 40px;
$dimension-sidebar-width: 300px;
$dimension-sidebar-content-max-width: 1200px;
$dimension-sidebar-body-max-width: 900px;
$dimension-article-content-max-width: 960px;
$dimension-nav-height: 75px;
$dimension-nav-functional-height: 60px;
$dimension-breadcrumb-height: 50px;
$dimension-social-share-max-width: 200px;
$dimension-api-sandbox-control-height: 0px;
$dimension-feature-height-desktop: 390px;
// // Paths
// $path-assets: '/assets';
// $path-icons: '#{$path-assets}/icons';
// $path-fonts: '#{$path-assets}/fonts';
// Layers
$layer-codeblock-nav: 1;
$layer-tabblock-nav: 1;
$layer-sidebar-template: -1;
$layer-header: 4;
$layer-feature: 1;
$layer-feedback-form: 3;
$layer-back-to-top: 2;
$layer-footer: 1;
$layer-search: 6;
$layer-voter-message: 3;
$layer-modal: 4;
$layer-modal-box: 5;
$layer-message:5