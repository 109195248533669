.small-image-card {
  cardBasic {
    border-bottom: none !important;
    h3 {
      font-weight: 600;
      font-size: spacer(5);
      line-height: spacer(5);
      margin-top: spacerThree(5);
      margin-bottom: spacerThree(7);
      color: $colorWpPrimaryHeadTextColor;
    }
  }
  &.-card-container {
    display: flex;
    justify-content: center;
  }
  &.-card-details {
    padding-left: spacer(4);
  }
  &.-image-container {
    display: flex;
    justify-content: center;
    bottom: 0;
  }
  &.-card-text {
    font-weight: 400;
    font-size: 16px;
    line-height: spacer(30);
    overflow: hidden;
    margin-bottom: unset;
  }
  &.-icon-animation {
    color: $colorPrimaryGreen;
    padding-top: spacerThree(5);

    .link-icon {
      &.-parent {
        &.-text {
          font-weight: 800 !important;
          font-size: $font-size;
          line-height: spacer(5);
        }
      }
    }
  }

  @media (min-width: $screen-mobile-xs) and (max-width: $screen-mobile-small-max) { //3205px-565px
    cardBasic {
      width: 100%;
    }
    &.-card-details {
      padding-left: spacer(1);
    }
    &.-image-container {
      width: 85%;
      margin: 0 auto;
    }
  }

  @media (min-width: $screen-mobile-large) and (max-width: $screen-mobile-large-max) { //576px-767px
    cardBasic {
      width: 100%;
    }
    &.-card-details {
      padding-left: spacer(1);
    }
    &.-image-container {
      width: 60%;
      margin: 0 auto;
    }
  }

  @media (min-width: $screen-tablet) and (max-width: $screen-tablet-max) { //767px-1439px
    cardBasic {
      width: 100%;
    }
    &.-card-details {
      padding-left: spacer(1);
    }
    &.-image-container {
      width: 80%;
      margin: 0 auto;
    }
  }
}
