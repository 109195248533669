.form {
  &.-question {
    label {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: spacer(5);
      margin-top: spacer(4);
      position: relative;
      color: var(--input-label-color);

      span {
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: spacer(2);

        &.-disabled-label {
          color: var(--body-text-color);
        }
      }

      input {
        @extend .question-input;

        &.-disabled {
          pointer-events: none;
          border: 1px solid var(--input-disabled-border-color);
          background-color: var(--input-disabled-color);
        }
      }

      textarea {
        @extend .question-input;
        resize: both;
        // min-height: 43px;
        overflow: hidden;
        padding: spacer(2) spacer(2) spacer(2) spacer(3) !important;
        min-height: spacer(9) !important;

        &:focus {
          box-shadow: 0px 0px 0px 2px var(--login-input-focus-border-box-color);
          border: 1px solid var (--login-input-focus-border-color);
        }
      }
    }
  }

  &.-error {
    // position: absolute;
    color: var(--input-mandatory-color);
    margin-bottom: 0 !important;
    margin-top: spacer(2) !important;
    bottom: -24px;
  }

  &.-help {
    opacity: 0.5;
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.question-input {
  width: 100%;
  border: 1px solid var(--input-border-color);
  outline: none;
  background-color: var(--input-background-color);
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  padding: 6px 12px;
  border-radius: 4px;
  height: 36px;
  color: var(--input-text-color);

  &:focus {
    border: 1px solid var(--input-border-focus-color);
    box-shadow: 0px 0px 0px 2px var(--input-border-focus-shadow-color) !important;
  }

  &.error {
    border: 1px solid var(--input-mandatory-color);
    color: var(--input-mandatory-color);
  }

  &:hover {
    border: 1px solid var(--input-border-hover-color);
  }

  &::placeholder {
    color: var(--input-placeholder-text-color);
    font-weight: normal;
  }
}

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active {
//   -webkit-box-shadow: 0 0 0 50px var(--input-background-color) inset !important;
//   -webkit-text-fill-color: var(--input-text-color);
// }

@media (max-width: $screen-sm-max) {
  .question-input {
    // width: 80%;
  }
}
