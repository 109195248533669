modal {
  icon.-refresh svg > * {
    fill: var(--button-spinner-color);
  }
  p.-colorAccentGrey {
    color: var(--modal-text-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  icon svg > * {
    fill: var(--modal-icon-clor);
  }

  .-asterix {
    color: var(--modal-asterix-color);
  }

  .fismodal {
    &.-opacityBlock {
      background-color: rgba($color: var(--modal-opacityBlock-background-color), $alpha: 0.8);
      display: block;
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 10 !important;
      justify-content: center;
      display: flex;
      align-items: center;
    }

    &.-contentContainer {
      background-color: var(--fismodal-bg-color);
      max-height: 90vh;
      max-width: 90vw;
      overflow: auto;
      padding: spacer(5);

      wpbutton {
        margin: spacer(4) spacer(2) spacer(3) 0;
      }
    }

    &.-header {
      margin-bottom: 16px;

      h3 {
        font-size: 18px !important;
        font-weight: 600 !important;
        line-height: 28px !important;
      }

      devx-icon {
        cursor: pointer;
        margin-bottom: spacer(3);
      }
    }

    &.-secondary-modal-header {
      display: block !important;
      position: relative;
      padding-top: spacer(6);
      padding-bottom: spacer(2);
    }

    &.guided-modal-header {
      padding-top: 12px;
    }

    &.-close {
      color: var(--modal-close-icon-color);
      cursor: pointer;
      display: flex !important;
      justify-content: flex-end;

      icon {
        height: 12px;
        width: 12px;

        svg {
          position: unset;
        }
      }
    }

    &.-default-padding-bottom {
      padding-bottom: 3%;
    }

    &.-secondary-padding-bottom {
      position: absolute;
      top: 0;
      right: 0;
    }

    &.-backgroundSupportingWhite {
      background-color: var(--fismodal-bg-color);
      border-radius: 5px;
      padding: spacer(5);
      max-width: 30rem !important;
      width: 96%;
    }

    &.-guided-modal-width {
      max-width: 22rem !important;
    }

    &.-secondary-modal-margin {
      margin-bottom: spacer(4);
      display: block;
      align-items: center;
    }

    &.-custom-title {
      color: $colorWpPrimaryHeadTextColor;
      font-weight: 600;
      font-size: 18px;
      font-style: normal;
      line-height: 28px;
    }

    &.-modal-pointer {
      display: block !important;
      background-color: inherit;
      position: inherit;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--modal-text-color);
      margin-bottom: 16px;
    }

    wp-form-question {
      label {
        margin-top: 0 !important;
        margin-bottom: 16px !important;
      }
    }
  }
}

@media (min-width: $screen-md) {
  modal {
    .fismodal {
      &.-contentContainer {
        border: 1px solid var(--fismodal-border-color);
        border-radius: 8px;
        max-width: 28rem;
        box-shadow: 0px 4px 5px 0px var(--modal-shadow-color), 0px 1px 3px 0px var(--modal-shadow-color),
          0px 0px 3px 0px var(--modal-shadow-color);
      }
    }
  }
}
