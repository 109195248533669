releasenotes-subscription {
  .-subscription {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: spacer(5);
    width: 100%;
  }

  .-infinite-spin {
    animation: rotate 2s linear infinite;
  }

  .-unsubscription-confirm-modal {
    text-align: center;
    .-unsubscription-confirm-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        width: 80%;
        margin-bottom: spacer(6);
        margin-top: 0 !important;
      }
    }
  }
}
