api-collection {
  .api-collection {
    .-filter-results {
      display: flex;
      flex-direction: column;
      gap: spacer(30);

      .-clear-results {
        display: flex;
        gap: spacerThree(1);
        align-items: center;
        cursor: pointer;
        button-icon {
          display: inline-block;
        }
      }

      .-results-info {
        display: flex;
        font-weight: 600;
        line-height: 20px;
        gap: spacer(3);
        min-height: 26px;
        flex-wrap: wrap;
        margin-top: spacerThree(7);
        p {
          margin: auto 0;
          display: inline-block;
        }
        .-pill {
          margin: unset;
        }
      }
      .-results {
        display: flex;
        flex-direction: column;
        .-results-container {
          display: flex;
          gap: spacerThree(5);
          flex-wrap: wrap;
          card-api-convergence {
            width: 32%;
          }
        }
      }
    }
    @media (max-width: ($screen-laptop-sm - 0.5)) {
      .-filter-results {
        .-results {
          .-results-container {
            card-api-convergence {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
