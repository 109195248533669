@import 'bootstrap-variables';
@import 'colors';
@import 'spacing';

// Currently only used for product pages ONLY
.-wide-element {
  width: 100vw;
  // 3vw matches the padding-left on product -content
  margin-left: calc(-222px - 3vw);
  padding: 0 15%;
}

.-main-padding {
  padding: 52px 0 52px spacer(7);
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $colorWpPrimaryHeadTextColor;
  }

  &.-top-only {
    // padding: 52px 18%;
    padding: 52px 0;
  }

  @extend .list;
}
.-secondary-padding {
  padding: 72px 6%;
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $colorWpPrimaryHeadTextColor;
  }
  @extend .list;
  &.-no-list-bubble {
    ul {
      li:before {
        visibility: hidden;
      }
    }
  }
}
.-section-border {
  border-bottom: 1px solid var(--pg-section-border-color);
}
.-padding-element {
  padding: 72px 0 0 0;

  &:first-of-type {
    padding-top: 20px;
  }

  h3 {
    color: var(--pg-heading-color);
  }
}

.-doc-image-bg-color {
  img {
    background-color: var(--doc-image-bg-color);
    padding: 0 spacerThree(10) spacerThree(10) spacerThree(10);
  }
}

.-centered-text {
  text-align: center;
  margin: auto;
  margin-bottom: 10px;
  width: 66%;
}

.-auto-margins {
  margin-left: auto;
  margin-right: auto;
}

.-half-text-width {
  width: 50%;
}

.-three-quarters-text-width {
  width: 75%;
}

p {
  @include color('color');
}

.-no-padding {
  padding: 0 !important;
}

// Override bootstrap
.row {
  margin-right: -15px;
  margin-left: -15px;
}

// Markdown list styling
.list > ul,
.list > ol,
ul,
ol {
  li {
    padding-left: spacer(2);
    margin: 0.5rem 0px;
    color: var(--body-text-color);
    a {
      color: var(--link-color);
      &:hover {
        color: var(--link-hover-color);
        svg > * {
          fill: var(--link-hover-color);
        }
      }
    }
  }
  li::marker {
    color: var(--list-color);
  }
}

// Markdown code highlighting
code {
  border-radius: $hairline;
  padding: 0.125rem 0.25rem;
  border: $hairline solid var(--short-code-bg-border-color);
  background-color: var(--short-code-bg-color);
  overflow-wrap: break-word;
  color: var(--short-code-text-color);
}

@media (max-width: $screen-sm-max) {
  img {
    margin: 0 auto;
    display: flex;
  }

  .valign .register-steps {
    max-width: 300px;
  }

  .-wide-element {
    width: 100vw;
    // 3vw matches the padding-left on product -content
    margin: 0 -6vw;

    .-padding-element {
      margin: 0;
    }
  }

  .-main-padding {
    padding-left: 6vw;
    padding-right: 6vw;
    padding-bottom: spacer(7);

    &:last-child {
      padding-bottom: 54px;
    }

    &.-top-only {
      padding: 52px 6%;
    }
  }
  .-iframe-small-screens {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25% !important; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: $screen-mobile-xs-max) {
    .-iframe-small-screens {
      iframe {
        width: 102%;
      }
    }
  }

  .-padding-element {
    padding: 56px 0 0 0;

    &:first-of-type {
      padding-top: 0;
    }
  }

  .-half-text-width {
    width: 100%;
  }

  .-three-quarters-text-width {
    width: 100%;
  }
}

@media (min-width: $screen-md-min) and (max-width: 1600px) {
  .-wide-element {
    padding: 52px 18% 52px 18%;
  }
}

//------------- Old variables to be removed/altered -------------
// Dimensions
$wpdh-sidebar-width: 250px;
$wpdh-mobile-nav-height: 60px;
$dimension-padding: $grid-gutter-width * 0.5;
$dimension-padding-lg: $grid-gutter-width;
$dimension-padding-xl: 40px;
$dimension-sidebar-width: 300px;
$dimension-sidebar-content-max-width: 1200px;
$dimension-sidebar-body-max-width: 900px;
$dimension-article-content-max-width: 960px;
$dimension-nav-height: 75px;
$dimension-nav-functional-height: 60px;
$dimension-breadcrumb-height: 50px;
$dimension-social-share-max-width: 200px;
$dimension-api-sandbox-control-height: 0px;
$dimension-feature-height-desktop: 390px;
// Paths
$path-assets: '/assets';
$path-icons: '#{$path-assets}/icons';
$path-fonts: '#{$path-assets}/fonts';
// Layers
$layer-codeblock-nav: 1;
$layer-tabblock-nav: 1;
$layer-sidebar-template: -1;
$layer-header: 4;
$layer-feature: 1;
$layer-feedback-form: 3;
$layer-back-to-top: 2;
$layer-footer: 1;
$layer-search: 6;
$layer-voter-message: 3;
$layer-modal: 4;
$layer-modal-box: 5;
$layer-message: 5;
