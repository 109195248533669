$stroke-brightness-percent: 25%;
$http: (
  get: $colorAccentLightPurple,
  patch: $colorAccentDeepBlue,
  put: $colorAccentLightBlue,
  post: $colorAccentTeal,
  delete: $colorAccentRed,
  update: $colorAccentLightRed,
  then: $colorAccentLightBlue,
  returns: $colorAccentDarkTeal,
);

diagram {
  .diagram {
    &.-parent {
      display: flex;
      margin-top: 2rem;
      background: $colorShadePrimaryBlackS50;
      overflow: hidden;
      min-height: 37.5rem;
      // max-width: $dimension-sidebar-body-max-width;
      max-width: 1200px;
      border-radius: 0.625rem;
    }

    &.-sidebar {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      width: 15rem;
      min-width: 15rem;
      padding: 3rem;
      padding-right: 0.5rem;
    }

    &.-scenarios {
      &.-wrapper {
        width: 300px;
        margin-bottom: 1.5rem;
      }
      label {
        display: flex;
      }
    }

    &.-information {
      &.-title {
        font-size: $font-size;
        text-transform: uppercase;
        display: block;
        margin-top: 0;
        margin-bottom: 0;

        &:first-child {
          margin-top: 0;
          font-weight: 600;
          margin-bottom: 0.5rem;
        }
      }
      &.-description {
        line-height: 1.5;
        font-size: $font-size;
        margin: 0;
        color: $colorSupportingWhite;
      }
    }

    &.-diagram {
      width: 100%;
      svg {
        display: block;
        pointer-events: none;
        height: auto;
        top: 0;
        padding-top: 2rem;
        & .node,
        & .connector {
          @each $method, $color in $http {
            &.#{$method} {
              &.connector {
                rect {
                  fill: $color;
                }
              }
              &.active {
                circle {
                  fill: $color;
                  stroke: $colorSupportingWhite;
                }
                path {
                  stroke: $color;
                }
              }
            }
          }
        }
        & .node {
          &.type-api,
          &.type-user {
            &.active {
              circle {
                stroke-width: 3px;
              }
            }
            text.label {
              font-weight: bold;
              opacity: 1;
            }
            text.subtitle {
              font-size: $font-size;
              font-weight: bold;
              fill: $colorSupportingWhite;
            }
            circle {
              fill: $colorTintPrimaryBlackT80;
              stroke: $colorTintPrimaryBlackT60;
              stroke-width: 0px;
            }
          }
          &.type-user {
            text.label {
              @extend .-fontMedium;
              // font-size: $fontMedium;
              fill: $colorSupportingWhite;
              text-transform: uppercase;
              opacity: 1;
            }
            &.active circle,
            circle {
              display: none;
            }
          }
          &.active {
            text.label {
              @extend .-fontSmall;
              // font-size: $fontSmall;
              fill: $colorSupportingWhite;
              opacity: 1;
            }
            circle {
              fill: $colorAccentRed;
              stroke: mix($colorAccentRed, $colorTintPrimaryBlackT60, $stroke-brightness-percent);
              stroke-width: 3px;
            }
          }
          text.label {
            @extend .-fontSmall;
            fill: $colorSupportingWhite;
            // font-size: $fontSmall;
            font-weight: 600;
            opacity: 0.8;
          }
          circle {
            fill: $colorTintPrimaryBlackT80;
            stroke: mix($colorAccentRed, $colorTintPrimaryBlackT60, $stroke-brightness-percent);
            stroke-width: 0px;
          }
        }
        & .connector {
          &.active {
            path {
              stroke: $colorAccentRed;
              stroke-dasharray: 5, 5;
              stroke-width: 2px;
            }
            text,
            rect {
              opacity: 1;
            }
          }
          path {
            fill: none;
            stroke-dasharray: 5, 5;
            animation: dash 50s infinite linear;
            stroke: lighten($colorSupportingBlack, 50%);
            stroke-width: 1px;
          }
          text,
          rect {
            opacity: 0;
          }
          text {
            // @extend .-fontSmall;
            fill: $colorAccentDarkTeal;
            // font-size: $font-size-sm;
            font-size: 0.875rem;
            font-weight: 600;
            text-transform: uppercase;
          }
        }
      }
    }
    &.-fallback {
      img {
        width: 100%;
        height: 100%;
      }
    }
    .t {
      transition: opacity 0.3s ease-in-out, fill 0.3s ease-in-out, r 0.3s ease-in-out;
      will-change: opacity, fill, r;
    }
  }
}

@media (max-width: $screen-md-min) {
  .diagram {
    flex-direction: column;
    &.-sidebar {
      width: 100%;
      text-align: center;
      border-right: none;
      border-bottom: 1px solid $colorSupportingWhite;
      padding: 3rem 0.5rem 3rem 3rem;
    }

    &.-information {
      &.-title,
      &.-description {
        margin-top: 0;
        padding: 15px;
        margin-bottom: 0px;
      }
      &.-description {
        // font-size: $font-size-sm;
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.25rem;
        padding: 0 15px;
      }
    }

    &.-scenarios {
      &.wpdh-form-element {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0;
        margin-bottom: 15px;

        .wpdh-form-element__option-wrapper {
          width: 33.3%;

          &:first-child {
            margin-top: 5px;
          }

          label {
            padding: 15px 0;
            width: 94%;
            height: 100%;
            border: 1px solid $colorTintPrimaryBlackT60;
          }
        }
      }
    }
    &.-diagram {
      svg {
        & .connector {
          path {
            animation: none;
          }
        }
      }
    }
  }
}

@media (max-width: $screen-xs-min) {
  .diagram {
    &.-diagram {
      svg {
        .node {
          .type-user {
            text.label {
              // @extend .-fontLarge;
              // font-size: $font-size-lg;
              font-size: 1.125rem;
              font-weight: 600;
              line-height: 1.4375rem;
            }
          }
          &.active {
            text.label {
              // @extend .-fontLarge;
              // font-size: $font-size-lg;
              font-size: 1.125rem;
              font-weight: 600;
              line-height: 1.4375rem;
            }
          }
          text.label {
            // @extend .-fontMedium;
            // font-size: $font-size-md;
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.25rem;
          }
        }
        .connector {
          text {
            // @extend .-fontSmall;
            // font-size: $font-size-sm;
            font-size: 0.875rem;
            font-weight: 600;
            line-height: 1.25rem;
            font-weight: bold;
          }
        }
      }
    }
    &.-scenarios {
      &.wpdh-form-element {
        .wpdh-form-element__option-wrapper {
          width: 50%;
        }
      }
    }
  }
}
