invite-user {
  .-submit-button {
    margin-top: auto;
    margin-bottom: spacer(3);
    display: flex;
    icon {
      svg {
        top: 0;
        left: 0;
        & > * {
          fill: $colorPrimaryBlack;
        }
      }
    }

    &:hover {
      color: $colorSupportingWhite;
      a {
        color: $colorSupportingWhite;
        icon {
          color: $colorSupportingWhite;
          svg > * {
            fill: $colorSupportingWhite;
          }
        }
      }
    }
  }

  .invite-user {
    &.-questions {
      display: flex;
      flex-direction: row !important;
      justify-content: space-between !important;
    }

    &.-flex-right {
      justify-content: flex-end !important;
    }

    &.-question {
      width: 49%;
    }

    &.-card {
      display: flex;
      flex-direction: column;
      margin-top: 33px;
      cardBasic {
        flex: 1;

        &.-border {
          padding: spacer(3) spacer(30) spacer(5) spacer(30);
        }
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  invite-user {
    .invite-user {
      &.-questions {
        flex-direction: column !important;
      }

      &.-flex-right {
        flex-direction: row !important;
      }
    }

    .-submit-button {
      float: unset;
    }
  }
}

@media (max-width: $screen-tablet) {
  invite-user {
    .invite-user {
      &.-question {
        width: 100%;
      }
    }
  }
}
