.social-network-share {
  &.-share-button {
    height: 36px;
    width: 36px;
    border: 2px solid $colorPrimaryGreen;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    background-color: $colorPrimaryGreen;
    cursor: pointer;
    position: relative;
    > icon {
      transform: rotate(-90deg);
      margin: auto;
    }
  }
  &.-share-pallete {
    width: 50px;
    border: 1px solid $colorAccentDarkTeal;
    border-radius: 4px;
    position: absolute;
    bottom: 55px;
    left: -10px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $colorShadePrimaryBlackS20;
    .social-media-type {
      height: 24px;
      width: 24px;
      margin: 8px auto;
    }
  }
  &.-share-pallete::after {
    content: '';
    position: absolute;
    transform: rotate(45deg);
    border-bottom: 1px solid $colorAccentDarkTeal;
    border-right: 1px solid $colorAccentDarkTeal;
    left: 25px;
    width: 12px;
    height: 12px;
    bottom: -7px;
    border-top-left-radius: 6px;
    background-color: $colorShadePrimaryBlackS20;
    z-index: 1;
  }
  .-social-icon {
    border: 2px solid white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
  .-mail-icon {
    icon {
      height: 14px;
      width: 14px;
    }
  }
  .-twitter-icon {
    icon {
      left: 1px;
    }
  }
}
