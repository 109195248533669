mastheadcta {
  position: relative;

  .-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: spacer(5);
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  div p {
    margin-top: spacer(4) !important;
  }
  .-notification {
    margin-bottom: spacer(5) !important;
    min-height: spacer(60);
  }
}

@media (max-width: $screen-sm-max) {
  masthead {
    .-content {
      display: flex !important;
    }

    .mastheadcta {
      &.-content > {
        div:nth-child(2) {
          margin-top: spacer(7);
          margin-bottom: spacer(7);
        }
      }
    }

    .-content > div:nth-child(2) {
      order: 3;
    }
  }
}
