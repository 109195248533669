.guided-modal {
  .-modal-footer {
    position: relative;
    font-size: 15px;
    border-top: 1px solid var(--fismodal-border-color);
    padding: spacer(5) 0 spacer(2) 0;
  }

  .-content-text {
    color: var(--modal-text-color);
  }

  wpbutton {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 12px;

    a {
      padding: spacer(2) spacer(4);
    }
  }

  .-guided-modal-description-padding {
    padding: 0 spacer(5) spacer(30) spacer(5);
  }

  @media (min-width: $screen-md) {
    modal {
      .fismodal1 {
        &.-contentContainer {
          max-width: 28rem;
        }
      }
    }
  }
}
