sf-modal {
  .sf-modal {
    &.-background {
      background-color: var(--sf-modal-bg-color);
      height: 100vh;
      left: 0;
      position: fixed;
      top: 0;
      width: 100vw;
      z-index: 5;
      align-items: center !important;
      justify-content: center !important;
      overflow: auto;
      padding-bottom: spacer(6);
    }

    &.-header {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin: 42px;
      // gap: 8px;
    }

    &.-header-logo {
      height: 42px;
      width: 136px;
    }

    &.-header-buttons {
      position: relative;
      display: flex;
    }

    &.-header-button-restart {
      @extend .-fontMedium;
      display: flex;
      margin-top: 3px;
      cursor: pointer;
      margin-right: spacer(5);
      icon {
        margin-top: -2px;
        margin-right: spacer(2);
        cursor: pointer;
        svg > * {
          fill: var(--sf-modal-restart-icon-color);
        }
      }
    }

    &.-header-button-close {
      display: flex;
      icon {
        cursor: pointer;
        svg > * {
          fill: var(--sf-modal-close-icon-color);
        }
      }
    }

    &.-content {
      margin-top: 54px;
      display: flex;
      justify-content: center;
    }
  }
}
@media (max-width: $breakpoint-medium-mobile) {
  sf-modal {
    .sf-modal {
      &.-background {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (min-width: $screen-mobile-xs) and (max-width: $screen-mobile-large-one) {
  sf-modal {
    .sf-modal {
      &.-header {
        justify-content: center;
        margin: 42px 16px;
        gap: 8px;
      }
    }
  }
}