api-left-menu {
  width: 220px;
  display: inline-block;
  position: sticky;
  top: spacer(84);
  h5 {
    font-size: 18px;
    font-weight: 600;
    margin: spacer(3);
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      color: #ffffff;
      font-size: 15px;
      list-style-type: none;
      margin: 0;
      padding: 0;
      app-link {
        width: 100%;
        &.active a {
          color: var(--api-left-menu-active-text-color);
          &::before {
            content: '';
            border-left: 2px solid;
            height: 28px;
            margin-right: spacerThree(3);
            vertical-align: middle;
            display: inline-flex;
          }
        }
        a {
          color: var(--api-left-menu-text-color);
          padding: spacerThree(2) spacer(3);
          display: inline-block;
          width: 100%;
          height: 40px;
          &:hover {
            background: var(--api-left-menu-hover-bg-color);
            color: var(--api-left-menu-hover-text-color);
            cursor: pointer;
            border-radius: 5px;
            text-decoration: none;
          }
        }
      }
    }
  }
  @media (max-width: ($screen-laptop-sm - 0.5)) {
    h5 {
      margin-left: unset;
    }
    ul {
      display: -webkit-inline-box;
      overflow-x: auto;
      width: 97vw;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
      li app-link {
        a {
          display: flex;
          padding-left: spacer(5);
          padding-right: spacer(5);
          &:hover {
            background: transparent;
            border-radius: 0;
            color: $colorSupportingWhite;
          }
          &::before {
            display: none !important;
          }
        }
        &.active a {
          color: var(--api-left-menu-active-text-color);
          border-bottom: 2px solid var(--api-left-menu-active-text-color);
        }
      }
    }
  }
}
