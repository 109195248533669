app-auth-research {
  height: calc(100vh - 140px);
  .-api-spec-container {
    position: relative;
    top: 70px;
    height: 100%;
    .hosted-api-docs {
      height: 100%;
    }
  }
}

@media (max-width: $breakpoint-mid-two-mobile-max) {
  app-auth-research {
    .-api-spec-container {
      top: 140px;
    }
  }
}
