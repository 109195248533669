$hairline: 0.0625rem; // 1px
$borderWidth: 0.125rem; // 2px

$spacerThree: 3px !default;
$spacer: 6px !default;
// stylelint-disable-next-line scss/dollar-variable-default

// 0 3 6 12 18 24 36 48 78 108
$spacers: (
  0: 0,
  1: $spacer * 0.5,
  2: $spacer,
  3: $spacer * 2,
  4: $spacer * 3,
  5: $spacer * 4,
  6: $spacer * 6,
  7: $spacer * 8,
  8: $spacer * 13,
  9: $spacer * 18,
  42: $spacer * 7,
  30: $spacer * 5,
  54: $spacer * 9,
  60: $spacer * 10,
  66: $spacer * 11,
  72: $spacer * 12,
  84: $spacer * 14,
  96: $spacer * 16,
  102: $spacer * 17,
  108: $spacer * 18,
  114: $spacer * 19,
  126: $spacer * 21,
  132: $spacer * 22,
  144: $spacer * 24,
  156: $spacer * 26,
  162: $spacer * 27,
  174: $spacer * 29,
  192: $spacer * 32,
  204: $spacer * 34,
  239: $spacer * 39,
  300: $spacer * 50,
  432: $spacer * 72
);

$spacerThrees: (
  0: 0,
  1: $spacerThree * 1,
  2: $spacerThree * 2,
  3: $spacerThree * 3,
  4: $spacerThree * 4,
  5: $spacerThree * 5,
  6: $spacerThree * 6,
  7: $spacerThree * 7,
  8: $spacerThree * 8,
  9: $spacerThree * 9,
  10: $spacerThree * 10,
  12: $spacerThree * 12,
  13: $spacerThree * 12,
  14: $spacerThree * 14,
  17: $spacerThree * 17,
  22: $spacerThree * 22,
  11: $spacerThree * 11,
  33: $spacerThree * 33,
  45: $spacerThree * 15,
  180: $spacerThree * 60
);

// Helper function
@function spacer($key: 0) {
  @return map-get($spacers, $key);
}

@function spacerThree($key: 0) {
  @return map-get($spacerThrees, $key);
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-10 {
  margin-top: spacerThree(3);
}