card-support {
  height: 100%;
  color: var(--card-product-text-color);
  background-color: var(--card-support-bg-color);

  box-shadow: 0px 8px 20px 0px var(--card-support-shadow-color-one),
    0px 4px 6px 0px var(--card-support-shadow-color-two), 0px 1px 0px 0px var(--card-support-shadow-color-three);

  border-radius: spacer(3);
  padding: spacer(42) spacer(7);

  .card-support {
    icon {
      display: inline-block;
      height: 55px;
      width: 72px;
    }
    .-icon-container {
      display: flex;
      align-items: center;
    }

    &.-title {
      display: flex;
    }

    h3 {
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      margin: 0 0 spacerThree(3) spacer(3);
      flex: 1;
      color: var(--card-support-title-color) !important;
    }
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin: 0 0 spacerThree(5) spacer(3);
    color: var(--card-support-text-color) !important;
  }
  .grid {
    margin: 0;
    padding: 0;
    justify-content: space-between;

    icon {
      margin-top: spacer(1);
      margin-right: spacer(2);
    }

    gridColumn {
      margin-top: spacerThree(5);
      flex-direction: initial;
      width: 50%;
      flex-grow: unset;
      padding: 0;

      &.-align {
        width: auto;
      }

      app-link {
        a {
          color: var(--card-product-link-color);
          &:hover {
            color: var(--card-product-link-hover-color);
            text-decoration: var(--card-product-link-hover-decoration);
          }
        }
      }
    }

    link-icon {
      flex-direction: column;
      a {
        color: var(--card-product-link-color);
        line-height: spacer(5);

        icon,
        .link-icon.-parent.-text {
          margin-top: 0;
        }

        &:hover {
          color: var(--card-product-link-hover-color);
        }
      }
    }
  }

  wpbutton {
    margin: unset;
  }

  @media (max-width: ($screen-sm - 1px)) {
    padding: spacer(5);
    .card-support {
      flex-direction: column;
      gap: 16px;

      icon {
        width: 55px;
      }

      h3 {
        margin-left: 0;
        font-size: 20px !important;
        line-height: 30px !important;
      }

      p {
        margin-left: 0;
      }
    }
  }

  @media (min-width: $screen-sm) and (max-width: ($screen-lg-desktop - 1px)) {
    padding: spacer(5);
  }
}

card-support > div {
  display: flex;
  gap: 24px;
}
