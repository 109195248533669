@mixin card-style {
  padding-top: spacer(4);
  padding-bottom: spacer(4);
  flex-grow: 1;
  width: 33%;
}

@mixin card-common-style {
  .-card {
    padding-left: spacer(3);
    padding-right: spacer(3);
    margin: 0 spacer(3);
    width: 100%;
    &:first-of-type {
      padding-left: spacer(3);
    }
  }
}
app-convergence-footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h1 {
    line-height: 36px;
    margin: 0 auto;
    max-width: 311px;
  }
  h4 {
    font-weight: 600;
    margin-bottom: spacer(2);
    margin-top: 0;
  }
  p {
    font-weight: 600;
  }
  .footer {
    &.-primary {
      display: flex;
      flex-wrap: wrap;
      padding: spacer(54) 0 spacer(7);
      background-color: $colorShadePrimaryBlackS20;
      width: 100%;
      .-card {
        @include card-style;

        &:first-of-type {
          padding-left: spacer(66);
          span {
            display: block;
          }
        }

        &:last-child {
          padding-left: spacer(36);
        }
        wpbutton {
          margin-top: spacer(2);
        }
      }
      .-contact {
        border-bottom: $hairline solid $colorAccentDarkTeal;
        border-left: 0;
        border-right: 0;
        border-top: $hairline solid $colorAccentDarkTeal;
      }
      .-channelContainer {
        margin-top: spacer(6);
      }
      .-link-icon-wrapper {
        a {
          color: $colorAccentLightBlue;
        }
        a:hover {
          color: $colorSupportingWhite;
        }
        icon {
          margin-left: spacer(2);
        }
        icon.-colorPrimaryGreen svg > * {
          fill: $colorAccentLightBlue;
        }
        a:hover > icon.-colorPrimaryGreen svg > * {
          fill: $colorSupportingWhite;
        }
      }
      &.-started-btn {
        margin-bottom: spacer(7);
      }
    }
    &.-secondary {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: spacer(5) 0;
      background-color: $colorShadePrimaryBlackS20;
      .-card {
        @include card-style;
        display: flex;
        justify-content: center;
        &:last-child {
          padding-left: spacer(36);
        }
      }
      .-contact {
        wpbutton.-secondary {
          margin: 0;
        }
      }
      &.-started-btn {
        margin-bottom: spacer(7);
      }
    }
    .-support h3,
    .-contact h3 {
      margin-top: 0;
      margin-bottom: spacer(3);
    }
    .-stay-connected {
      margin-top: spacer(7);
    }
    .-link-icon-wrapper {
      a {
        color: $colorAccentLightBlue;
      }
      a:hover {
        color: $colorSupportingWhite;
      }
      icon {
        margin-left: spacer(2);
      }
      icon.-colorPrimaryGreen svg > * {
        fill: $colorAccentLightBlue;
      }
      a:hover > icon.-colorPrimaryGreen svg > * {
        fill: $colorSupportingWhite;
      }
    }
    .-channel {
      h4 {
        margin-bottom: spacer(2);
      }
      p {
        color: $colorAccentLightBlue;
        font-weight: 400;
        cursor: pointer;
      }
      p:hover {
        color: $colorSupportingWhite;
      }
      icon.-colorPrimaryGreen svg > * {
        fill: $colorAccentLightBlue;
      }
      a:hover > icon.-colorPrimaryGreen svg > * {
        fill: $colorSupportingWhite;
      }
    }
    .-social {
      padding: 0;
      padding-inline-start: 0;
      display: flex;
      flex-direction: row;
      margin-top: spacer(2);

      a {
        height: spacer(5);
        width: spacer(5);
        border-radius: 50%;
        margin-right: spacer(3);
      }
      icon.-colorPrimaryGreen svg > * {
        fill: $colorSupportingWhite;
      }
    }

    &.-container {
      background: $colorPrimaryBlack;
      padding: spacer(5) 0;
      width: 100%;
      display: flex;
      justify-content: center;
      .-copyright {
        display: flex;
        justify-content: center;
        max-width: 1240px;
        flex-wrap: wrap;
        p {
          margin: 0;
        }
        ul {
          list-style: none;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          text-align: center;
          margin: 0;
          padding-left: spacer(5);
          li {
            margin: 0;
            padding: 0 spacer(3);
            a {
              display: inline-block;
              color: $colorAccentLightBlue;
              width: 100%;
              font-weight: 600;
              icon {
                display: none;
              }
            }
            a:hover {
              color: $colorSupportingWhite;
            }
            icon.-colorPrimaryGreen svg > * {
              fill: $colorAccentLightBlue;
            }
            a:hover icon.-colorPrimaryGreen svg > * {
              fill: $colorSupportingWhite;
            }
          }
        }
      }
    }
  }
}
@media (max-width: $screen-md) {
  app-convergence-footer {
    .footer {
      padding: spacer(5);
      &.-primary {
        padding: spacer(4) 0;
        .-card {
          padding-top: spacer(4);
        }
        .-channelContainer {
          flex-flow: column;
        }
        .-card {
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
      &.-secondary {
        padding-bottom: 0;
        .-card {
          &:last-child {
            padding-top: spacer(5);
          }
        }
      }
      .-stay-connected {
        margin-top: 0;
        padding-bottom: 0;
      }
      .-card {
        width: 100%;
        text-align: center;
        &:first-of-type {
          padding: 0 spacer(5) spacer(4);
          h1 {
            margin: 0;
            font-size: 24px;
            max-width: 100%;
          }
        }
      }
      &.-copyright {
        text-align: center;
        height: auto;
        margin-top: spacer(1);
        display: block;
      }
      .-channelContainer,
      .-social {
        justify-content: center;
      }

      &.-container {
        padding-bottom: spacer(3);
        .-copyright {
          p {
            margin-bottom: spacer(5);
          }
          ul {
            justify-content: center;
            width: 100%;
            flex-wrap: wrap;
            padding: 0 spacer(3);
            li {
              padding: 0 spacer(3) spacer(3);
            }
          }
        }
      }
    }
  }
}
@media (min-width: $screen-md) {
  app-convergence-footer {
    .footer {
      &.-primary {
        .-card {
          padding: 0 spacer(6) spacer(3);
        }
        .-contact {
          border-bottom: 0;
          border-left: $hairline solid $colorAccentDarkTeal;
          border-right: $hairline solid $colorAccentDarkTeal;
          border-top: 0;
        }
        .-channel {
          width: 50%;
        }
      }
      &.-secondary {
        .-card {
          padding: 0;
        }
      }
    }
  }
}

@media (max-width: $screen-sm) {
  app-convergence-footer {
    flex-direction: column;
    .footer {
      &.-primary {
        @include card-common-style; // have to deifne this as other will override
      }
      &.-secondary {
        @include card-common-style; // have to deifne this as other will override
      }
    }
  }
}
