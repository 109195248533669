// Icon size needs to be rewritten using scale so it fits in parent container properly.
// Icons must be redone to be of same dimensions. All icons are currently different sizes
//   to viewBox 0 0 29 29?

$opacity: 0.3;
icon {
  display: inline-block;
  position: relative;
  height: spacer(5);
  width: spacer(5);

  &.-floatLeft {
    float: left;
  }

  &.secondary-height {
    height: 15px;
  }

  .icon {
    &__label {
      color: $colorSupportingBlack;
      position: absolute;
      text-align: center;
      top: 0;
      width: 100%;
    }
  }

  &.-textSupportingWhite {
    .icon {
      &__label {
        color: $colorSupportingWhite;
      }
    }
  }

  @include colorClasses('svg > * ', 'fill');

  svg {
    all: inherit;
    background-color: transparent;
    display: inline-block;
    margin: auto;
    transform: scale(1);
    position: absolute;
  }

  svg > * {
    fill: var(--icon-clor);
  }

  &.-round {
    border-radius: spacer(3);

    svg {
      height: calc(#{spacer(3)} * 0.8);
      margin: calc(#{spacer(2)} * 1.1) 0 0 calc(#{spacer(2)} * 1.2);
      position: absolute;
      width: calc(#{spacer(3)} * 0.8);
    }

    &.-roundBold {
      svg {
        height: calc(#{spacer(5)} * 0.8);
        margin: calc(#{spacer(1)} * 0.8) 0 0 calc(#{spacer(2)} * 0.8);
        position: absolute;
        width: calc(#{spacer(4)} * 0.8);
      }
    }
  }

  &.-xsm {
    height: spacer(3);
    width: spacer(3);
  }

  &.-sm15 {
    height: spacerThree(5);
    width: spacerThree(5);
  }

  &.-sm {
    height: spacer(4);
    width: spacer(4);

    &.-round {
      svg {
        margin: calc(#{spacer(1)} * 1.3) 0 0 calc(#{spacer(1)} * 1.3);
      }
    }
  }

  &.-xmd {
    height: spacer(5);
    width: spacer(5);
  }

  &.-md-wide {
    height: spacer(5);
    width: spacer(6);
  }

  &.-md {
    height: spacer(6);
    width: spacer(6);

    .icon {
      &__label {
        top: spacer(2);
        right: spacer(1);
      }
    }
  }

  &.-no-cursor {
    cursor: default;
  }

  &.-margin-left {
    margin-left: spacer(2);
    text-align: initial;
  }

  &.-lg {
    height: spacer(7);
    width: spacer(7);

    &.-round {
      border-radius: spacer(7);

      svg {
        height: calc(#{spacer(5)} * 0.8);
        margin: calc(#{spacer(7)} * 0.3) 0 0 calc(#{spacer(7)} * 0.3);
        position: absolute;
        width: calc(#{spacer(5)} * 0.8);
      }
    }
  }

  &.-xlg {
    height: 72px;
    width: 72px;

    &.-round {
      border-radius: 72px;

      svg {
        height: calc(48px * 0.8);
        margin: calc(72px * 0.3) 0 0 calc(48px * 0.35);
        position: absolute;
        width: calc(48px * 0.8);
      }
    }
  }

  &.-tile-size {
    height: 66px;
    width: 66px;
  }

  &.-backgroundAccentCoolGray {
    background-color: $colorAccentCoolGray;

    &.-opacity {
      background-color: rgba($colorAccentCoolGray, $opacity);
    }
  }
  &.-backgroundSupportingGreen {
    background-color: $colorSupportingGreen;

    &.-opacity {
      background-color: rgba($colorSupportingGreen, $opacity);
    }
  }
  &.-backgroundPrimaryGreen {
    background-color: $colorPrimaryGreen;

    &.-opacity {
      background-color: rgba($colorAccentCoolGray, $opacity);
    }
  }
  &.-backgroundAccentRed {
    background-color: $colorAccentRed;

    &.-opacity {
      background-color: rgba($colorAccentRed, $opacity);
    }
  }
  &.-backgroundSupportingWhite {
    background-color: $colorSupportingWhite;

    &.-opacity {
      background-color: rgba($colorSupportingWhite, $opacity);
    }
  }
  &.-backgroundLightBlue {
    background-color: var(--button-icon-bg-color);

    &.-opacity {
      background-color: rgba(var(--button-icon-bg-color), $opacity);
    }
  }
  &.-backgroundAccentYellow {
    background-color: $colorAccentYellow;

    &.-opacity {
      background-color: rgba($colorAccentYellow, $opacity);
    }
  }
  &.-backgroundWpPrimaryLightBlue {
    background-color: var(--test-case-status-icon-color);

    &.-opacity {
      background-color: rgba($colorWpPrimaryLightBlue, $opacity);
    }
  }
  &.-backgroundAccentLightBlue {
    background-color: $colorAccentLightBlue;

    &.-opacity {
      background-color: rgba($colorAccentLightBlue, $opacity);
    }
  }
  &.-backgroundAccentDarkBlue {
    background-color: var(--button-icon-bg-hover-color);

    &.-opacity {
      background-color: rgba(var(--button-icon-bg-hover-color), $opacity);
    }
  }
  &.-backgroundTintPrimaryBlackT60 {
    background-color: $colorTintPrimaryBlackT60;

    &.-opacity {
      background-color: rgba($colorTintPrimaryBlackT60, $opacity);
    }
  }

  &.-colorIconCircle {
    background-color: var(--go-live-icon-circle);
  }

  &.-colorIconCircleGray {
    background-color: $colorIconCircleGray;
  }

  &.-backgroundAccentTeal {
    background-color: $colorAccentTeal;

    &.-opacity {
      background-color: rgba($colorAccentTeal, $opacity);
    }
  }
  &.-backgroundShadePrimaryBlackS50 {
    background-color: $colorShadePrimaryBlackS50;

    &.-opacity {
      background-color: rgba($colorShadePrimaryBlackS50, $opacity);
    }

    &.-border {
      border: 1px solid #cdcdcd;
      svg {
        border: none;
      }
    }
  }

  &.-backgroundShadePrimary {
    &.-opacity {
      background-color: rgba($colorAccentDeepBlue, $opacity);
    }

    &.-border {
      border: 1px solid var(--background-Shade-Primary-border);
      svg {
        border: none;
      }
    }
  }
}