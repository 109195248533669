.hidden {
  display: none;
}

.margin-reset-children {
  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.margin-reset {
  margin: 0;
}

.margin-reset-top {
  margin-top: 0;
}

.margin-reset-bottom {
  margin-bottom: 0;
}

.padding-reset {
  padding: 0;
}

.padding-reset-top {
  padding-top: 0;
}

.padding-reset-bottom {
  padding-bottom: 0;
}

.buffer {
  padding-top: $dimension-padding;
}

.nowrap {
  white-space: nowrap;
}

.clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

@media (max-width: $screen-xs-max) {
  .hidden-xs {
    display: none;
  }
  .visible-sm,
  .visible-md,
  .visible-lg {
    &:not(.visible-xs) {
      display: none;
    }
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .hidden-sm {
    display: none;
  }

  .visible-xs,
  .visible-md,
  .visible-lg {
    &:not(.visible-sm) {
      display: none;
    }
  }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .hidden-md {
    display: none;
  }

  .visible-xs,
  .visible-sm,
  .visible-lg {
    &:not(.visible-md) {
      display: none;
    }
  }
}

@media (min-width: $screen-lg-min) {
  .hidden-lg {
    display: none;
  }

  .visible-xs,
  .visible-sm,
  .visible-md {
    &:not(.visible-lg) {
      display: none;
    }
  }
}

.flex {
  display: flex;
}

.flex-space-between {
  justify-content: space-between;
}

.margin-top-80 {
  margin-top: 80px;
}
