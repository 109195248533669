.convergence-masthead-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;

  .convergence-masthead-breadcrumb {
    display: inline-block;
    border-radius: spacer(4);
    padding: spacerThree(1) spacerThree(5);
    background: #123e48;
    border: 1.5px solid #3bcff0;
    font-family: Source Sans Pro;
    font-size: spacerThree(5);
    font-style: normal;
    font-weight: 600;
    line-height: spacerThree(7);
    letter-spacing: 0px;
    text-align: center;
    margin-bottom: spacer(5);
  }

  img {
    width: 100%;
  }

  h1 {
    margin: 0 0 spacerThree(12);
    text-transform: none;
    font-weight: 200;
  }

  p {
    margin-bottom: spacerThree(9);
  }

  link-icon {
    font-weight: 600;
  }

  @media (min-width: $screen-md-min) {
    margin: initial;
    flex-direction: row-reverse;
    justify-content: flex-end;

    .img-container,
    .text-container {
      width: 50%;
    }

    .text-container {
      padding: 0 spacerThree(33);
    }
  }
}
