notification {
  @include colorClasses('.-container', 'border-left-color');
  @include colorClasses('.-title', 'color');
  @include colorClasses('.-title', 'border-right-color');

  .-content-link a {
    color: var(--notification-link-color);
    font-weight: 600;
  }

  &.hideBanner {
    display: none !important;
  }

  &.-colorAccentLightPurple {
    .notification {
      &.-container {
        border: $hairline solid var(--notification-notes-yellow-border-color);
        border-left-width: spacer(2);
        background-color: var(--notification-notes-yellow-background-color);
      }
    }
  }

  &.-colorAccentRed {
    .notification {
      &.-container {
        border: $hairline solid var(--notification-error-border-color);
        border-left-width: spacer(2);
        background-color: var(--notification-error-background-color);
      }
    }
  }

  &.-colorAccentLightRed {
    .notification {
      &.-container {
        border: $hairline solid var(--notification-error-border-color);
        border-left-width: spacer(2);
        background-color: var(--notification-error-background-color);
      }
    }
  }

  &.-colorAccentPink {
    .notification {
      &.-container {
        border: $hairline solid var(--notification-error-border-color);
        border-left-width: spacer(2);
        background-color: var(--notification-error-background-color);
      }
    }
  }

  &.-colorAccentGreen {
    .notification {
      &.-container {
        border: $hairline solid var(--notification-success-border-color);
        border-left-width: spacer(2);
        background-color: var(--notification-success-background-color);
      }
    }
  }

  &.-colorAccentLightGreen {
    .notification {
      &.-container {
        border: $hairline solid var(--notification-success-border-color);
        border-left-width: spacer(2);
        background-color: var(--notification-success-background-color);
      }
    }
  }

  &.-colorAccentLightBlue {
    .notification {
      &.-container {
        border: $hairline solid var(--notification-notes-border-color);
        border-left-width: spacer(2);
        background-color: var(--notification-notes-background-color);
      }
    }
  }

  &.-colorAccentSunglow {
    .notification {
      &.-container {
        border: $hairline solid var(--notification-notes-yellow-border-color);
        border-left-width: spacer(2);
        background-color: var(--notification-notes-yellow-background-color);
      }
    }
  }

  &.-colorAccentYellow {
    .notification {
      &.-container {
        border: $hairline solid var(--notification-notes-yellow-border-color);
        border-left-width: spacer(2);
        background-color: var(--notification-notes-yellow-background-color);
      }
    }
  }

  .notification {
    &.-container {
      background-color: var(--notification-background-color);
      margin: 0 spacer(2) spacer(2) 0;
      padding: spacer(3) spacer(3);
      border-left-style: solid;
      display: flex;
      // to add transition effect
      opacity: 1;
      transition: opacity, 0.3s;
    }

    &.-title {
      margin-right: spacer(3);
      padding-right: 12px;
      border-right: 2px solid;
      color: var(--notification-text-color);
    }

    &.-dismiss {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;

      a:hover {
        color: $colorPrimaryGreen;
        cursor: pointer;
      }
    }

    &.-noRightBorder {
      border-right: 0px none;
      display: none;
    }

    &.-description {
      color: var(--notification-text-color);
      display: flex;
      width: 75%;

      p {
        margin: 0 !important;
        word-break: break-word;
      }
    }

    &.-centerDescription {
      display: grid;
      place-items: center;
    }

    &.-displayNone {
      // changed to opacity for transition effect
      opacity: 0;
    }
  }

  @media (max-width: 380px) {
    .notification {
      &.-dismiss {
        display: none;
      }
    }
  }
}
