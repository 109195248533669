* {
    box-sizing: border-box;
}

html {
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

body::after {
    content: '';
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: -1;
}

.is-scroll-disabled,
.is-mobile-scroll-disabled {
    overflow: hidden;
}

img {
    max-width: 100%;
    height: auto;
}

video {
    max-width: 100%;
    height: auto;
}

@media (min-width: $screen-md-min) {
    .is-mobile-scroll-disabled {
        overflow: visible;
    }
}