@import 'colors';

html,
body {
  font-family: 'Source Sans Pro', sans-serif !important;
  // Below controls the REM sizing for the entire site.
  font-size: 16px !important;
  font-weight: 400;
  line-height: 1.5rem;
  background-color: var(--body-background-color);
  color: var(--body-text-color);
}

.-fontColorGreen {
  color: $colorAccentTeal;
}

.-fontColor {
  color: $colorAccentLightBlue;
}

.-fontColorAccentBlue {
  color: $colorWpAccentDarkBlue;
}

a {
  color: var(--link-color);
  text-decoration: none;

  // &:active {
  //   color: $colorPrimaryGreen;
  // }

  // &:visited {
  //   color: $colorSupportingWhite;
  // }

  
  &:hover {
    color: var(--link-hover-color);
    text-decoration: underline;
    text-decoration-color: var(--link-hover-color);
  }

  &:focus {
    color: var(--link-focus-text-color);
    border: 1.5px solid var(--link-focus-color);
    text-decoration: none;
  }
}

app-link {
  display: inline-block;
}
