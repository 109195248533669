sc-product-cards{
  .sc-product-cards{
    &.-product-card-container{
      background-color: #012834;
      padding: spacer(30);
      margin: spacer(30) 0;
      border: 1px solid #013B4D;
      border-radius: 4px;
      max-width: 60rem;
    }
    &.-product-card-options{
      display: flex;
      margin: spacer(4) 0;
    }
    &.-product-card-desc {
      font-weight: 300;
    }
    &.-product-checkbox{
      max-width: 1.8rem !important;
      margin-right: spacer(3);
      width: 3.5rem !important;
    }
    &.-collapsableTitleHeader{
      display: flex;
      justify-content: space-between;
    }
    &.-learnMoreLinkIcon{
      margin-right: spacer(54);
    }
    &.-collapsableTitle, .-learnMoreLinkIcon{
      margin-top:0 !important;
      margin-bottom:0 !important;
    }
    &.-collapsableParent{
      position: relative;
    }
    &.-productDescription{
      margin-top: spacer(5);
    }
  }
}