:host(.hideButton) {
  display: none !important;
}

wpbutton {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;

  background-color: var(--button-primary-background-color);
  border-radius: 8px;
  color: var(--button-primary-text-color);
  margin: 20px spacer(2) spacer(3) 0;
  user-select: none;
  display: inline-block !important;
  transition: 0.3s;

  &:focus {
    box-shadow: 0px 0px 0px 3px var(--button-primary-focus-border-color),
      0px 0px 0px 2px var(--button-primary-focus-border-color-two);
  }

  &:focus-visible {
    outline: none !important;
  }

  .-infinite-spin {
    animation: rotate 2s linear infinite;
  }

  &[disabled] {
    pointer-events: none;
    background-color: var(--button-disabled-background-color);
    color: var(--button-disabled-text-color);
    a {
      color: var(--button-disabled-text-color) !important;
    }
  }

  &:not([disabled]):hover {
    background-color: var(--button-primary-background-hover-color);
    color: var(--button-primary-text-color);
    // border: $borderWidth solid var(--button-primary-background-hover-color);
    a {
      color: var(--button-primary-text-color) !important;
      text-decoration: none !important;
      icon {
        svg > * {
          fill: var(--button-primary-text-color);
        }
      }
    }
    icon {
      color: var(--button-primary-text-hover-color) !important;
    }
  }

  & a {
    color: var(--button-primary-text-color) !important;
    display: flex;
    cursor: pointer;
    padding: 6px 12px;
    line-height: 24px;
    height: 38px;

    icon {
      &.-refresh {
        margin: auto 0 auto 6px;
      }
    }

    &.-external {
      icon {
        display: flex;
        margin: auto 0 auto spacer(2);
        svg > * {
          fill: var(--button-primary-text-color);
        }
      }
    }

    &:focus {
      border: unset !important;
    }
  }

  &.-secondary,
  &.-outline,
  &.-gray {
    color: var(--button-secondary-text-color) !important;
    background-color: transparent;
    border: 1.5px solid var(--button-secondary-border-color);

    &:focus {
      box-shadow: 0px 0px 0px 3px var(--button-primary-focus-border-color),
        0px 0px 0px 2px var(--button-secondary-focus-shadow-color-two);
    }

    a {
      color: var(--button-secondary-text-color) !important;
      height: unset;
      &.-external {
        icon {
          svg > * {
            fill: var(--button-secondary-text-color);
          }
        }
      }
    }

    &:hover icon svg > * {
      fill: var(--button-secondary-text-color) !important;
    }

    &.-external::after {
      // background-image: url('#{$path-icons}/external-link-white.svg');
    }
    &:not([disabled]):hover {
      color: var(--button-secondary-text-color) !important;
      background-color: var(--button-secondary-background-hover-color);
      a {
        color: var(--button-secondary-text-color) !important;
        .-refresh svg > * {
          fill: var(--button-secondary-text-color) !important;
        }
      }

      &.-external::after {
        // background-image: url('#{$path-icons}/external-link-grey.svg');
      }
    }
    &[disabled] {
      pointer-events: none;
      background-color: transparent;
      color: var(--button-disabled-text-color);
      border: 1.5px solid var(--button-disabled-background-color);
    }
  }

  &.-small {
    height: spacer(5);
    @extend .-fontSmall;
    a {
      padding: 0 spacer(4);
    }
  }

  &.-tertiary {
    color: var(--button-tertiary-text-color) !important;
    background-color: var(--button-tertiary-background-color);
    border: 1.5px solid var(--button-tertiary-border-color);
    a {
      color: var(--button-tertiary-text-color) !important;
      height: unset;
      icon {
        svg > * {
          fill: var(--button-tertiary-text-color) !important;
        }
      }
    }

    &[disabled] {
      pointer-events: none;
      background-color: var(--button-tertiary-disabled-bg-color);
      color: var(--button-tertiary-disabled-text-color) !important;
      border: 1.5px solid var(--button-tertiary-disabled-border-color);
      a {
        color: var(--button-tertiary-disabled-text-color) !important;
        icon {
          svg > * {
            fill: var(--button-tertiary-disabled-text-color) !important;
          }
        }
      }
    }

    &:not([disabled]):hover {
      background-color: var(--button-tertiary-background-hover-color);
      border: 1.5px solid var(--button-tertiary-hover-border-color);
      color: var(--button-tertiary-text-hover-color) !important;
      a {
        color: var(--button-tertiary-text-hover-color) !important;
        text-decoration: none !important;
        icon {
          svg > * {
            fill: var(--button-tertiary-text-hover-color) !important;
          }
        }
      }
    }

    &:not([disabled]):focus {
      background-color: var(--button-tertiary-focus-bg-color);
      color: var(--button-tertiary-text-color) !important;
      border: 1px solid var(--button-tertiary-hover-border-color);
      box-shadow: 0px 0px 0px 2px var(--button-tertiary-focus-shadow-color-one),
        0px 0px 0px 2px var(--button-tertiary-focus-shadow-color-two);

      a {
        color: var(--button-tertiary-text-color) !important;
        text-decoration: none !important;
        icon {
          svg > * {
            fill: var(--button-tertiary-text-color) !important;
          }
        }
      }
    }
  }
}
