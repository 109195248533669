api-versions-convergence {
  margin: 12px 24px 12px 12px;
  .version-button {
    color: var(--dropdown-text-color);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: unset;
    padding: 8px 12px;
    width: 160px;
    height: 36px;
    right: 14px;
    top: 14px;
    align-self: stretch;
    background: var(--version-control-bg-color);
    border: 1px solid var(--version-control-border-color);
    border-radius: 4px;
    span {
      font-weight: 400;
    }

    &:focus {
      box-shadow: 0px 0px 0px 2px var(--version-control-focus-shadow-color);
      border: 1px solid var(--version-control-border-focus-color);
    }
    &:focus-visible {
      outline: none !important;
    }
    &:hover {
      border-color: var(--version-control-border-hover-color);
    }
  }

  .-icon-padding {
    padding-left: spacer(3);
  }
  .dropdown-wrapper {
    position: absolute;
    background: var(--version-control-bg-color);
    border: 1px solid var(--dropdown-pull-down-border-color);
    border-radius: 4px;
    box-shadow: 0px 4px 5px 0px var(--dropdown-pull-down-shadow-color),
      0px 1px 3px 0px var(--dropdown-pull-down-shadow-color), 0px 0px 3px 0px var(--dropdown-pull-down-shadow-color);
    width: 160px;
    color: var(--dropdown-text-color);
  }
  .dropdown {
    position: relative !important;
    display: inline-block;
    icon {
      svg > * {
        fill: var(--version-control-flip-color) !important;
      }
    }
    &-rotate-icon {
      transform: rotate(180deg);
      padding-right: spacer(3);
    }
  }
  .dropdown-content {
    display: block;
    position: relative;
    background-color: var(--version-control-bg-color);
    border: 4px;
    z-index: 2;
    :hover {
      color: var(--dropdown-text-color);
      background-color: var(--dropdown-checkbox-hover-bg-color);
    }
  }
  .dropdown-content a {
    color: var(--version-control-text-color);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-decoration: none;
    display: block;
    text-align: center;
    width: 100%;
    border-top: 1px;
    text-align: left;
    padding: 8px 4px 8px 12px;
    justify-content: space-between;
    align-items: center;
  }
}
