sf-progress-tracker {
  .sf-progress-tracker {
    &.-parent {
      display: flex;
      user-select: none;
    }

    &.-nav {
      margin-top: auto;
      margin-bottom: auto;
      line-height: 0px;
      cursor: pointer;

      &:first-child {
        margin-right: spacer(3);
      }

      &:last-of-type {
        margin-left: spacer(3);
      }
    }

    &.-nav-placeholder {
      margin-right: spacer(6);
    }

    &.-progress {
      display: flex;
    }

    &.-bubble {
      width: spacer(30);
      height: spacer(30);
      border: 3px solid var(--sf-progress-border-color);
      text-align: center;
      border-radius: spacer(42);
      position: relative;
      margin-right: 26px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        display: inline-block;
        font-weight: 700;
        font-size: 1rem;
        padding-bottom: 2px;
      }

      &.-inactive {
        cursor: unset;
        border-color: var(--sf-progress-default-border-color);
        pointer-events: none;

        span {
          color: var(--sf-progress-default-text-color);
        }
      }

      &:after {
        top: 12px;
        left: 32px;
        content: "";
        font-weight: 500;
        position: absolute;
        pointer-events: none;
        cursor: unset;
        border-bottom: 1px solid var(--sf-progress-separator-color);
        width: 16px;
      }

      &:last-of-type {
        margin-right: 0;
        &:after {
          content: "";
          border-bottom: unset;
        }
      }

      &:hover, &.active {
        background-color: var(--sf-progress-bg-color);
        span {
          color: var(--sf-progress-text-color);
        }
      }
    }
  }
}