card-product-category {
  height: 100%;
  color: var(--card-product-text-color);
  background-color: var(--card-product-bg-color);
  padding: 0;
  transition: 0.2s;
  a {
    &:hover {
      text-decoration: none;
    }
  }

  .-subtitle {
    font-weight: 500;
    font-size: 12px;
    color: var(--card-product-category-caption-color);
    line-height: 17px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  .card-product {
    icon {
      display: inline-block;
      position: relative;
      height: 36px;
      width: 36px;
    }

    &.-title {
      display: flex;
      align-items: center;
    }
    h3 {
      font-weight: 600;
      margin: 0 !important;
      margin-bottom: 8px !important;
      flex: 1;
      color: var(--card-product-category-title-color) !important;
      font-size: 30px;
      line-height: 38px;
    }
  }
  p {
    margin-bottom: 8px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
    color: var(--card-product-category-description-color);
  }
  .grid {
    margin: 0;
    padding: 0;
    justify-content: space-between;

    icon {
      margin-top: spacer(1);
      margin-right: spacer(2);
    }

    gridColumn {
      flex-direction: initial;
      width: 50%;
      flex-grow: unset;
      padding: 0;

      &.-align {
        width: auto;
      }

      app-link {
        a {
          font-size: 18px;
          line-height: 28px;
          font-weight: 600;
          color: var(--card-product-category-link-color);
          &:hover {
            color: var(--link-hover-color);
            text-decoration: var(--link-hover-color);
          }
        }
      }

      link-icon {
        a {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }
      }
    }
  }
  wpbutton {
    margin-top: spacer(42);
  }
  // &:hover {
  //   -webkit-box-shadow: 0 0 10px var(--card-product-updated-shadow-color);
  //   box-shadow: 0 0 10px var(--card-product-updated-shadow-color);
  //   transform: scale(1.01);
  //   cursor: pointer;
  // }

  @media (max-width: 575px) {
    .card-product {
      h3 {
        font-size: 1.25rem;
      }
    }
  }

  @media (min-width: $screen-md) {
    .card-product {
      display: flex;
      flex-direction: column;
      &.-title {
        display: flex;
        align-items: unset !important;
        margin-bottom: unset !important;
        gap: 8px;
      }
    }

    h3 {
      margin-left: unset !important;
      margin-bottom: 12px !important;
    }

    icon {
      margin-bottom: 15px;
    }
  }

  @media (max-width: ($screen-sm - 1px)) {
    .card-product {
      &.-title {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        margin-bottom: 8px;
      }
      h3 {
        margin-bottom: 0 !important;
        font-size: 30px !important;
      }
    }

    p {
      margin-bottom: 8px;
    }
    .grid {
      gridColumn {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: $screen-sm) and (max-width: $screen-md-sm-max) {
    .card-product {
      &.-title {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        margin-bottom: 8px;
      }
      h3 {
        margin-bottom: 0 !important;
        font-size: 30px !important;
      }
    }

    p {
      margin-bottom: 8px;
    }
    .grid {
      gridColumn {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: ($screen-md-sm-max + 1)) and (max-width: ($breakpoint-ipad-landscap - 1)) {
    .card-product {
      &.-title {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
