loader {
  .loader {
    &.-parent {
      position: fixed;
      top: 50%;
      left: 50%;
      margin-left: -60px;
      margin-top: -60px;
      font-size: 0;
      width: 120px;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 41;
    }

    img {
      width: 45px;
      height: 45px;
    }

    &.-wrapper {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 40;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--body-background-color);
    }

    &.-circle {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
    }

    &.-line--mask {
      position: absolute;
      left: 0;
      top: 0;
      width: 50%;
      height: 100%;
      overflow: hidden;
      transform-origin: 60px 60px;
      -webkit-mask-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0));
      animation: rotate 1s infinite linear;
    }

    &.-line {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      box-shadow: inset 0 0 0 2px var(--loader-color);
    }
  }
}
