dashboard-account {
  app-breadcrumbs {
    display: flex;
  }

  .d-flex {
    wp-form-question {
      width: 45%;
    }
  }

  .-link-cluster {
    display: flex;
    gap: spacer(5);
  }

  .-filler {
    width: 100vw;
    height: 90px;
  }

  .-submit-button {
    margin-top: auto;
    margin-bottom: spacer(3);
    display: flex;
    float: right;
    width: 49%;
    icon {
      // top: 1px;
      svg {
        top: 0;
        left: 0;
        & > * {
          fill: var(--button-spinner-color);
        }
      }
    }

    &:hover {
      color: $colorSupportingWhite;
      a {
        color: $colorSupportingWhite;
        icon {
          color: $colorSupportingWhite;
          svg > * {
            fill: var(--button-spinner-hover-color);
          }
        }
      }
    }
  }

  .account {
    &.-questions {
      display: flex;
      flex-direction: row !important;
      justify-content: space-between !important;
    }

    &.-question {
      width: 49%;
    }

    &.-card {
      display: flex;
      flex-direction: column;
      margin-top: 33px;
      cardBasic {
        flex: 1;

        &.-border {
          padding: spacer(3) spacer(30) spacer(5) spacer(30);
        }
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  dashboard-account {
    .account {
      &.-questions {
        display: unset;
      }
    }

    .-submit-button {
      float: unset;
    }
  }
}

@media (max-width: $screen-xs-max) {
  dashboard-account {
    .-link-cluster {
      flex-direction: column;
      gap: spacer(3);
    }
  }
}
