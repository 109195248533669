sc-project-details{
  .sc-project-details{
    &.-clanOffcNoCaps{
      text-transform: none !important;
    }
    &.-progression-buttons{
      padding:0 spacer(4);
    }
    &.-project-details-form{
      display: flex;
      column-gap: spacer(5);
    }
    &.-project-details-questions{
      flex: auto;
    }
    &.-buttons-container{
      display: flex;
    }
  }   
  p{
    color: #CCD3D5;  
  }
}
