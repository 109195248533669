.wpdh-404 {
  margin: 150px 0;
  min-height: calc(100vh - 793px);

  &__title {
    font-size: 100px;
    line-height: 50px;
    margin-top: 45px;
    color: var(--four-o-four-heading-color);
  }

  .wpdh-button {
    // @extend %wpdh-button--secondary;
  }
}
