sf-parent {
  .sf-parent {
    &.-container {
      margin: 0 spacer(4);
    }

    &.-progress-tracker {
      display: flex;
      margin-bottom: spacer(7);

      sf-progress-tracker {
        margin: 0 auto;
      }
    }

    &.-question {
      margin-bottom: spacer(7);
    }

    &.-answers {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      &.-card {
        // margin-left: spacer(3);
        margin: 0 spacer(2) spacer(3) spacer(2);

        &:first-child {
          margin-left: 0;
        }
      }

      &.-card:nth-child(1):nth-last-child(2),
      &.-card:nth-child(2):nth-last-child(1) {
        answer-tile > div {
          min-width: 300px;
        }
      }
    }

    // &.-answers > .-card:not(:only-child) {
    //   margin-bottom: 13px;
    //   sf-card-answer > div {
    //     min-width: 400px;
    //   }
    // }

    // &.-answers > .-card:first-child:nth-last-child(2),
    // &.-answers > .-card:first-child:nth-last-child(2) ~ .answers {
    //   margin-bottom: 13px;
    //   sf-card-answer > div {
    //     min-width: 400px;
    //   }
    // }

    &.-question-block {
      opacity: 1;
      transition: opacity 200ms;

      &.-fade-out {
        opacity: 0;
        transition: opacity 200ms;
      }
    }
  }
}

@media screen {
}
