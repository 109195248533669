$linkIconleftMargin: spacer(2);

link-icon {
  display: flex;
  &:hover {
    a > icon {
      margin-left: calc(#{$linkIconleftMargin} * 2);
    }
  }
  &.hideButton {
    display: none !important;
  }
  .link-icon {
    &.-parent {
      position: relative;

      &.-text {
        margin: auto 0;
      }
    }
  }
  .-link-blue-hover {
    &:hover {
      color: $colorAccentLightBlue !important;
    }
  }
  .-link-green-text {
    color: $colorPrimaryGreen;
  }

  .-link-blue-text {
    color: var(--link-icon-color);
  }

  a {
    cursor: pointer;
    display: flex;
    // font-size: inherit;
    color: var(--link-icon-text-color);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;

    icon {
      transition: margin-left 250ms;
      text-align: initial;
      margin: auto 0;
      margin-left: $linkIconleftMargin;
      svg > * {
        fill: var(--link-icon-color);
      }
      width: 30px;
      height: 14px;
    }

    &:focus {
      text-decoration: none;
      color: var(--link-icon-text-color);
      border-radius: 2px;
      padding: 1px;
      border: 1px solid var(--link-icon-focus-color);
      icon {
        svg > * {
          fill: var(--link-icon-color);
        }
      }
    }

    &:focus-visible {
      outline: none !important;
    }

    &:hover {
      text-decoration-color: var(--link-icon-text-hover-color);
      color: var(--link-icon-text-hover-color);
      icon {
        svg > * {
          fill: var(--link-icon-text-hover-color);
        }
      }
    }
  }
}
