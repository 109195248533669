card-category-product {
  color: var(--card-product-text-color);
  background-color: var(--category-bg-color);
  padding: 16px;
  transition: 0.2s;
  a {
    &:hover {
      text-decoration: none;
    }

    icon[icon='external-link'] {
      display: none;
    }
  }

  .-subtitle {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  .card-product {
    icon {
      display: inline-block;
      position: relative;
      height: 36px;
      width: 36px;
    }

    &.-title {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
    }
    h3 {
      font-weight: 600;
      margin: 0 0 4px 0 !important;
      flex: 1;
      font-size: 16px;
      line-height: 24px;
    }

    &.-header-link {
      a {
        h3 {
          color: var(--category-product-title-color) !important;
          font-size: 16px;
          line-height: 24px;
        }
        &:hover {
          h3 {
            color: var(--category-product-title-hover-color) !important;
            text-decoration: underline !important;
            text-decoration-color: var(--category-product-title-hover-color) !important;
            opacity: 0.8;
          }
        }
      }
    }
  }
  p {
    margin-bottom: 4px;
    line-height: 20px;
    font-weight: 400;
    font-size: 14px;
    color: var(--card-category-product-description-color);
  }
  .grid {
    margin: 0;
    padding: 0;
    justify-content: space-between;

    icon {
      margin-top: spacer(1);
      margin-right: spacer(2);
    }

    gridColumn {
      margin-top: spacerThree(5);
      flex-direction: initial;
      width: 50%;
      flex-grow: unset;
      padding: 0;

      &.-align {
        width: auto;
      }

      app-link {
        a {
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          color: var(--link-color);
          // &:hover {
          //   color: var(--link-hover-color);
          //   text-decoration: var(--link-hover-color);
          // }
        }
      }

      link-icon {
        a {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }
      }
    }
  }
  wpbutton {
    margin-top: spacer(42);
  }
  &:hover {
    border-radius: 12px;
    background-color: var(--card-category-product-bg-color);
    box-shadow: 4px 8px 20px 0px var(--card-category-product-shadow-color-one),
      3px 4px 6px 0px var(--card-category-product-color-two), 4px 8px 20px 0px var(--card-category-product-color-three);
    transform: scale(1.01);
    cursor: pointer;
  }
  &.-title-category-product-card {
    &:hover {
      h3 {
        text-decoration: underline !important;
        text-decoration-color: var(--link-color) !important;
      }
    }
  }

  @media (min-width: $screen-md) {
    .card-product {
      display: flex;
      flex-direction: column;
      &.-title {
        display: flex;
        align-items: unset !important;
        margin-bottom: unset !important;
      }
    }

    h3 {
      margin-left: unset !important;
      margin-bottom: 12px !important;
    }

    icon {
      margin-bottom: 15px;
    }
  }

  @media (max-width: ($screen-sm - 1px)) {
    padding: 0;
    &:hover {
      border-radius: 8px;
      box-shadow: unset;
      transform: unset;
      background: transparent;
      .card-product {
        &.-title {
          text-decoration: underline;
          text-decoration-color: var(--card-category-product-title-color);
        }
      }
    }
    .card-product {
      &.-title {
        margin-bottom: 8px;
      }
      h3 {
        margin-bottom: 0 !important;
        font-size: 16px !important;
      }
    }
    p {
      margin-bottom: 8px;
    }
  }

  @media (min-width: $screen-sm) and (max-width: $screen-md-sm-max) {
    .card-product {
      &.-title {
        margin-bottom: 8px;
      }
      h3 {
        margin-bottom: 0 !important;
        font-size: 16px !important;
      }
    }
    p {
      margin-bottom: 8px;
    }
  }

  @media (min-width: ($screen-md-sm-max + 1)) and (max-width: ($breakpoint-ipad-landscap - 1)) {
    .card-product {
      &.-title {
        margin-bottom: unset;
      }
    }
  }
}
