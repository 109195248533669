app-spec-footer {
  display: flex;
  .-footer-left-nav {
    width: 300px;
    background: var(--spec-page-left-bg-color);
    border-right: 1px solid var(--spec-page-left-separator);
  }
  .-footer-body {
    flex: 1;
  }
}

@media (max-width: $screen-tablet-md) {
  app-spec-footer {
    .-footer-left-nav {
      display: none;
    }
  }
}

@media (min-width: ($screen-tablet-md + 1)) and (max-width: $screen-md) {
  app-spec-footer {
    .-footer-body {
      app-footer {
        .-secondary {
          .-secondary-container {
            flex-direction: column;
            flex-wrap: nowrap;
            flex: 1;
            .-card {
              width: 100%;
            }
            .-contact-bottom {
              border-bottom: 0.0625rem solid $colorAccentDarkTeal;
            }
          }
        }
      }
    }
  }
}
