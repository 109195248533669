.-content-link {
  a {
    color: var(--link-color);

    icon {
      margin-left: spacer(2);
    }

    &:hover {
      color: var(--link-hover-color);
      text-decoration: underline;
    }
  }
}
