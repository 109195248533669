.-theme {
  height: 60px;
  width: 18px;

  svg {
    top: 4px;
  }

  svg > * {
    cursor: pointer;
  }
}

@media (max-width: $screen-mobile-large-max) {
  .-theme {
    height: 40px;
  }
}
