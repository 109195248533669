workflow-card {
  .workflow-card {
    &.-card {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: spacer(1);
    }

    &.-status {
      flex-direction: column;
    }

    &.-content-title {
      margin-bottom: spacer(5);
      color: var(--go-live-head-text-color);
    }

    &.-content-description-width {
      margin-bottom: spacer(4);
    }

    &.-spacer {
      border-left: $hairline solid var(--go-live-workflow-border-left);
      height: 100%;
      margin: spacer(2) 0 spacer(2) spacer(3);
    }

    &.-content {
      margin: 0 0 0 spacer(4);
      width: 100%;

      &.-bottom-padding {
        padding-bottom: spacer(30);
      }

      link-icon {
        a {
          color: var(--body-text-color);
          &:focus,
          &:hover {
            color: var(--body-text-color);
          }
        }
      }
    }

    &.-content-title:empty,
    &.-content-description:empty {
      display: none;
    }

    &.-childrenContainer {
      overflow: hidden;
    }
  }

  &.-lastChild {
    .wpdh-workflowCard {
      &.-spacer {
        border-left: 0;
        border-top: 0;
      }
    }
  }
}

@media (min-width: $screen-md) {
  workflow-card:not(.-vertical) {
    .workflowCard {
      &.-card {
        flex-direction: column;
        margin-bottom: spacer(4);
        padding: 0 spacer(4) 0 0;
        width: 16rem;
      }

      &.-status {
        flex-direction: row;
      }
      &.-spacer {
        border-left: 0;
        border-top: $hairline solid $colorSupportingWhite;
        width: 100%;
        margin: spacer(3) 0 0 spacer(3);
      }

      &.-content {
        margin: spacer(4) 0 0 0;

        p {
          max-width: 10rem;
        }
      }
    }
  }
}
