related-articles {
  border-bottom: unset;
  padding-top: 24px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--doc-page-section-border-color);
  gap: 8px;
  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0px;
    margin: 0;
  }
  div {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 72px;
    span {
      padding: 0;
      width: 27.3%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      a {
        color: var(--related-link-color);
        &:hover {
          color: var(--related-link-color);
          text-decoration-color: var(--related-link-color);
        }
      }
    }
  }
}

@media (max-width: ($screen-sm - 1px)) {
  related-articles {
    div {
      flex-direction: column;

      span {
        width: 100%;
        &:nth-of-type(2) {
          display: none;
        }
        &:nth-of-type(3) {
          display: none;
        }
      }
    }
  }
}

@media (min-width: $screen-sm) and (max-width: ($screen-lg - 1)) {
  related-articles {
    div {
      gap: 8px;
      span {
        width: 32.5%;
      }
    }
  }
}
