tab-container {
  // padding: 1.2rem;
  list-style: none;
  display: inline-block;
  border-radius: 0px;
  position: relative;
  width: 100%;

  .tab-content {
    position: relative;
    padding: 42px 28px 28px;
    overflow: hidden;
    overflow-x: auto;
    .-container {
      padding: 0;
    }
    &:focus {
      outline: 0;
      background-color: rgba(black, 0.1);
    }

    &.-no-side-padding {
      padding-left: 0;
      padding-right: 0;
    }
  }

  // Secondary tab style
  &.-secondary {
    .tab-content {
      padding: spacer(5) 0 0 0;

      > div > tab-item > .tab-contents {
        &.is-active {
          animation-iteration-count: 1;
          animation: fade 200ms;
          animation-direction: alternate;
        }
      }
    }

    ul.tab-nav {
      li {
        border-bottom: 0;
        margin: 0 spacerThree(7);
        padding: 0 0 spacerThree(2) 0;

        &:first-of-type {
          margin-left: 0;
        }

        button {
          padding: 0;
          // font-weight: 700;
          text-transform: uppercase;
        }

        &.is-active,
        &:hover {
          color: var(--tab-container-active-text-color) !important;
          border-bottom: 2px solid var(--tab-container-active-underline-color) !important;
        }
      }
    }
  }

  // Default
  ul.tab-nav {
    display: flex;
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;
    overflow-x: auto;
    padding-bottom: spacer(1);

    /* width */
    &::-webkit-scrollbar {
      width: spacer(1);
      height: spacer(1);
    }

    /* Track */
    &::-webkit-scrollbar-track {
      // box-shadow: inset 0 0 5px grey;
      border-radius: 14px;
      background-color: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $colorTintPrimaryBlackT60;
      border-radius: spacer(2);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $colorTintPrimaryBlackT80;
    }

    li {
      &::before {
        opacity: 0;
      }

      border-bottom: 2px solid transparent;
      border-bottom-color: var(--tab-block-default-border-color);

      &.is-active {
        color: var(--tab-block-active-color) !important;
        border-bottom-color: var(--tab-block-active-border-color) !important;
      }
      &:hover {
        color: var(--tab-block-active-color);
        border-color: var(--tab-block-active-border-color);
      }
    }
    li.-spaceBetweenFlex {
      flex: 1;
    }
    &.-spaceBetween {
      justify-content: space-between;
      flex-direction: row;
      align-items: stretch;
    }
    button {
      @include button-reset;
      padding: spacer(3) spacer(5);
      padding-bottom: 0.5rem;
      text-align: center;
      color: var(--tab-container-text-color);
      width: 100%;
      font-weight: var(--tab-container-text-weight);

      &.is-active {
        color: var(--tab-container-active-text-color) !important;
        font-weight: var(--tab-container-active-text-weight);
      }
      &:hover {
        color: var(--tab-container-active-text-color);
      }
    }
  }

  @keyframes animate-in {
    25% {
      transform: none;
      opacity: 1;
    }
    100% {
      transform: none;
      opacity: 0;
    }
  }
}

// @media (min-width: $screen-lg-min) {
//   .wpdh-right-content {
//       .wpdh-tab-block {
//           &__content {
//               margin-top: 0;
//               margin-bottom: 0;
//               animation: fadeIn 0.5s ease 1 forwards
//           }
//       }
//       ul.wpdh-tab-block__nav {
//           position: absolute;
//           top: 0;
//           left: 100%;
//           width: 100%;
//           padding: 0 25px;
//           &.is-fixed {
//               position: fixed;
//               top: $dimension-nav-height;
//               width: auto;
//               right: 0;
//               left: calc( (100% - #{$dimension-sidebar-width}) / 2+#{$dimension-sidebar-width});
//               z-index: $layer-tabblock-nav;
//           }
//       }
//   }
// }
