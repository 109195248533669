workflow {
  .workflow {
    flex-direction: column;
    margin-top: spacerThree(7);
  }
}

@media (min-width: $screen-sm-max) {
  workflow:not(.-vertical) {
    .workflow {
      flex-direction: row;
      margin-top: spacer(6);
    }
  }
}
