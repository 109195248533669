article-image-card {
  .article-image-card {
    &.-container {
      padding: 0;
      width: 100%;
      display: flex;
      position: relative;
      .-content-image {
        width: 100%;
        overflow: hidden;
        border-radius: spacer(2);
        img {
          width: 100%;
          height: 100%;
          transition: 0.6s;
          object-fit: cover;
          border-radius: spacer(2);
        }
      }
      .-content-info {
        transition: 0.6s;
        border-radius: spacer(2);
        display: flex;
        position: absolute;
        flex-direction: column;
        color: var(--article-image-card-text-color);
        background: var(--article-image-card-container-color);
        border: $hairline solid var(--article-image-content-card-border-color);
        pill {
          span {
            margin-bottom: spacerThree(5);
          }
        }
        label {
          margin-bottom: spacerThree(1);
          color: var(--article-image-card-caption-color);
        }
        h3 {
          margin-top: 0;
          max-height: spacer(12);
          margin-bottom: spacerThree(3);
          @extend .-fontDisplaySourceSan;
          font-size: 1.5rem;
          line-height: spacerThree(11);
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          text-transform: unset;
          color: var(--article-image-card-title-color);
        }
        p {
          font-weight: 400;
          height: 66px;
          font-size: 16px;
          line-height: spacerThree(10);
          margin-bottom: spacerThree(6);
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }
        link-icon {
          display: flex;
          flex-grow: 1;
          align-items: flex-end;
          a {
            font-weight: 700;
            icon {
              transition: 0.6s;
            }
          }
        }
      }
      &.-landscape {
        height: 400px;
        align-items: center;
        .-content-image {
          height: 100%;
          img {
            width: 70%;
          }
        }
        .-content-info {
          right: 0;
          width: 45%;
          padding: spacerThree(11);
          height: 362px;
          link-icon {
            a {
              font-size: $font-size-md;
              line-height: spacer(5);
            }
          }
        }
        &:hover {
          .-content-info {
            transform: translate(-0.5rem, 0);
          }
        }
      }
      &.-portrait {
        margin-bottom: 8rem;
        align-items: center;
        flex-direction: column;
        .-content-image {
          height: 18.75rem;
        }
        .-content-info {
          width: 93%;
          bottom: -8rem;
          padding: spacer(5);
          position: absolute;
          height: 338px;
          pill {
            span {
              margin-bottom: spacer(3);
            }
          }
          p {
            line-height: 1.35rem;
          }
          link-icon {
            a {
              font-size: $font-size-sm;
              line-height: 1.12rem;
            }
          }
          &.-events {
            height: 204px;
            p {
              height: auto;
              -webkit-line-clamp: unset;
            }
          }
        }
        &:hover {
          .-content-info {
            transform: translate(0, -0.5rem);
          }
        }
      }
      &:hover {
        .-content-image {
          img {
            transform: scale(1.05);
          }
        }
        .-content-info {
          link-icon {
            a {
              icon {
                transform: translate(0.5rem);
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  article-image-card {
    .article-image-card {
      &.-container {
        width: 100%;
        background: none;
        position: relative;
        flex-direction: column;
        height: unset !important;
        margin-bottom: unset !important;
        .-content-image {
          width: 100% !important;
          height: 8.25rem !important;
          display: flex !important;
          border-bottom-left-radius: unset !important;
          border-bottom-right-radius: unset !important;
          img {
            width: 100% !important;
            border-bottom-left-radius: unset !important;
            border-bottom-right-radius: unset !important;
          }
        }
        .-content-info {
          top: 0 !important;
          bottom: 0 !important;
          width: 100% !important;
          height: unset !important;
          padding: spacer(5) !important;
          position: relative !important;
          border-top-left-radius: unset !important;
          border-top-right-radius: unset !important;
        }
        &:hover {
          .-content-info {
            transform: unset !important;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-md) and (max-width: $screen-laptop-big) {
  article-image-card {
    .article-image-card {
      &.-container {
        &.-portrait {
          .-content-info {
            &.-events {
              height: 230px;
            }
          }
        }
      }
    }
  }
}
