header-tabs {
  .-container {
    display: flex;
  }

  .-columns {
    width: 100%;
    max-width: 232px;
    margin-right: spacer(5);

    h4 {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: spacer(5);
      color: var(--tab-item-heading-color);
    }

    h5 {
      font-weight: 700;
      margin: spacer(30) 0 0 0;
      color: var(--tab-item-heading-color);
    }

    p {
      font-size: 14px;
      line-height: 18px;
    }

    p.-pdf {
      font-size: 8px;
      line-height: 0;
      margin: spacer(1) 0 0 spacer(1);
      color: var(--body-text-color);
      border: 1px solid $colorSupportingGray;
      padding: calc(#{spacer(1)} + 2px);
      border-radius: spacer(1);
    }

    app-link {
      display: inline;

      div {
        line-height: spacer(5);

        a {
          color: var(--tab-item-link-color);
          line-height: 26px;

          &:hover {
            color: var(--tab-item-link-hover-color);
          }
        }
      }
    }
  }
}
