testimonial-rebranding {
  padding: spacer(5) spacer(54);
  display: flex;
  flex-direction: column;
  background-color: var(--testimonial-bg-color);
  border-radius: spacerThree(3);
  gap: spacer(3);
  color: $colorSupportingWhite;
  position: relative;
  icon {
    position: absolute;
    z-index: 0;
    top: 18px;
    left: 18px;
    width: 48px;
    height: 48px;
  }

  h3 {
    color: $colorSupportingWhite !important;
    font-size: 35px;
    line-height: 53.5px;
    position: relative;
    q {
      &::before {
        opacity: 0;
        position: absolute;
      }
    }
  }
  p {
    font-size: 24px;
    font-weight: 700;
    position: relative;
    .-identifier {
      color: var(--testimonial-identifier-color);
      margin-right: spacer(5);
      display: inline-block;
      rotate: 270deg;
      position: relative;
      left: 8px;
    }

    .-author {
      display: inline-flex;
      flex-direction: column;
      gap: 6px;
    }

    .-author-role {
      font-size: 18px;
      font-weight: 100;
    }
  }
}

@media (max-width: $breakpoint-big-mobile) {
  testimonial-rebranding {
    icon {
      width: 24px;
      top: 12px;
      left: 20px;
    }
    h3 {
      font-size: 18px;
      line-height: 22.6px;
      font-weight: 400;
    }
    p {
      font-size: 18px;
      font-weight: 700;
      .-author {
        gap: 0px;
      }
      .-author-role {
        font-size: 16px;
      }
    }
  }
}

@media (min-width: $screen-mobile-large) and (max-width: $screen-mobile-large-max) {
  testimonial-rebranding {
    icon {
      width: 24px;
      top: 12px;
      left: 20px;
    }
    h3 {
      font-size: 18px;
      line-height: 22.6px;
      font-weight: 400;
    }
    p {
      font-size: 18px;
      font-weight: 700;
      line-height: 18px;
    }
  }
}

@media (min-width: $screen-tablet) and (max-width: $screen-laptop-big) {
  testimonial-rebranding {
    icon {
      width: 36px;
      top: 16px;
      left: 16px;
    }
    h3 {
      font-size: 30px;
      line-height: 36px;
    }
  }
}
