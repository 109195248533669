app-api-versions {
  .version-button {
    background-color: $colorShadePrimaryBlackS20;
    border: $hairline solid $colorSupportingWhite;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 1rem;
    font-weight: 700;
    color: $colorSupportingWhite;
    padding: spacer(3) 0 spacer(3) spacer(3);
    width: 160px;
    text-align: left;
    outline: none;
    display: flex;
  }
  .-icon-padding {
    margin-left: 60px;
  }
  .dropdown-wrapper {
    position: absolute;
  }
  .dropdown {
    position: relative !important;
    display: inline-block;
  }
  .dropdown-content {
    display: block;
    position: relative;
    background-color: $colorShadePrimaryBlackS20;
    z-index: 2;
    :hover {
      color: $colorShadePrimaryBlackS20;
      background-color: $colorSupportingWhite;
    }
  }
  .dropdown-content a {
    color: $colorSupportingWhite;
    font-size: 1rem;
    text-decoration: none;
    display: block;
    border: 2px solid $colorSupportingWhite;
    text-align: center;
    width: 160px;
    border-top: 1px;
    text-align: left;
    padding: spacer(3) 0 spacer(3) spacer(3);
  }

  .version-parent {
    // float:right;
    // position:absolute;
    // right:2rem
  }
  .version-text {
    width: 100%;
    margin: 0 auto 0 auto;
    font-weight: bold;
  }
}
