reference-card {
  padding-top: 32px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  border-top: 8px solid;
  border-top-color: #3bcff0;
  border-radius: 8px;
  box-shadow: 0px 4px 5px 0px #0000000d, 0px 1px 3px 0px #0000000d, 0px 0px 3px 0px #0000000d;
  transition: 0.2s;
  background-color: var(--reference-cards-bg-color);

  height: 178px;
  .base-card {
    &.-title-container {
      display: flex;
      margin-bottom: 16px;
      align-items: center;
      gap: 6px;
      icon {
        width: 21px;
        height: 21px;
      }
      h3 {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        flex: 1;
        color: var(--reference-cards-title-color) !important;
        margin: unset;
      }
    }
  }
  p {
    color: var(--reference-cards-description-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  a {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--reference-cards-link-color);

    &:hover {
      text-decoration: none;
    }
  }
  ul,
  ol {
    padding-inline-start: 20px;
    margin-top: spacer(4);
    li::marker {
      color: var(--base-card-list-marker-color);
    }
  }

  &:hover {
    border-radius: 8px;
    box-shadow: 0px 3px 10px 0px #1b1b6f0d, 0px 4px 6px 0px #1b1b6f0d, 0px 4px 6px 0px #1b1b6f0d;
    transform: scale(1.01);
    cursor: pointer;
  }
}

@media (max-width: $screen-xs-max) {
  reference-card {
    padding: 24px 16px 16px 16px !important;
  }
}

@media (min-width: $screen-sm) and (max-width: $breakpoint-sm-desktop-max) {
  reference-card {
    padding: 24px 16px 16px 16px !important;
    icon {
      width: 15px !important;
      height: 15px !important;
    }
    h3 {
      font-size: 18px !important;
      line-height: 28px !important;
    }
  }
}
