dashboard-credentials {
  .credentials {
    &.-credential-heading {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .-credential-header {
        margin-top: 0 !important;
      }

      .-credential-blurb {
        display: flex;
        justify-content: space-between;
        padding-top: spacer(1);
      }
    }

    &.-api-table-container {
      margin-top: 33px;
      engineTable {
        width: 100%;
        .-icon-color {
          color: var(--copy-icon-color);
        }
      }

      .-tripos-express-table {
        margin-bottom: spacer(42);
      }

      .-bass-table {
        margin-bottom: spacer(42);
      }
    }
  }
}
