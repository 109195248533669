card-product-small {
  height: 100%;
  color: var(--card-product-text-color);
  border: 1px solid var(--card-product-border-color);
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  background-color: var(--card-product-small-bg-color);
  box-shadow: 0px 4px 5px 0px var(--card-product-small-shadow-color-one),
    0px 1px 3px 0px var(--card-product-small-shadow-color-two),
    0px 0px 3px 0px var(--card-product-small-shadow-color-three);

  border-top-width: 8px;
  border-radius: 8px;
  padding: 24px 16px 40px 16px;
  transition: 0.2s;
  position: relative;

  a {
    &:hover {
      text-decoration: none;
    }
    &:focus {
      text-decoration: none;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 18px;
    margin: 0 0 0 spacer(4);
    flex: 1;
    color: var(--card-product-small-title-color) !important;
    line-height: 28px;
    height: 56px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  p {
    margin-bottom: 16px;
    line-height: 24px;
    font-weight: 400;
    color: var(--card-product-small-text-color);
  }
  .grid {
    margin: 0;
    padding: 0;
    justify-content: space-between;
    position: absolute;
    bottom: 16px;

    gridColumn {
      flex-direction: initial;
      width: 50%;
      flex-grow: unset;
      padding: 0;

      &.-align {
        width: auto;
      }

      app-link {
        a {
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          color: var(--card-product-small-link-color);
        }
      }
    }
  }

  &.-borderTopColorPurple {
    border-top-color: var(--card-product-small-border-top-purplecolor);
  }

  &.-borderTopColorLightPurple {
    border-top-color: var(--card-product-small-border-top-purple-color);
  }

  &.-borderTopColorLightOrange {
    border-top-color: var(--card-product-small-border-top-orange-color);
  }

  &.-borderTopColorIndigo {
    border-top-color: var(--card-product-small-border-top-indigo-color);
  }

  &.-borderTopColorLightBlue {
    border-top-color: var(--card-product-small-border-top-blue-color);
  }

  wpbutton {
    margin-top: spacer(42);
  }
  &:hover {
    box-shadow: 0px 8px 20px 0px var(--card-product-small-shadow-hover-color-one),
      0px 4px 12px 0px var(--card-product-small-shadow-hover-color-two),
      0px 1px 0px 0px var(--card-product-small-shadow-hover-color-three);

    transform: scale(1.02);
  }

  @media (max-width: ($screen-sm - 1px)) {
    h3 {
      margin: 0;
      margin-bottom: 8px;
      height: unset;
    }
    p {
      margin-bottom: 8px;
    }

    .grid {
      gridColumn {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: $screen-sm) and (max-width: ($screen-lg-desktop - 1px)) {
    .card-product {
      display: flex;
      flex-direction: column;
      &.-title {
        display: flex;
        align-items: unset !important;
        margin-bottom: unset !important;
      }
    }

    h3 {
      margin-left: unset !important;
      margin-bottom: 16px !important;
      height: unset;
    }

    icon {
      margin-bottom: 15px;
    }
  }

  @media (min-width: $screen-lg-desktop) {
    .card-product {
      display: flex;
      flex-direction: column;
      &.-title {
        display: flex;
        align-items: unset !important;
        margin-bottom: unset !important;
      }
    }

    h3 {
      margin-left: unset !important;
      margin-bottom: 16px !important;
    }

    icon {
      margin-bottom: 15px;
    }
  }
}
