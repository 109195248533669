pill-country {
  display: inline-block;
  @include colorClasses('span', 'background-color');

  .-pill {
    border-radius: 12px;
    color: var(--pill-country-text-color);
    background-color: var(--pill-country-bg-color);
    border: 1px solid var(--pill-line-color);
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    user-select: none;
    display: flex;
    padding: 2px 8px;
    align-items: center;
    gap: 4px;

    span {
      width: 12px;
      display: flex;
      img {
        width: 100%;
      }
    }

    icon {
      display: none;

      svg > * {
        fill: var(--pill-text-color);
      }
    }

    &.-canclick {
      cursor: pointer;

      icon {
        display: inline-block;
        margin-bottom: unset;
        margin-right: 4px;
        position: relative;
        top: 1px;
        width: 12px;
        height: 12px;
      }
    }
  }

  &.-colorAccentLightBlue {
    span {
      background-color: var(--pill-bg-color) !important;
    }
  }
}
