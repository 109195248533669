search-pager {
  .pager {
    // @include list-reset;
    display: flex;
    justify-content: center;

    &.-button {
      @include button-reset;
      width: 25px;
      color: var(--search-text-color);
      border-bottom: 2px solid transparent;
      padding-bottom: spacer(2);
      margin: 0 spacer(2);

      &:hover {
        color: var(--search-pager-hover-text-color);
      }

      &.is-active {
        color: var(--search-pager-active-color);
        border-bottom-color: var(--search-pager-active-color);
      }

      &--previous,
      &--next,
      &--first,
      &--last {
        // @include hide-text;
        background-repeat: no-repeat;
        background-position: center top 4px;
        background-size: auto 13px;
      }

      &--previous {
        background-image: url('#{$path-icons}/chevron-left-grey-dark.svg');

        &:hover {
          background-image: url('#{$path-icons}/chevron-left-red.svg');
        }
      }

      &--next {
        background-image: url('#{$path-icons}/chevron-right-grey-dark.svg');

        &:hover {
          background-image: url('#{$path-icons}/chevron-right-red.svg');
        }
      }

      &--first {
        background-image: url('#{$path-icons}/double-chevron-left-grey-dark.svg');

        &:hover {
          background-image: url('#{$path-icons}/double-chevron-left-red.svg');
        }
      }

      &--last {
        background-image: url('#{$path-icons}/double-chevron-right-grey-dark.svg');

        &:hover {
          background-image: url('#{$path-icons}/double-chevron-right-red.svg');
        }
      }
    }

    &.-excess-icon {
      color: var(--search-pager-page-dots-color);
      opacity: 0;

      &.is-active {
        opacity: 1;
      }
    }
  }

  ul.pager {
    padding-left: 0;

    li {
      margin: 0;
      list-style-type: none;
      padding-left: 0;

      icon {
        svg > * {
          fill: var(--search-pager-navigation-icon-color);
        }
        &:hover {
          svg > * {
            fill: var(--search-pager-navigation-hover-icon-color);
          }
        }
      }

      &::before {
        display: none;
      }
    }
  }
}
