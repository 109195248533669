dropdown-convergence {
  .dropdown {
    &.-parent {
      position: relative;
      display: inline-block;
      width: 450px;
      height: spacer(42);

      &.-lg {
        width: 312px;
      }
    }
    &.-header {
      display: flex;
      justify-content: space-between;
      background-color: var(--convergence-dropdown-bg-color);
      border-radius: spacer(1);
      cursor: pointer;
      border: 1px solid var(--convergence-dropdown-border-color);

      .-button {
        padding: spacer(3) spacer(4);
        border: none;
        user-select: none;
        font-weight: 600;
      }

      icon {
        float: right;
        margin: auto spacer(4);
        height: 13px;
        width: 13px;

        svg > * {
          fill: var(--convergence-dropdown-icon-color);
        }

        svg {
          margin-right: 0;
        }
      }
    }

    &-rotate-icon {
      transform: rotate(180deg);
    }

    &-active {
      border-radius: 0px !important;
      border-top-left-radius: spacer(1) !important;
      border-top-right-radius: spacer(1) !important;
    }

    &.-content {
      width: 100%;
      position: absolute;
      background-color: var(--convergence-dropdown-bg-color);
      z-index: 3;
      border-bottom-left-radius: spacer(1);
      border-bottom-right-radius: spacer(1);
      overflow: hidden;
      border: 1px solid var(--convergence-dropdown-border-color);
    }

    &.-content-option {
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      cursor: pointer;
      user-select: none;
      background: var(--convergence-dropdown-bg-color);
      font-size: 18px;
      line-height: 22px;
      &:hover {
        background-color: var(--convergence-dropdown-bg-hover-color);
      }
    }
    &.-multiselect {
      position: relative;
      display: inline-block;
      max-width: 270px;
      height: spacerThree(45);
      .content-padding {
        padding: 16px 16px 32px;
      }
      .-header-sort {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $colorShadePrimaryBlackS20;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        line-height: 15px;
        padding: spacerThree(5);
        cursor: pointer;
        border: 1px solid $colorAccentLightBlue;
        .-button {
          border: none;
          user-select: none;
        }
      }
      .-content-sort {
        width: 100%;
        background-color: $colorPrimaryBlack;
        border-radius: 4px;
        overflow: hidden;
        position: absolute;
        z-index: 9999;
      }
      .-group-element {
        cursor: pointer;
        display: flex;
        position: relative;
        user-select: none;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        padding: 12px 16px;
        align-items: center;
      }
      .-content-option-sort {
        text-decoration: none;
        display: block;
        cursor: pointer;
        user-select: none;
        height: 48px;
        min-width: 100px;
      }
      .-content-option-sort:hover {
        background: $colorAccentBlueShade;
        color: $colorAccentLighterBlue;
        cursor: pointer;
        border-radius: 5px;
      }
    }
  }
}

@media (max-width: ($screen-mobile-large - 0.5)) {
  dropdown-convergence {
    .dropdown {
      &.-parent {
        width: 100%;
      }

      &.-multiselect {
        .-header-sort {
          div:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }
}
