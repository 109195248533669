// Prism overrides

$prism-color1: $colorAccentLightOrange;
// Object Value Number
$prism-color2: $colorAccentLightPurple;
// Object Property
$prism-color3: $colorWpLightPurple;
// Punctiation
$prism-color4: $colorAccentLightWhite;
// Object Value String
$prism-color5: $colorAccentSunglow;

.prettyprint {
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal; 

  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;

  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  color: $prism-color4;

  code[class*="language-"],
  pre[class*="language-"] {
    color: $prism-color4;
    background: none;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
  
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
  
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
  }
  
  /* Code blocks */
  pre[class*="language-"] {
    padding: 1em;
    margin: 0.5em 0;
    overflow: auto;
    border-radius: 0.3em;
  }
  
  /* Inline code */
  :not(pre) > code[class*="language-"] {
    padding: 0.1em;
    border-radius: 0.3em;
    white-space: normal;
  }
  .token.comment {
    color: $colorTintPrimaryBlackT60;
  }

  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: $prism-color3;
  }
  
  .token.punctuation,
  .token.operator {
    color: var(--code-block-punctuation-color);
  }
  
  .token.property,
  .token.tag,
  .token.constant,
  .token.symbol,
  .token.deleted {
    color: var(--code-block-property-color);
  }
  
  .token.boolean,
  .token.number {
    color: var(--code-block-punctuation-color);
  }
  
  .token.selector,
  .token.attr-name,
  .token.string,
  .token.char,
  .token.builtin,
  .token.inserted {
    color: var(--code-block-selector-color);
  }
  
  .token.entity,
  .token.url,
  .language-css .token.string,
  .style .token.string,
  .token.variable {
    color: $prism-color2;
  }
  
  .token.atrule,
  .token.attr-value,
  .token.function {
    color: $prism-color1;
  }
  
  .token.keyword {
    color: $prism-color2;
  }
  
  .token.regex,
  .token.important {
    color: $prism-color1;
  }
  
  .token.important,
  .token.bold {
    font-weight: bold;
  }
  
  .token.italic {
    font-style: italic;
  }
  
  .token.entity {
    cursor: help;
  }
  
  @media screen and (-ms-high-contrast: active) {
    code[class*="language-"],
    pre[class*="language-"] {
      color: windowText;
      background: window;
    }
  
    :not(pre) > code[class*="language-"],
    pre[class*="language-"] {
      background: window;
    }
  
    .token.important {
      background: highlight;
      color: window;
      font-weight: normal;
    }
  
    .token.atrule,
    .token.attr-value,
    .token.function,
    .token.keyword,
    .token.operator,
    .token.selector {
      font-weight: bold;
    }
  
    .token.attr-value,
    .token.comment,
    .token.doctype,
    .token.function,
    .token.keyword,
    .token.operator,
    .token.property,
    .token.string {
      color: highlight;
    }
  
    .token.attr-value,
    .token.url {
      font-weight: normal;
    }
  }
}