app-search-view {
  .search {
    &.-results-controls {
      display: flex;
      position: relative;
      align-items: stretch;
      flex-direction: column;
  
      &::after {
        content: '';
        width: 100%;
        height: 2px;
        background-color: var(--search-result-border-bottom-color);
        left: 0;
        bottom: 0;
        position: absolute;
      }
    }

    &.-results-filters {
      @include list-reset;
      display: flex;
      overflow-x: auto;
      flex-grow: 1;
      justify-content: flex-end;
  
      > * {
        flex-shrink: 0;
      }
  
      li + li {
        margin-left: 30px;
      }
    }

    &.-results-filter {
      @include button-reset;
      padding-bottom: 9px;
      position: relative;
      color: $colorSupportingWhite;

      &:hover {
        color: $colorTintTealT70;
      }
  
      &::after {
        content: '';
        background-color: transparent;
        width: 100%;
        bottom: 0px;
        left: 0;
        height: 2px;
        display: inline-block;
        position: absolute;
        z-index: 1;
      }
  
      &.is-active {
        color: $colorPrimaryGreen;
  
        &::after {
          background-color: $colorPrimaryGreen;
        }
      }
    }

    &.-no-results {
      text-align: center;
      margin: 50px 0;
      color: var(--search-text-color);
    }

    &.-pager-wrapper {
      padding-top: spacer(6);
      justify-content: center;
    }

    &.-result {
      position: relative;
      border-bottom: 1px solid var(--search-result-border-bottom-color);
      padding: spacer(4) 0;
  
      > * {
        margin: spacer(2) 0;
      }
      
      em {
        background-color: var(--search-page-highlight-color);
        color: white;
        font-style: normal;
        padding: 0 2px;
        opacity: 0.7;
      }
  
    }

    &.-result-link {
      @include hide-text;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

@media (min-width: $screen-md-min) {
  app-search-view {
    .search {
      &.-results-controls {
        flex-direction: row;
        align-items: flex-end;
      }

      &.-results-filters {
        flex-grow: 1;
        justify-content: flex-end;
      }
    }
  }
}
