app-public {
  display: flex;
  .-right-pane {
    width: 300px;
    padding-left: spacer(3);
    doc-index {
      top: 100px !important;
    }
  }
  content-viewer {
    flex: 1;
    display: flex;
    flex-direction: column;
    link-icon {
      &:hover {
        color: $colorSupportingWhite;
      }
    }
    .-border-line {
      border-bottom: $borderWidth solid var(--public-border-line-color);
      display: block;
      padding: spacer(3) 0;
      h2 {
        font-weight: 600;
      }
    }
    .-bg-title-start-letter {
      display: flex;
      height: 36px;
      width: 36px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid var(--glossary-title-start-letter-bg-color);
      background-color: var(--glossary-title-start-letter-bg-color);
    }
    .-title-start-letter {
      color: var(--glossary-title-start-letter-color);
    }
    h2 {
      font-size: 1rem;
    }
    p {
      margin-bottom: spacer(6);
    }
    .sec-masthead-section {
      display: flex;
      padding-bottom: spacer(42);
      .masthead-block {
        width: 70%;
        margin-right: 2%;
      }
      .sales-div {
        width: 28%;
        padding-top: spacer(3);
        .-light-font {
          color: var(--sales-section-light-font-color);
        }
        .-sales-text {
          padding-top: spacer(3);
          margin-bottom: 0;
        }
      }

      wpbutton {
        margin-top: spacerThree(10);
      }
    }

    .-api-text {
      max-width: 630px;
      font-size: $font-size;
      line-height: spacer(5);
      padding-bottom: spacerThree(11);
      margin: unset;
      color: $colorAccentCoolGray;
    }
    .-key-section {
      margin-top: spacerThree(11);
      padding-bottom: spacerThree(11);
      p {
        margin: unset;
      }
      app-link a {
        color: var(--link-color);
        text-decoration: none;
        &:hover {
          border-bottom: $hairline solid var(--glossary-page-item-hover-color);
        }
      }
      .-key-section-heading {
        font-size: spacer(5);
        line-height: spacer(30);
        font-weight: 700;
        margin: unset;
        padding-bottom: spacerThree(7);
        color: var(--pg-heading-color);
      }

      ul,
      ol {
        padding-inline-start: 20px;
        margin-top: spacer(4);

        li::marker {
          color: $colorAccentLightBlue;
        }
      }
    }
    .-fontFraudVideo {
      font-size: spacer(5);
      line-height: spacer(30);
      font-weight: 700;
    }
    grid {
      card-product {
        p {
          font-size: 16px;
          line-height: spacer(5);
          font-weight: 400;
        }
        div.card-product.-title {
          h3 {
            line-height: 24px;
            font-size: 18px;
          }
        }
      }
    }

    .-section-border {
      &:nth-child(2) {
        grid {
          gridcolumn {
            width: 32%;
          }
        }
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    content-viewer {
      .sec-masthead-section {
        flex-direction: column;
        .masthead-block {
          width: 100%;
        }
        .sales-div {
          width: 100%;
        }
      }

      .-key-section {
        padding-bottom: spacerThree(7);
      }
      grid {
        .pill-count-style,
        .pill-count-style icon div {
          width: 36px;
          display: block;
        }
        div {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          gridcolumn {
            width: 100%;
            flex-grow: 0;
            margin-bottom: spacerThree(11);
          }
        }
      }

      .-section-border {
        &:nth-child(2) {
          grid {
            gridcolumn {
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media (min-width: $screen-sm) and (max-width: $breakpoint-sm-desktop-max) {
    content-viewer {
      .-key-section {
        padding-bottom: spacerThree(7);
      }
      grid {
        .pill-count-style,
        .pill-count-style icon div {
          width: 36px;
          display: block;
        }
        div {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          gridcolumn {
            width: 49%;
            flex-grow: 0;
            margin-bottom: spacerThree(7);
            padding: 0 spacer(3);
          }
        }
      }
    }
  }

  @media (min-width: $breakpoint-desktop) {
    content-viewer {
      .-key-section {
        padding-bottom: spacerThree(7);
      }
      grid {
        .pill-count-style,
        .pill-count-style icon div {
          width: 36px;
          display: block;
        }
        div {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          gridcolumn {
            width: 32%;
            flex-grow: 0;
            margin-bottom: spacerThree(7);
          }
        }
      }
    }
  }
}

@media (max-width: $screen-laptop-sm) {
  app-public {
    .-right-pane {
      display: none;
    }
  }
}
