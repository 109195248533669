card-related {
  height: 100%;
  color: var(--card-product-text-color);
  border: 1px solid var(--card-related-box-border-color);
  background-color: var(--card-related-box-bg-color);
  border-radius: 8px;
  padding: 32px;
  box-shadow: 0px 4px 5px 0px var(--card-related-box-shadow-color-one),
    0px 1px 3px 0px var(--card-related-box-shadow-color-two), 0px 0px 3px 0px var(--card-related-box-shadow-color-three);
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  .card-product {
    &.-title {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .-text-caption {
        font-size: 12px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0.05em;
        color: var(--card-related-font-color);
      }
    }
    h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      margin: unset;
      flex: 1;
      color: var(--card-related-title-color) !important;
    }
  }
  p {
    margin-bottom: spacer(2);
    color: var(--card-related-description-color);
  }
  .grid {
    margin: 0 !important;
    padding: 0 !important;

    icon {
      margin-top: spacer(1);
      margin-right: spacer(2);
    }

    gridColumn {
      flex-direction: initial;
      width: 50%;
      flex-grow: unset;
      padding: 0;

      &.-align {
        width: auto;
      }

      app-link {
        a {
          color: var(--card-related-link-color);
          font-weight: 600;
          &:hover {
            color: var(--card-related-link-hover-color);
            text-decoration: underline;
            text-decoration-color: var(--card-related-link-hover-color);
          }
        }
      }
    }

    link-icon {
      flex-direction: column;
      a {
        color: var(--card-product-link-color);
        line-height: 24px;
        font-size: 16px;
        font-weight: 600;

        icon,
        .link-icon.-parent.-text {
          margin-top: 0;
        }

        &:hover {
          color: var(--card-product-link-hover-color);
        }
      }
    }
  }
  wpbutton {
    margin-top: spacer(42);
  }

  &:hover {
    border-radius: 8px;
    box-shadow: 0px 3px 10px 0px var(--card-related-box-shadow-hover-color-one),
      0px 4px 6px 0px var(--card-related-box-shadow-hover-color-two),
      0px 4px 6px 0px var(--card-related-box-shadow-hover-color-three);

    transform: scale(1.01);
    cursor: pointer;
    a {
      text-decoration: underline;
    }
  }

  @media (max-width: $screen-xs-max) {
    padding: 24px;
  }

  @media (min-width: $screen-sm) and (max-width: $breakpoint-sm-desktop-max) {
    padding: 24px;
  }
}
