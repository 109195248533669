.wpdh-form-element {
  margin: 16px 0;

  label {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  span {
    width: 100%;
    font-size: $font-size-sm;
    line-height: 24px;
    margin-bottom: 2px;
  }

  select {
    appearance: none;
    border-radius: 0;
    background-image: url('#{$path-icons}/chevron-thick-down-grey-dark.svg');
    background-size: 8px;
    background-repeat: no-repeat;
    background-position: center right 15px;
    padding: 14px 30px 12px 16px;

    &::-ms-expand {
      display: none;
    }

    .wpdh-dark & {
      background-image: url('#{$path-icons}/chevron-thick-down-white.svg');
    }
  }

  textarea {
    resize: vertical;
  }

  textarea,
  input[type='tel'],
  input[type='text'],
  input[type='date'],
  input[type='email'],
  input[type='number'],
  input[type='password'],
  select {
    border: 1px solid $colorTintPrimaryBlackT60;
    background-color: $colorTintPrimaryBlackT60;
    color: inherit;
    line-height: 1.5;

    &:focus {
      outline: none;
      border-color: $colorTintPrimaryBlackT80;
    }

    .wpdh-dark & {
      background-color: $colorTintPrimaryBlackT80;
      border-color: $colorTintPrimaryBlackT80;

      &:focus {
        border-color: $colorTintPrimaryBlackT80;
      }
    }
  }

  textarea,
  input[type='tel'],
  input[type='text'],
  input[type='date'],
  input[type='email'],
  input[type='number'],
  input[type='password'] {
    padding: 14px 16px 12px;
  }

  input[type='radio'],
  input[type='checkbox'] {
    display: none;

    & ~ span::before {
      content: '';
      display: inline-block;
      height: 16px;
      width: 16px;
      background-color: $colorTintPrimaryBlackT60;
      border: 1px solid #dddddd;
      box-sizing: border-box;
      margin-right: 8px;
      position: relative;
      top: 2px;
    }

    &:checked ~ span:before {
      background-color: white;
      border: 5px solid $colorAccentGreen;
    }
  }

  input[type='date'] {
    height: 52px;
  }

  input[type='submit'] {
    // @extend %wpdh-button;
    align-self: left;
    min-width: none;
  }

  input[type='radio'] {
    & ~ span::before {
      border-radius: 50%;
    }
  }

  input[type='checkbox'] {
    &:checked ~ span:before {
      border-width: 0;
      background-color: $colorAccentGreen;
      background-image: url('#{$path-icons}/tick-white.svg');
      background-size: 11px 8px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &__inner {
    display: flex;
  }

  &__help {
    opacity: 0.5;
  }

  &__option-wrapper {
    margin: 16px 0;
  }

  &--with-dropdown {
    select {
      margin-right: 11px;
    }

    input[type='tel'] {
      flex-grow: 1;
    }
  }

  &--group {
    label {
      width: auto;
      display: inline-block;
    }
  }

  &--rating {
    label span {
      color: rgba(57, 57, 57, 0.5);
      text-transform: uppercase;
      font-size: 10px;
      cursor: pointer;
    }

    input[type='radio'] {
      & ~ span {
        display: flex;
        flex-direction: column;
        align-items: center;

        &::before {
          margin-right: 0;
          margin-bottom: 5px;
          height: 40px;
          width: 40px;
          border: 0;
          background-color: transparent;
          border-radius: 0;
          background-size: 94%;
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      &:checked ~ span::before {
        border: 0;
      }

      &[value='bad'] {
        & ~ span::before {
          background-image: url('#{$path-icons}/face-bad-grey.svg');
        }

        &:checked ~ span::before {
          background-image: url('#{$path-icons}/face-bad-dark-grey.svg');
        }
      }

      &[value='poor'] {
        & ~ span::before {
          background-image: url('#{$path-icons}/face-poor-grey.svg');
        }

        &:checked ~ span::before {
          background-image: url('#{$path-icons}/face-poor-dark-grey.svg');
        }
      }

      &[value='regular'] {
        & ~ span::before {
          background-image: url('#{$path-icons}/face-regular-grey.svg');
        }

        &:checked ~ span::before {
          background-image: url('#{$path-icons}/face-regular-dark-grey.svg');
        }
      }

      &[value='good'] {
        & ~ span::before {
          background-image: url('#{$path-icons}/face-good-grey.svg');
        }

        &:checked ~ span::before {
          background-image: url('#{$path-icons}/face-good-dark-grey.svg');
        }
      }

      &[value='great'] {
        & ~ span::before {
          background-image: url('#{$path-icons}/face-great-grey.svg');
        }

        &:checked ~ span::before {
          background-image: url('#{$path-icons}/face-great-dark-grey.svg');
        }
      }
    }
  }

  &.is-disabled {
    opacity: 0.5;

    input {
      cursor: not-allowed;
    }
  }

  &.is-valid {
    input[type='tel'],
    input[type='text'],
    input[type='date'],
    input[type='email'],
    input[type='number'],
    input[type='password'],
    input[type='radio'] ~ span::before,
    input[type='checkbox'] ~ span::before,
    textarea,
    select {
      border-color: $colorAccentGreen;
    }
  }

  &.is-invalid {
    input[type='tel'],
    input[type='text'],
    input[type='date'],
    input[type='email'],
    input[type='number'],
    input[type='password'],
    input[type='radio'] ~ span::before,
    input[type='checkbox'] ~ span::before,
    textarea,
    select {
      border-color: $colorAccentRed;
    }
  }
}

span.wpdh-form-element__error {
  font-size: $font-size;
  margin-top: 3px;
  color: $colorAccentRed;
}
