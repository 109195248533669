sf-question {
  .sf-question {
    &.-parent {
      @extend .-fontMedium;
      text-align: center;
      max-width: 540px;
      margin: 0 auto;
    }

    &.-intro-text {
      @extend .-fontMedium;
      color: var(--sf-intro-text-color);
    }

    &.-question-text {
      @extend .-fontDisplaySourceSan;
      font-size: 32px;
      line-height: 48px;
      margin: 14px 0;
      font-weight: 400;
    }

    &.-secondary-text {
      font-weight: 400;
      line-height: spacer(5);
    }
  }
}