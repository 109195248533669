app-header {
  height: 64px;
  display: inline-flex;
  width: 100%;

  .-container {
    padding: 0 spacer(30);
    display: flex;
  }

  &.-secondaryNav {
    height: 70px;
    // margin-bottom: spacerThree(10);
  }
  app-link {
    width: 100%;
  }
  app-main-nav .-active,
  app-main-nav .link-nav a:hover {
    color: var(--main-nav-menu-item-color-hover);
  }
  app-main-nav .link-nav .-link-items:hover {
    cursor: pointer;
  }
  app-main-nav .link-nav .-link-items .-custom-size-icon svg {
    transition: 0.5s;
  }
  app-main-nav .link-nav .-link-items:hover .-custom-size-icon svg {
    transform: rotate(-180deg);
    transition: 0.5s;
  }
  app-main-nav .link-nav .-link-items {
    padding: 0;
  }
  a[aria-label*='MainNav'] {
    padding: spacer(5);
  }
  .header {
    min-height: 70px;
    position: relative;
    z-index: $layer-header;
    background-color: var(--header-background-color);
    z-index: 5;
    position: fixed;
    width: 100%;
    top: 0px;
    transition: background-color 0.3s ease;
    &.transparentBG {
      background-color: transparent;
    }
  }
  .-border {
    border-bottom: 1px solid var(--header-bottom-border-color);
  }
  .nav {
    height: 70px;
    &.-expand-button {
      display: grid;
      place-items: center;
      visibility: hidden;
      height: 0;
      width: 0;
      svg {
        use {
          fill: var(--header-icon-colors);
        }
      }
    }

    &.-logo-padding {
      z-index: 5;
      margin-right: spacer(5);
      a {
        &:hover,
        &:focus {
          color: $colorSupportingWhite;
        }
      }
    }

    &.-logo {
      display: grid;
      place-items: center;
      img {
        width: 100%;
        max-height: 36px;
      }
    }

    &.-search {
      flex-shrink: 0;
      justify-content: center;
      display: flex;
      justify-content: space-between;
      align-items: center;
      display: none;
    }

    &.-wrapper {
      // position: static;
      // bottom: 0;
      flex: 1;
      top: $wpdh-mobile-nav-height;
      transform: none;
      transition: transform 0.2s ease-in-out;
      display: flex;
      flex-direction: column;
      // overflow: scroll;

      &.is-open {
        transform: none;
        overflow: hidden;
      }
    }

    app-functional-nav {
      background: transparent;
    }
  }

  .-menu-holder {
    top: 70px;
    padding: spacerThree(5);
    overflow-y: auto;
    padding-bottom: spacer(96);
    button-icon {
      svg {
        use {
          fill: var(--body-text-color);
        }
      }
    }

    .-menu-wrapper {
      display: flex;
      flex-direction: column;
      line-height: 1.25rem;
      gap: spacer(5);
      .-functional-nav {
        display: none;
        gap: spacer(5);
        justify-content: flex-end;
      }
      .-menu-back {
        display: flex;
        gap: spacerThree(5);
        align-items: center;
      }
      .-menus {
        display: flex;
        flex-direction: column;
        gap: spacer(4);
        .-parent-menu-container {
          margin-top: spacer(4);
        }
        .-menu a {
          color: var(--link-color);
          &:hover {
            text-decoration: none;
          }
        }
        .-parent-menu-container {
          h5 {
            font-size: 1rem;
            font-weight: 700;
            text-transform: uppercase;
            color: $colorWpPrimaryHeadTextColor;
          }
        }
        .-child-menus {
          display: flex;
          flex-direction: column;
          margin-top: spacer(4);
          gap: spacer(4);
          .-child-menu {
            display: flex;
            justify-content: space-between;
          }
          .-child-menu-tabs {
            display: flex;
            flex-direction: column;
            h5 {
              text-transform: capitalize;
            }
            a {
              line-height: 26px;
            }
          }
        }
      }

      .-menu-content {
        display: flex;
        flex-direction: column;
        gap: spacer(4);
        h5 {
          font-weight: 700;
          line-height: 1.25rem;
          font-size: 1rem;
          text-transform: uppercase;
        }
        .-menu-head {
          font-weight: 700;
          line-height: 1.25rem;
        }
        .-menu-body {
          display: flex;
          flex-direction: column;
          gap: spacer(6);
          .-menu-column {
            display: flex;
            flex-direction: column;
            gap: spacer(4);
            .-menu-column-content {
              display: flex;
              flex-direction: column;
              gap: spacer(4);
              .-menu-column-item {
                display: flex;
                gap: spacer(3);
                app-link {
                  width: auto;
                  a {
                    color: var(--link-color);
                    &:hover {
                      color: var(--link-hover-color);
                      text-decoration: none;
                    }
                  }
                }
                p.-pdf {
                  font-size: 8px;
                  line-height: 0;
                  margin: spacer(1) 0 0 spacer(1);
                  color: $colorSupportingWhite;
                  border: 1px solid $colorSupportingWhite;
                  padding: calc(#{spacer(1)} + 2px);
                  border-radius: spacer(1);
                  height: 12px;
                }
              }
            }
          }
        }
      }
    }
  }

  .-open {
    display: flex !important;
  }

  .-close {
    display: none !important;
  }
}

@media (min-width: $screen-md-min) {
  app-header {
    .header-menu-dropdown {
      padding: spacer(5) !important;
    }
    .nav {
      // &.-wrapper {
      //   // position: static;
      //   // transform: none;
      //   // display: block;
      //   // transition: none;
      // }

      &.-search {
        position: absolute;
        border-bottom: 0;
        bottom: 0;
      }
    }
  }
}

// @media (max-width: $screen-sm-max) {
//   app-header {
//     .nav {
//       &.-menus {
//         flex-direction: column;
//         background: $colorShadePrimaryBlackS70;
//       }

//       &.-logo-padding {
//         min-width: 250px;
//         padding-left: spacer(3);
//         margin-right: spacer(6);
//       }

//       &.-logo {
//         width: 250px;
//         img {
//           width: 250px;
//         }
//       }

//       &.-search {
//         display: block;
//       }
//     }
//   }
// }

@media (max-width: ($screen-laptop-sm - 1)) {
  app-header {
    .-container {
      padding: 0 spacerThree(5);
    }
    .nav {
      &.-expand-button {
        visibility: visible;
        height: initial;
        width: initial;
      }
      // &.-menus {
      //   flex-direction: column;
      //   background: $colorShadePrimaryBlackS70;
      // }

      &.-logo-padding {
        padding-left: spacer(3);
        margin-right: spacer(3);
      }

      &.-search {
        display: block;
      }
    }
  }
}

@media (max-width: $screen-mobile-large-max) {
  app-header {
    .-moon-dark {
      margin-top: -2px;
    }

    .-menu-holder {
      .-menu-wrapper {
        .-functional-nav {
          display: flex;
          align-items: center;
          .-solution-finder {
            wpbutton.-small {
              height: unset;
              margin: 0;
              a {
                padding: 5px 18px !important;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint-medium-mobile-max) {
  app-header {
    .-container {
      padding: 0 spacerThree(3);
    }
    .nav {
      &.-expand-button {
        icon {
          height: 18px;
          width: 18px;
        }
      }
    }
  }
}
