app-forgot-password {
  site-logo {
    svg {
      g > path {
        fill: var(--site-logo-color) !important;
      }
    }
  }
  .row {
    margin-right: 0px;
    margin-left: 0px;
  }

  .-colorInfo {
    color: var(--forgot-pass-info-color);
  }

  masthead-centered .masthead-centered.-container {
    min-height: 115ch;
  }

  .inline-block {
    display: inline-block;
  }

  .valign span {
    font-size: 14px;
  }

  a,
  a:visited {
    color: var(--link-color);
  }
  a:hover {
    cursor: pointer;
    color: var(--link-hover-color);
    text-decoration-color: var(--link-hover-color);
  }
  a:focus {
    color: var(--link-focus-text-color);
    border: 1.5px solid var(--link-focus-color);
  }
  // .notification.-title {
  //   color: $colorAccentPink;
  // }
  // .notification.-container {
  //   border-color: $colorAccentPink;
  // }
  // .grey {
  //     color: #979797;
  // }

  // .col-md-offset-1 {
  //   // float: left;
  //   // float: right;
  // }

  // .login h4 {
  //     padding: 0em 20%;
  // }

  // & .valign{
  //   display: inline-block;
  //   float: none;
  //   vertical-align: middle;
  // }

  & .misplaced-identifier.-masthead {
    // padding-bottom: 5em;
    padding: 2em 2em 0.5em 2em;
  }

  .wrap {
    // max-width: 13em;
    vertical-align: middle;
    width: 29%;
  }

  // & .form .-question label {
  //     margin-bottom: 5px;
  //     margin-top:10px;
  //   }

  p:last-child {
    padding-bottom: 5%;
  }

  .nav {
    z-index: 100;
    position: absolute;
    padding: 2em;
  }

  .misplaced-identifier .line {
    overflow: hidden;
    padding: 0em 20%;
  }
  .line:before,
  .line:after {
    background-color: var(--login-separator-color);
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }
  .line:before {
    right: 1em;
    margin-left: -50%;
  }
  .line:after {
    left: 1em;
    margin-right: -50%;
  }

  masthead .masthead.-container {
    min-height: 50em;
    width: 100%;
  }

  .main-icon {
    display: inline-block;
    border-radius: 50%;
    border: 3.5px solid #fff;
    padding: 1.5em;
    margin: 2em 2em;
  }

  .misplaced-identifier {
    // h2:first-of-type {
    //   margin-bottom: 0;
    // }

    h2:last-child {
      margin-top: 0;
    }

    &.-cols {
      display: flex;
      margin: auto;
    }

    &.-row {
      width: 100%;
    }

    &.-submit-button-wrapper {
      display: flex;
      justify-content: center;
      margin-top: spacer(4);
      icon {
        top: 1px;
        svg {
          top: 0;
          left: 0;
          & > * {
            fill: var(--button-primary-text-color);
          }
        }
      }
    }

    & wpbutton a {
      padding: 0 0 0 0;
      display: block;
    }

    &.-submit-button {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      background-color: var(--button-primary-background-color);
      border: unset;
      border-radius: 8px;
      color: var(--button-primary-text-color);
      margin: 0 6px 22px 0;
      user-select: none;
      display: inline-block !important;
      transition: 0.3s;
      width: 66%;
      text-align: center;
      padding: 6px 12px;
      height: 38px;

      &:hover {
        background-color: var(--button-primary-background-hover-color);
        color: var(--button-primary-text-color);
        a {
          color: var(--button-primary-text-color);
        }
        icon {
          color: var(--button-primary-text-color);
          svg > * {
            fill: var(--button-primary-text-color);
          }
        }
      }

      &:focus {
        box-shadow: 0px 0px 0px 3px var(--button-primary-focus-border-color),
          0px 0px 0px 2px var(--button-primary-focus-border-color-two);
      }
    }

    &.-filler {
      margin-bottom: 24%;
    }

    &.-masthead-confirmation {
      padding-top: 140px;
    }

    &.-confirmation {
      height: 0;
      overflow: hidden;
    }

    &.-failure {
      color: $colorAccentPink;
      text-align: center;
    }
    .notification.-description {
      font-size: 13px !important;
    }
    .notification.-title {
      font-weight: 700;
    }
    &.-question {
      margin-left: auto;
      margin-right: auto;
      width: 66%;
    }
    ::-webkit-input-placeholder {
      color: $colorAccentLightGray;
    }
    ::-moz-placeholder {
      color: $colorAccentLightGray;
    }
    ::-ms-placeholder {
      color: $colorAccentLightGray;
    }
    ::placeholder {
      color: $colorAccentLightGray;
    }
  }

  .-main-padding {
    padding: spacer(7) 18% spacer(9) 18%;
  }

  label {
    margin: 0;
  }
}

@media (max-width: $screen-sm-max) {
  app-forgot-password {
    .misplaced-identifier {
      &.-submit-button {
        padding: calc(12px - 0.1875rem) 0px;
      }
      &.valign {
        display: block;
        // margin-top: 4em;
      }
      &.valign.form {
        margin-top: 6em;
        padding-bottom: spacer(5);
      }
      &.-masthead {
        padding-top: spacer(7);
        display: inline-block;
      }
      &.-cols {
        display: block;
      }
      & .misplaced-identifier .form {
        width: 66%;
        margin: auto;
      }
    }
    .main-icon {
      display: table;
      margin: auto;
    }
    .wrap {
      text-align: center;
    }
  }
}

@media (max-width: $screen-tablet) {
  app-forgot-password {
    .nav {
      padding: 1em;
      width: 50%;
    }
    .-logo {
      img {
        margin: unset;
      }
    }
  }
}
