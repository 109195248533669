small-screen-modal {
  position: fixed;
  top: 70px;
  height: 100%;
  z-index: 5;
  width: 100%;
  background-color: var(--body-background-color);
  flex-direction: column;
  left: 0;
  display: none;
}
