// Variables
$toolTipFontColor: #ffffff;
$toolTipCaretSpacing: spacer(4);
$toolTipFontSize: 16px;
$toolTipOpacity: 0.75;
$toolTipCaretSize: 10px;
$toolTipCaretBorderLR: $toolTipCaretSize solid rgb(65, 79, 95, $toolTipOpacity);
$toolTipCaretStyleBorderTB: calc(#{$toolTipCaretSize} - 1px) solid transparent;

.line-chart {
  &.-container {
    display: flex;
  }

  &.-parent {
    position: relative;
    overflow: hidden;
    width: 70% !important;
  }

  &.-no-data {
    position: absolute;
    top: 39%;
    left: 47%;
  }
}

.chart-tooltip {
  &.-container {
    width: 250px;
    color: white;
    border-radius: 3px;
    position: absolute;
    font-style: normal;
    font-weight: normal;
    mix-blend-mode: normal;
    color: $toolTipFontColor;
    transition: all 0.1s ease;
    overflow-wrap: break-word;
    font-size: $toolTipFontSize;
    font-family: 'Source Sans Pro';
    -webkit-transition: all 0.1s ease;
    background-color: rgb(65, 79, 95, $toolTipOpacity);
    padding: 12px 26px 12px spacer(4) !important;
  }

  &.-title {
    opacity: 0.4;
    text-align: left;
    line-height: 14px;
    letter-spacing: 0.066px;
  }

  &.-body {
    opacity: 0.8;
    max-width: 230px;
    line-height: 16px;
    padding-top: spacer(2);
  }

  &.left {
    -webkit-transform: translate(12px, -50%);
    transform: translate($toolTipCaretSpacing, -50%);
  }

  &.left:before {
    @extend .caret-style;

    right: unset;
    border-left: 0;
    left: -$toolTipCaretSize;
    border-right: $toolTipCaretBorderLR;
  }

  &.right:before,
  &.center:before {
    @extend .caret-style;

    left: unset;
    border-right: 0;
    right: -$toolTipCaretSize;
    border-left: $toolTipCaretBorderLR;
  }

  &.right,
  &.center {
    transform: translate(calc(-100% - #{$toolTipCaretSpacing}), -50%);
  }
}

.chart-legend {
  user-select: none;

  &.-container {
    width: 27%;
    margin-left: 24px;
  }

  &.-items {
    color: var(--body-text-color);
    /* width */
    &::-webkit-scrollbar {
      width: spacer(2);
    }

    /* Track */
    &::-webkit-scrollbar-track {
      // box-shadow: inset 0 0 5px grey;
      border-radius: 14px;
      background-color: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $colorTintPrimaryBlackT60;
      border-radius: spacer(2);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $colorTintPrimaryBlackT80;
    }
  }

  &.-color-bar {
    border: 2px solid;
    border-radius: 5px;
    width: spacer(5);
    height: 0;
    position: absolute;
    top: 49%;
    left: 12px;
  }

  p {
    margin: 0;
  }

  &.-text {
    word-break: break-word;
  }

  &.-total {
    margin-left: auto;
    padding-left: spacer(2);
  }

  &.-active {
    font-weight: 900;
  }

  &.-items {
    overflow-y: scroll;
    // for graph height 400
    // height: 364px;
    // for graph height 320
    height: 298px;
  }

  &.-item {
    width: 100%;
    cursor: pointer;
    display: inline-flex;
    padding: spacer(2) spacer(4);
    position: relative;
    padding-left: 48px;
    font-size: 13px;
  }

  &.-item:nth-child(odd) {
    background-color: var(--analytics-chart-legend-bg-color-odd);
    border: $hairline solid var(--analytics-chart-border-color);
  }

  &.-item:nth-child(even) {
    background-color: var(--analytics-chart-legend-bg-color-even);
    border: $hairline solid var(--analytics-chart-border-color);
  }

  &.-sort-button {
    @extend .-fontLarge;

    cursor: pointer;
    text-align: end;
    margin-right: spacer(5);
    margin-bottom: spacer(3);
    user-select: none;
    font-size: 15px;
    icon {
      margin-left: $hairline;
    }
  }
}

.caret-style {
  content: '';
  position: absolute;
  top: calc(50% - #{$toolTipCaretSize});
  border-top: $toolTipCaretStyleBorderTB;
  border-bottom: $toolTipCaretStyleBorderTB;
}

@media (max-width: $screen-md-max) {
  .line-chart {
    &.-parent {
      width: 100% !important;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .line-chart {
    &.-container {
      flex-direction: column;
    }
  }

  .chart-legend {
    &.-container {
      width: auto;
    }
  }
}
