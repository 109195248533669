doc-index {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  right: 0;
  h3 {
    font-size: 16px;
    line-height: spacer(5);
    font-weight: 600;
    color: $colorWpPrimaryHeadTextColor;
    margin-bottom: spacer(4);
  }
  app-link {
    margin-bottom: spacer(3);
    padding-left: spacer(4);
    a {
      font-size: 14px;
      line-height: spacer(5);
      color: var(--doc-index-text-color);
      font-weight: 700;
      display: flex;
    }
    &:hover {
      cursor: pointer;
      a {
        color: var(--doc-index-text-hover-color);
        text-decoration: none;
      }
    }
    &.-active {
      a {
        font-weight: 700;
        color: var(--doc-index-text-hover-color);
      }
    }
  }
}
