.small-icon-tile {
  h3 {
    font-size: 24px; //21px;
    line-height: spacer(30); //34px;
    color: var(--carousel-tile-title-color);
    font-weight: 600;
    margin-top: 0;
  }
  &.-tile-container {
    width: 350px;
    min-height: 242px;
    border-radius: spacerThree(3); //10px;
    background-color: var(--carousel-tile-bg-color);
    border: 1px solid var(--carousel-tile-border-color);
    margin: 0 auto;
  }
  &.-tile-interior {
    display: flex;
    padding: spacer(4) spacer(4) spacer(5) spacer(4);
  }
  &.-icon-background {
    background-color: var(--corousel-tile-icon-bg-color);
    border: $hairline solid var(--corousel-tile-icon-border-color);
    border-radius: 50%;
    height: 55px;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  icon {
    height: spacerThree(7);
    width: spacerThree(7);
    svg > * {
      fill: var(--corousel-tile-icon-color);
    }
  }
  &.-tile-text {
    font-size: $font-size;
    line-height: spacerThree(10); //28px;
    color: var(--carousel-tile-text-color);
  }
  &.-tile-details {
    width: calc(100% - 55px);
    padding-left: spacerThree(6); //20px
  }
}
@media (max-width: $screen-mobile-small-max) {
  .small-icon-tile {
    &.-tile-container {
      width: 240px;
      height: 343px;
    }
    &.-tile-details {
      width: 100%;
      padding-left: unset;
    }
    &.-tile-interior {
      display: block;
    }
  }
}
