.carousel {
  &.-nav {
    pointer-events: auto;
    cursor: pointer;
    background-color: var(--carousel-tile-navigator-bg-color);
    opacity: 0.5;
    z-index: 1;
  }

  &.-slide {
    width: 370px;
    position: relative;
    transition: 0.2s;
    flex-shrink: 0;
  }

  &.-container {
    position: relative;
    width: 100%;
    display: flex;
    overflow: hidden;
  }

  &.-slideshow {
    width: 100%;
    display: flex;
    pointer-events: none;
    transition: 0.2s;
  }

  &.-left {
    height: 100%;
    width: 185px;
    position: absolute;
    align-items: center;
    justify-content: flex-start;
  }

  &.-right {
    position: absolute;
    width: 185px;
    height: 100%;
    right: 0;
    align-items: center;
    justify-content: flex-end;
  }
  .-arrowleft {
    margin-left: 10px;
  }
  .-arrowright {
    margin-right: 10px;
  }
}
@media (max-width: $screen-mobile-small-max) {
  .carousel {
    &.-slide {
      width: 250px;
    }
    .-arrowleft {
      margin-left: 5px;
    }
    .-arrowright {
      margin-right: 5px;
    }
  }
}