gridColumn {
  display: flex;
  flex-direction: column;
  // justify-content: flex-end;
  align-items: stretch;
  margin-bottom: 1rem;
}

@media (min-width: $screen-md-min) {
  .grid {
    padding: 1rem 0;
    margin: 0 -#{spacer(3)};
    display: flex;
    flex-wrap: wrap;
  }
  gridColumn {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0 spacer(3);
    width: 0;
    margin-bottom: 0;
  }
}

.no-space-between {
  width: auto;
  flex-grow: unset;
  padding: 0 spacer(2);
}