account-dropdown {
  @extend .header-dropdown;
  width: 158px;
  display: block;
  background-color: var(--account-pull-down-background-color);

  &:after {
    @extend .header-dropdown-arrow;
    top: -7px;
    width: spacer(3);
    height: spacer(3);
    right: spacer(3);
    background-color: var(--account-pull-down-background-color);
  }

  .-heading {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    padding: spacer(3) spacer(3) spacer(2);
    color: $colorWpPrimaryHeadTextColor;
  }

  .-links {
    display: flex;
    line-height: 1.1rem;
    padding: spacer(3) spacer(3);
    flex-direction: column;

    a {
      padding:3px;
      font-weight: 600;
      font-size: 1rem;
      color: var(--link-color);

      &:hover {
        color: var(--link-hover-color);
        text-decoration: none;
      }
    }
  }
}
