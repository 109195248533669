app-dashboard {
  workflow-card .-round svg > * {
    fill: $colorSupportingWhite;
  }
  .dashboard-border {
    padding-bottom: spacer(42);
    border-bottom: 1px solid var(--dashboard-border-color);
    secondary-masthead {
      link-icon {
        display: inline-block;
      }
    }
  }
  .dashboard {
    &.-container {
      // Using display: flex breaks the graphs for monitoring
      display: flex;
      margin-bottom: spacer(84);
      background: var(--body-background-color);
    }

    &.-nav-vertical {
      background: var(--body-background-color);
      min-height: 100vh;
      z-index: 2;
      position: relative;
      top: 0;
      border-right: 1px solid var(--dashboard-border-color);
    }

    &.-body {
      margin: 0 auto;
      padding: spacer(30) spacer(54) 0 spacer(42);
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1296px;
      padding-bottom: spacer(42);
    }
  }
}

@media (max-width: $screen-lg) {
  app-dashboard {
    .dashboard {
      &.-body {
        padding: spacer(5) spacer(30) 0 spacer(30);
      }
    }
  }
}

@media (max-width: $breakpoint-sm-mobile-max) {
  app-dashboard {
    .dashboard {
      &.-body {
        padding: spacer(5) spacer(3) 0 spacer(3);
      }
    }
  }
}

@media (max-width: $screen-laptop-sm) {
  app-dashboard {
    .dashboard {
      &.-body {
        &.-slide {
          margin-left: 328px;
          overflow: hidden;
          transition: margin-left 200ms;
        }
      }
    }
  }
}
