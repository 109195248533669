engineTable {
  overflow: auto;
  width: 100%;
  display: grid;

  table {
    width: 100%;

    thead {
      background-color: var(--table-head-bg-color);
      border: $hairline solid var(--table-border-color);
      tr {
        th {
          background-color: var(--table-row-bg-color);
          padding: spacer(3) spacer(5);
          cursor: default;
          text-align: left;
          color: var(--table-row-text-color);
          icon {
            // padding-left: $hairline;
            margin-left: spacer(1);
            width: 0.7rem;
            height: 0.7rem;
            cursor: pointer;

            svg {
              width: 100%;
            }
          }
        }
      }
    }

    tbody {
      th {
        background-color: var(--table-row-bg-color);
        padding: spacer(3) spacer(5);
        cursor: default;
        text-align: left;
        border-bottom: $hairline solid var(--table-border-color);
        border-left: $hairline solid var(--table-border-color);
        icon {
          // padding-left: $hairline;
          margin-left: spacer(1);
          width: 0.7rem;
          height: 0.7rem;
          cursor: pointer;
          svg {
            width: 100%;
          }
        }
      }

      tr {
        border: $hairline solid var(--table-border-color);
        td {
          border-bottom: $hairline solid var(--table-border-color);
          padding: spacer(3) spacer(5);
        }
      }

      tr:last-child {
        td:first-child {
          border-bottom-left-radius: spacer(3);
        }

        td:last-child {
          border-bottom-right-radius: spacer(3);
        }

        td {
          border-bottom: 0;
        }
      }
    }
  }

  .-api-table {
    width: 100%;
    .-colSizeXSmall {
      width: 15%;
      word-break: break-all;
    }
    .-colSizeSmall {
      width: 20%;
      word-break: break-word;

      .-api-column {
        .-api-title {
          max-width: 170px;
          min-width: 100px;
        }
      }
    }
    .-colSizeMedium {
      width: 20%;
      word-wrap: break-word;

      .-api-column {
        .-api-title {
          max-width: 210px;
          min-width: 130px;
        }
      }
    }
    .-colSizeLarge {
      width: 20%;
      max-width: 350px;
    }

    .-colSizeXLarge {
      width: 40%;
      max-width: 550px;
    }

    .-sorting-icon {
      cursor: pointer;
      user-select: none;
    }
    td {
      border-collapse: collapse;
      overflow: hidden;
      vertical-align: top;
      font-size: 0.875rem;
      border-bottom: $hairline solid var(--table-border-color);
      a {
        cursor: pointer;
        font-weight: 400;
      }

      .-certifyLink:hover {
        cursor: pointer;
      }

      .-api-key-text {
        text-decoration: underline;
      }
      .-copy-div {
        display: flex;
      }
      .-apispec-div {
        padding-top: spacer(2);
      }
      .-apispec-section {
        padding: 0px 9px;
      }
    }
    td:first-child {
      .-api-column {
        display: flex;
        icon,
        .-api-title {
          flex-grow: 1;
        }
        icon {
          margin-top: spacer(1);
          margin-left: spacer(3);
        }
      }
    }

    td:nth-child(2) {
      word-wrap: break-word;
      font-weight: 600;
      font-size: 0.875rem;

      .-copy-text {
        padding: 0 spacer(2);
      }
    }

    td:last-child {
      font-family: Courier;
    }
    .icon-nav {
      &.-navIcon {
        &:hover {
          .-dropdown {
            @extend .header-dropdown-transition-hover;
          }
        }
      }
      &.-dropdown {
        @extend .header-dropdown-transition;
      }
    }
  }
  .-icon-position {
    top: 3px;
    height: 12px;
    width: 13px;
  }
  .-icon-color {
    color: $colorAccentLightBlue;
  }
  account-dropdown {
    margin-top: spacerThree(3);
    width: 215px;
    .-links a {
      font-size: 0.875rem;
      line-height: 1.5rem;
    }
    &:after {
      left: spacerThree(5);
    }
  }
  .margin-top10 {
    margin-top: 10px;
  }
}

@media (max-width: $screen-sm-max) {
  engineTable {
    .engineTable {
      overflow: auto;
    }
    table {
      thead {
        tr {
          th {
            padding: spacer(3) spacer(3);
          }
        }
      }
      tbody {
        tr {
          td {
            padding: spacer(1) spacer(3);
          }
        }
      }
    }
  }
}
