sc-add-project{
  .sc-add-project{
    a{  
      cursor: pointer;
    }   
    &.-project-empty{
      display:flex;
      margin-top:spacer(30);
      padding: spacer(5);
      max-width: 54rem;
      height: 6.5rem;
      background: #012834;
      border: 1px dashed #025670;
      box-sizing: border-box;
      border-radius: 14px;
      .-contents{
        display:flex;
        justify-content: center;
        align-items: center;
      }
    }
    &.-project-existing{
      padding: spacer(5);
      max-width: 25rem;
      height: 11.5rem;
      background: #012834;
      border: 1px dashed #025670;
      box-sizing: border-box;
      border-radius: 14px;
      display: grid;
      place-items: center;
    }
    &.-add-button{
      width: spacer(42);
      height: spacer(42);
      background-color: $colorAccentLightBlue;
      text-align: center;
      border-radius: spacer(42);
      position: relative;
      display: grid;
      place-items: center;
      cursor: pointer;
    }
    &.-button-text{
        margin-left: spacer(3);
    }
  } 
}