.hosted-api-docs {
  // .-menu-item-font {
  //   font-size: 16px !important;
  //   font-weight: 400 !important;
  //   line-height: 24px !important;
  //   color: var(--body-text-color) !important;
  // }
  // .-menu-title-font {
  //   font-size: 12px !important;
  //   line-height: 20px !important;
  //   font-weight: 600 !important;
  //   color: var(--body-text-color) !important;
  // }
  // .-menu-item-selected {
  //   font-weight: 600 !important;
  //   color: var(--body-text-color) !important;
  // }
  // .-breadcrumbs-font {
  //   font-size: 12px;
  //   line-height: 18px;
  //   font-weight: 400;
  // }
  // .-api-download-button-font {
  //   color: var(---body-text-color) !important;
  //   font-size: 14px !important;
  //   line-height: 20px !important;
  //   font-weight: 600 !important;
  // }
  // .-button-font {
  //   @extend .-api-download-button-font;
  //   color: var(--button-primary-text-color) !important;
  // }
  // .-primary-button {
  //   background-color: var(--button-primary-background-color);
  //   border: 0.125rem solid var(--button-primary-border-color);
  //   border-radius: 8px;
  //   transition: 0.3s;
  // }
  // .-primary-button-hover {
  //   background-color: var(--button-primary-background-hover-color) !important;
  //   color: var(--button-primary-text-hover-color) !important;
  //   border: $borderWidth solid var(--button-primary-background-hover-color) !important;
  // }

  // .-secondary-button {
  //   color: var(--button-secondary-text-color) !important;
  //   background-color: transparent !important;
  //   border: 0.125rem solid var(--button-secondary-border-color) !important;
  //   svg > * {
  //     fill: var(--button-secondary-text-color) !important;
  //   }
  // }

  // .-secondary-button-hover {
  //   color: var(--button-secondary-text-hover-color) !important;
  //   background-color: var(--button-secondary-background-hover-color) !important;
  //   border: $borderWidth solid var(--button-secondary-background-hover-color) !important;
  //   svg > * {
  //     fill: var(--button-secondary-text-hover-color) !important;
  //   }
  // }
  // .-icon-color {
  //   svg > * {
  //     fill: var(--icon-color-apimatic) !important;
  //   }
  // }

  // .-page-title-font {
  //   font-size: 24px !important;
  //   line-height: 32px !important;
  //   font-weight: 600 !important;
  //   color: var(--body-text-color) !important;
  // }
  // .dYNeTw {
  //   @extend .-breadcrumbs-font;
  // }

  // .rc-menu-item-group-title {
  //   @extend .-menu-title-font;
  // }

  // .rc-menu-submenu-title {
  //   @extend .-menu-item-font;
  // }

  // .rc-menu-item {
  //   @extend .-menu-item-font;
  //   a {
  //     @extend .-menu-item-font;
  //   }
  // }

  // .rc-menu-submenu-selected {
  //   background-color: unset !important;
  //   .rc-menu-submenu-title {
  //     @extend .-menu-item-selected;
  //   }
  // }
  // .rc-menu-submenu-active {
  //   .rc-menu-submenu-title {
  //     background-color: unset !important;
  //   }
  // }

  // .rc-menu-item-active {
  //   background-color: unset !important;
  // }

  // .rc-menu-item-selected {
  //   background-color: unset !important;
  //   .rc-menu-submenu-title {
  //     @extend .-menu-item-selected;
  //   }
  // }

  // .rc-menu-item-group-list {
  //   .rc-menu-submenu-arrow::before {
  //     border-color: var(--left-nav-text-color) !important;
  //   }
  // }

  // .jOaNVk .rc-menu-root.rc-menu-horizontal.rc-menu {
  //   @extend .-api-download-button-font;
  //   @extend .-icon-color;
  //   background-color: #ffffff !important;
  //   border: 1px solid var(--download-spec-border-color) !important;
  //   li {
  //     margin: unset !important;
  //   }
  // }

  // button {
  //   &.czhNgw {
  //     background-color: unset !important;
  //     border: unset !important;
  //   }

  //   &.MfohX {
  //     @extend .-primary-button;
  //     @extend .-button-font;

  //     &:hover {
  //       @extend .-primary-button-hover;
  //     }
  //   }

  //   &.ctVYTl {
  //     @extend .-primary-button;
  //     @extend .-button-font;
  //     align-items: center;

  //     &:hover {
  //       @extend .-primary-button-hover;
  //     }
  //   }

  //   &.emFrCF {
  //     @extend .-secondary-button;
  //     &:hover {
  //       @extend .-secondary-button-hover;
  //     }
  //   }

  //   &.bJXeJf {
  //     .dnALGQ,
  //     .hkOUTn {
  //       color: var(--left-nav-text-color) !important;
  //       background-color: transparent !important;
  //     }
  //     background-color: transparent !important;
  //     border: 1px solid var(--download-spec-border-color) !important;
  //     .DOOed path {
  //       stroke: var(--left-nav-text-color) !important;
  //     }
  //   }
  // }

  // .heading-markdown {
  //   @extend .-page-title-font;
  // }

  // .-apimatic-container > div {
  //   border-top: unset !important;
  // }

  // p {
  //   color: var(--body-text-color) !important;
  // }

  // .fRPXKs,
  // .app-layout-docs-container {
  //   background-color: var(--body-background-color) !important;
  // }

  // .fRPXKs {
  //   border-color: var(--doc-header-bottom-border-color) !important;
  // }
  // .dTLWCK {
  //   border-color: var(--doc-header-bottom-border-color) !important;
  // }

  // hr {
  //   border-color: var(--doc-header-bottom-border-color) !important;
  // }

  // .jhBSap,
  // .kPMlQq {
  //   svg {
  //     circle {
  //       opacity: 0 !important;
  //     }
  //     path {
  //       opacity: 1;
  //       stroke: var(--left-nav-text-color) !important;
  //     }
  //   }
  // }

  // .AppLayoutContent {
  //   h3 {
  //     border-color: var(--doc-header-bottom-border-color) !important;
  //   }
  // }

  // // response section
  // // container
  // .kxtUkQ {
  //   border-color: var(--doc-header-bottom-border-color) !important;
  //   // background-color: var(--response-section-bg-color) !important;
  // }

  // // status tabs
  // ul.gmqVni {
  //   li {
  //     border-color: var(--doc-header-bottom-border-color) !important;
  //     background-color: var(--response-status-code-bg-color) !important;
  //     color: var(--body-text-color) !important;
  //     .tab-list-item-template-wrapper {
  //       color: var(--body-text-color) !important;
  //     }
  //   }
  // }

  // .jFmUUP {
  //   color: var(--body-text-color) !important;
  // }

  li.rc-menu-submenu {
    margin: unset !important;
  }
}
